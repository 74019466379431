import { createClient } from "@supabase/supabase-js";

const supabaseUrl: any = process.env.REACT_APP_SUPA_END_POINT;
const supabaseAnonKey: any = process.env.REACT_APP_SUPA_ANON_KEY;

/**
 * Create a new Supabase Client.
 */
const supabase = createClient(supabaseUrl, supabaseAnonKey);

export { supabase };
