import {
  Card,
  Form,
  Container,
  Alert,
  InputGroup,
  Modal,
  Button,
} from "react-bootstrap";
import axios from "axios";
import { Navigate } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import userDataStore, { UserState } from "../../store/userDataStore";
import React, { useRef, useState } from "react";
import Loading from "../../components/ui/Loading";
import "../../App.css";
import AuthService from "../../service/Auth/AuthService";
import UserService from "../../service/UserService";
import InfoAlert from "../../components/ui/warning/InfoAlert";
import Swal from "sweetalert2";
import AuthForm from "../../components/ui/auth/AuthForm";
import useWebInstallPrompt from "../../hooks/useWebInstallPrompt";
import PwaInstallModal from "../../components/ui/modals/PwaInstallModal";
// @ts-ignore
import PWAPrompt from "react-ios-pwa-prompt";
import themeStore from "../../store/themeStore";
import { _hasUserRole } from "../../utils/functions";

type Inputs = {
  userName: string;
  pass: string;
};

const Auth = () => {
  ////////////////////
  //Form States
  ///////////////////
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();
  const form: any = useRef();

  const API_URL = process.env.REACT_APP_END_POINT;

  ////////////////////
  //States
  ///////////////////
  const authLogin = userDataStore((state: any) => state.authLogin);
  const dataStore = userDataStore((state: any) => state);
  const themestore = themeStore((state: any) => state.themeRegister);
  const theme = themeStore((state: any) => state);
  const userRoles = userDataStore((state: UserState) => state.roles);


  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingAuth, setIsLoadingAuth] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isView, setIsView] = useState<boolean>(false);
  const [msgError, setMsgError] = useState<string>("");
  const [codeError, setCodeError] = useState<string>("");

  const [token, setToken] = useState<any>([]);
  const [myData, setMyData] = useState<any>([]);

  ////////////////////
  //Forgot pass States
  ///////////////////
  const [isNotEmail, setIsNotEmail] = useState<boolean>(false);
  const [myEmail, setMyEmail] = useState<any>("");

  const [isCompany, setIsCompany] = useState<boolean>(false);

  ////////////////////
  //Forgot modal states
  ///////////////////
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showModal, setShowModal] = React.useState<boolean>(false);
  // const [myTheme, setMyTheme] = React.useState<string>("");
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const isAndroid = /Android/i.test(navigator.userAgent);

  const [webInstallPrompt, handleWebInstallDeclined, handleWebInstallAccepted] =
    useWebInstallPrompt();

  const [isOnline, setIsOnline] = React.useState(window.navigator.onLine);

  const handleOnline = () => {
    setIsOnline(true);
  };

  const handleOffline = () => {
    setIsOnline(false);
    alert("Connexion perdue, reconnectez-vous");
  };
  ////////////////////
  //UseEffect
  ///////////////////

  React.useEffect(() => {
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
    
  }, []);

  React.useEffect(() => {
    const handleBeforeInstallPrompt: any = (event: any) => {
      event.preventDefault();
      if (event?.displayMode !== "browser") {
        if (isAndroid) {
          handleShowModal();
        }
      }
    };
    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };

  }, []);

  React.useEffect(() => {
    themestore("light");

  }, []);

  React.useEffect(() => {
    if (token && token?.length > 0) {
      getMyData(token);
    }
  }, [token]);

  React.useEffect(() => {
    if (token && token?.length > 0) {
      authLogin(
        true, // isLogged
        myData.id, // user id
        myData.firstName, // user name
        myData?.memberships ? myData?.memberships[0]?.id : null,
        myData?.memberships ? myData?.memberships[0]?.cleveronCompanyId : null, // cleveronCompany_Id
        // hasAdmin || hasSuperAdmin
        //   ? myData?.ownerships && myData?.ownerships[0]?.name //ownerships[0].name
        //   : myData?.memberships
        //   ? myData?.memberships[0]?.name //company name
        //   : "No company",
        hasAdmin || hasSuperAdmin
          ? "nocompany"
          : myData?.memberships
          ? myData?.memberships[0]?.name //company name
          : "",
        token, //token
        null, //subtoken
        myData?.apmAccessCode, //apm acces code
        myData?.roles, //roles
        myData?.username // username
      );
      handleClearCache();
    }
  }, [authLogin, myData, token]);

  React.useEffect(() => {
    if (dataStore.company_name === undefined) {
      setIsCompany(true);
      setMsgError(
        "Vous n'êtes affilié à aucune companie, contactez votre adminitrateur"
      );
    }
  }, [dataStore]);

  React.useEffect(() => {
    if (myEmail) {
      setIsNotEmail(false);
    }
  }, [myEmail]);

  const hasSuperAdmin = _hasUserRole(userRoles, "ROLE_SUPER_ADMIN");
  const hasAdmin = _hasUserRole(userRoles, "ROLE_ADMIN");
  ////////////////////
  //events
  ///////////////////

  ///////////////
  //Vide le cache du navigateur
  ///////////////
  const handleClearCache = () => {
    if ("caches" in window) {
      caches.keys().then((cacheNames) => {
        cacheNames.forEach((cacheName) => {
          caches.delete(cacheName);
        });
      });
    } else {
      console.log(
        "Impossible de vider le cache. Votre navigateur ne prend pas en charge cette fonctionnalité."
      );
    }
  };

  const signUp: SubmitHandler<Inputs> = (dataz: any, e: any) => {
    e.preventDefault();
    setIsError(false);
    AuthService.login(
      dataz.userName,
      dataz.pass,
      setToken,
      setMsgError,
      setIsError,
      setIsLoadingAuth,
      setCodeError
    );
    setIsLoading(false);
  };

  const getMyData = async (token: any) => {
    try {
      const response = await UserService.me(token);
      setIsLoading(false);
      setMyData(response.data);
    } catch (error: any) {
      console.error(error);
      setIsError(true);
      setCodeError(error?.response?.status);
      setMsgError(error?.response?.data?.message);
      setIsLoading(false);
    }
  };

  const forgot = (e: any) => {
    e.preventDefault();

    if (!myEmail) {
      setIsNotEmail(true);
    } else {
      let data = {
        email: myEmail,
        url: "https://locker.app.easyclic.co/forgot-password",
      };

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: API_URL + "forgot-password",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response: any) => {
      
          Swal.fire({
            position: "top-end",
            toast: true,
            icon: "success",
            title: "Email de réinitilisation",
            text: "Envoi en cours",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
        })
        .catch((error: any) => {
          console.log(error);
          setIsError(true);
          setCodeError(error?.response?.status);
          setMsgError(
            error?.response?.data["hydra:description"]
              ? error?.response?.data["hydra:description"]
              : error?.response?.data?.message
          );
        });
      handleClose();
    }
  };


  const formProps = {
    handleSubmit,
    register,
    errors,
    signUp,
    isView,
    setIsView,
    handleShow,
    isError,
    codeError,
    msgError,
    isLoadingAuth,
  };

  const pwaInstallModalProps = {
    showModal,
    handleCloseModal,
    webInstallPrompt,
    handleWebInstallDeclined,
    handleWebInstallAccepted,
  };


  return (
    <Container fluid className="auth-cont-sup px-0 py-0">
      <PwaInstallModal pwaInstallModalProps={pwaInstallModalProps} />
      <PWAPrompt
        promptOnVisit={1}
        timesToShow={3}
        copyClosePrompt="Close"
        permanentlyHideOnDismiss={false}
      />
      <Container
        fluid
        className="auth-cont col-12 col-md-12 col-lg-6 px-0 bg-secondar"
      >
        {dataStore.token && dataStore.company_name && (
          <Navigate to="/preparations" />
        )}
        {isCompany && (
          <Alert variant="danger" className="text-center">
            {msgError}
          </Alert>
        )}
        {isLoading ? (
          <Loading variant="info" />
        ) : (
          <Card className="auth-form  bg-warni shadow animate__animated animate__fadeI rounded-0 border-0 vh-100">
            <Card.Body className="">
              <div className="logo-app text-center text-light animate__animated animate__rotateI"></div>
              <AuthForm formProps={formProps} />
            </Card.Body>
          </Card>
        )}
        <Modal show={show} onHide={handleClose} centered className="rounded-0">
          <Modal.Header className="border-bottom-0">
            <Modal.Title>Mot de passe oublié</Modal.Title>
          </Modal.Header>
          <Form ref={form} onSubmit={forgot}>
            <Modal.Body>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="d-none">email</Form.Label>
                <InputGroup className="mb-3">
                  <InputGroup.Text
                    id="basic-addon1"
                    className="rounded-0 border"
                  >
                    <i className="ri-at-line text-muted"></i>
                  </InputGroup.Text>
                  <Form.Control
                    className=" rounded-0 border"
                    type="email"
                    name="user_email"
                    placeholder="email"
                    value={myEmail}
                    onChange={(e) => setMyEmail(e.currentTarget.value)}
                  />
                </InputGroup>
                {isNotEmail && (
                  <Alert variant="danger" className="mt-2 py-0 text-cente">
                    <InfoAlert
                      icon="ri-error-warning-line"
                      iconColor="danger"
                      message={"Saisissez votre email"}
                      fontSize="font-75"
                    />
                  </Alert>
                )}
              </Form.Group>
              <Alert
                variant="warning"
                className="border-2 border-warning py-1 rounded-0"
              >
                <i className="ri-information-line text-warning align-bottom"></i>{" "}
                <span className=" font-75 text-secondary mb-0">
                  Suivez les instructions qui vous seront envoyées pour
                  réinitialiser votre mot de passe.
                </span>
              </Alert>
            </Modal.Body>
            <Modal.Footer className="text-light border-top-0">
              <Button
                variant=""
                onClick={handleClose}
                className="text-success bg-yellow"
              >
                Fermer
              </Button>
              <Button type="submit" variant="" className="text-light bg-green">
                Envoyer
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </Container>
    </Container>
  );
};

export default Auth;
