import React from 'react'
import { Button, Col, Container, Modal, Row, Spinner } from 'react-bootstrap';
import BadgedIcon from '../BadgedIcon';
import { _getOrdersByStatus } from '../../../utils/functions';
import userDataStore from '../../../store/userDataStore';
import { AuthData } from '../../../utils/definitions';

export default function Confirmation({confirmationProps}: any) {
  const dataStore = userDataStore((state: AuthData) => state);

    const {showCustomerService, handleCloseCustomerService, selectedOrder, changeStatus, newStatus, isLoading, message, handleShowUpdateStatus, handleShowCustomerService, setOrderExpired,
      setIsLoading, setSelectedOrder, setIsSelectedOrder} = confirmationProps;

  return (
    <Modal show={showCustomerService} onHide={handleCloseCustomerService}>
        <Modal.Body className="pb-0">
          <Container className="text-center m-auto w-100 ">
            <Row>
              <Col xs={2} md={4} className="m-auto ps-0 ps-md-5">
                {" "}
                <BadgedIcon
                  slot={selectedOrder?.bookingSlot}
                  borderColor="gray"
                  imgSize="25px"
                  componentSize="35px"
                  design="indicator-small"
                />
              </Col>
              <Col className="m-auto text-start">
                <b>
                {selectedOrder?.barcode}
                </b>
              </Col>
            </Row>
          </Container>
          <Container className="text-start">
             {message}
          </Container>
        </Modal.Body>
        <Modal.Footer className="text-center  border-0">
          <Row>
            <Col xs={6}>
              <Button
                className=" rounded-pill border-yellow bg-yellow text-success px-4"
                variant=""
                onClick={() => {
                  handleCloseCustomerService();
                }}
              >
                Annuler
              </Button>
            </Col>
            <Col xs={6}>
              <Button
                type="submit"
                className="bg-green rounded-pill border-green text-light px-4 "
                onClick={() => {
                    if (newStatus === "operin") {
                      changeStatus(
                        "left_for_customer_service",
                        handleShowUpdateStatus
                      );
                    } else if (newStatus === "operout") {
                      _getOrdersByStatus(
                        dataStore.token,
                        "overtime",
                        setOrderExpired,
                        setIsLoading
                      );
                      setSelectedOrder(null);
                      handleCloseCustomerService();
                      setIsSelectedOrder(false);
                    } else {
                      changeStatus(newStatus, handleShowUpdateStatus);
                    }
               
                }}
              >
                {isLoading ? <Spinner size="sm" as="span" /> : "Valider"}
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
  )
}
