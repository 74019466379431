import { Card, Container } from 'react-bootstrap'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import '../../App.css'
import Swal from 'sweetalert2'
import ForgotForm from '../../components/ui/auth/ForgotForm'
import axios from 'axios'
import userDataStore from '../../store/userDataStore'
import imag from '../../styles/forgot.png'

const Forgot = () => {
  const API_URL = process.env.REACT_APP_END_POINT
  //////////////////////////
  // booleans States
  /////////////////////////
  const [isLoadingAuth, setIsLoadingAuth] = useState<boolean>(false)
  const [isError, setIsError] = React.useState<boolean>(false)
  const [isView, setIsView] = useState<boolean>(false)
  const [isView2, setIsView2] = useState<boolean>(false)
  const [isValidPassword, setIsValidPassword] = React.useState<boolean>(true);

  
  
  
  //////////////////////////
  // states
  /////////////////////////
  
  
  const [pass1, setPass1] = React.useState('')
  const [pass2, setPass2] = React.useState('')
  
  const [msgError, setMsgError] = useState<string>('')
  
  const params = useParams()
  const navigate = useNavigate()




  //////////////////////////
  // Useeffect
  /////////////////////////


  React.useEffect(() => {
    
    const validatePassword = () => {
      const regex: any  = /^(?=.{12,})(?=.*?[A-Z])(?=.*?[^a-zA-Z0-9])/
      const match = regex?.test(pass1);

      setIsValidPassword(match ? true : false);
    };

    if(pass1?.length > 8){

      validatePassword();
    }
  

   
  }, [pass1]);

  useEffect(() => {
    if (pass1 === pass2) {
      setIsError(false)
    }
  }, [pass1, pass2])



  //////////////////////////
  // Event
  /////////////////////////
  const updatePass = (e: any) => {
    e.preventDefault();
    setIsLoadingAuth(true);
    if (params?.token) {
      if (pass1 === pass2) {
        let data = JSON.stringify({
          password: pass1,
          retypedPassword: pass2,
        });

        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: API_URL + "forgot-password/" + params.token,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios
          .request(config)
          .then((response) => {
            setIsLoadingAuth(false);
            setIsError(false);
            Swal.fire({
              position: "top-end",
              toast: true,
              icon: "success",
              title: "Réinitialisation du mot de passe effectué",
              text: "Vous allez être redirigé",
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
              didOpen: () => {
                setTimeout(() => {
                  navigate("/preparations");
                }, 5000);
              },
            });
          })
          .catch((error) => {
            setIsLoadingAuth(false);
            setIsError(true);
            setMsgError(error?.response?.data?.message ? error?.response?.data?.message : error?.response?.data["hydra:description"])
        
          });
      } else {
        setIsLoadingAuth(false);
        setMsgError("Vos mots de passe doivent être identiques")
        setIsError(true);
      }
    } else {
      setIsLoadingAuth(false);
      setIsError(true);
      setMsgError("Vous n'êtes pas autorisé à modifier votre mode passe")
    }
  };

  const formProps = {
    updatePass,
    isView,
    setIsView,
    isView2,
    setIsView2,
    pass1,
    setPass1,
    pass2,
    setPass2,
    isError,
    isLoadingAuth,
    msgError,
    isValidPassword,
  }

  return (
    <Container
      fluid
      className="auth-cont col-12 col-lg-4 pb-5 px-0 bg-secondar"
    >
      <i
        className="go-back ri-arrow-left-line text-light fs-2 me-1 "
        onClick={() => navigate("/connexion")}
      ></i>
      {params.token ? (
        <Card className="auth-form  bg-secondary shadow animate__animated animate__fadeIn rounded-0 border-0 vh-100">
          <Card.Body>
            <div className="logo-ap text-center text-dark animate__animated animate__rotateIn">
              <img
                src={imag}
                alt="Déverrouiller"
                style={{
                  width: "80px",
                  height: "80px",
                  filter:
                    "invert(99%) sepia(0%) saturate(0%) hue-rotate(168deg) brightness(102%) contrast(102%)",
                }}
              />
            </div>
            <div className=" text-center my-5 text-light animate__animated animate__fadeInUp">
              <h3>Mot de passe oublié</h3>
              <p>Réinitialisé le</p>
            </div>
            <ForgotForm formProps={formProps} />
          </Card.Body>
        </Card>
      ) : (
        <Navigate to="/connexion" />
      )}
    </Container>
  );
}

export default Forgot
