import React from 'react'
import { Button, Card, Col, Container, Form, Modal, Row } from 'react-bootstrap'
import { useOutletContext } from 'react-router-dom';
import { _bgTemp, _filteredLocker, _iconFilter3, _slotLocationTab, _tempZoneSelection } from '../utils/functions';
import { orders } from '../data/orders';


const Preps = () => {

    const { allSlot } = useOutletContext<any>();

  const [uniqueBookingTab, setUniqueBookingTab] = React.useState<any>([])
  const [uniqueTab, setUniqueTab] = React.useState<any>([])



    // const [validatedOrder, setValidatedOrder] = React.useState<any>([]);
    const [chosenLocker, setChosenLocker] = React.useState<any>([]);
    const [selectedOrder, setSelectedOrder] = React.useState<any>([]);
    const [selectedProduct, setSelectedProduct] = React.useState<any>([]);
    const [productQty, setProductQty] = React.useState<any>("");
    const [productHTValidated, setProductHTValidated] = React.useState<any>([]);
    const [productHT2, setProductHT2] = React.useState<any>([]);
    const [productMT2, setProductMT2] = React.useState<any>([]);
    const [productHT, setProductHT] = React.useState<any>([]);
    const [productMT, setProductMT] = React.useState<any>([]);
    // const [productLT, setProductLT] = React.useState<any>([]);
    const [qtySlot, setQtySlot] = React.useState<any>(1);



    // const [nonAl, setNonAl] = React.useState<any>([]);
    // const [al, setAl] = React.useState<any>([]);
    const [isChosenLocker, setIsChosenLocker] = React.useState<boolean>(false);
    const [isPreparing, setIsPreparing] = React.useState<boolean>(false);
    const [isErrorQty, setIsErrorQty] = React.useState<boolean>(false);


    const [selectedFile, setSelectedFile] = React.useState(null);
    const [imagePreviewUrl, setImagePreviewUrl] = React.useState(null);

    const [checkedState, setCheckedState] = React.useState(
      new Array(selectedOrder?.length).fill(false)
    );
  
    const [allCheckedState, setAllCheckedState] = React.useState(
    false
    );

   
      // const planNonAl = [30, 31, 33, 34, 11, 12];
      // const planAl = [10, 14, 23, 50, 51, 52, 54, 56, 57, 59, 58];
      const liquide = ["alcool", "soft"];
      const frais = ["50", "51"];
      const sec: string[] = ["30", "31", "33", "34", "11", "12", "14", "10", "57"];


      const [show, setShow] = React.useState(false);
      const handleClose = () => setShow(false);
      const handleShow = () => setShow(true);

      const [showAddCart, setShowAddCart] = React.useState(false);
      const handleCloseAddCart = () => setShowAddCart(false);
      const handleShowAddCart = () => setShowAddCart(true);



      React.useEffect(() => {  
         const bookingLocker: any = allSlot?.['hydra:member']?.map(
        (locker: any) => locker?.slot?.temperatureZone?.locker
      )
      const deduplicate: any = [
        ...new Set(bookingLocker?.map((locker: any) => locker?.location)),
      ]
      setUniqueTab(deduplicate)

        const bookingLocker2: any = allSlot?.['hydra:member']?.map(
            (locker: any) => locker?.slot?.temperatureZone?.locker
          )
          const deduplicate2: any = [
            ...new Set(bookingLocker2?.map((locker: any) => locker?.shortLocation)),
          ]
          setUniqueBookingTab(deduplicate2)
      }, [allSlot])
    

      React.useEffect(() => {
          const htProd = selectedOrder?.filter((prod: any) => prod?.temperature_zone === "HT")
          const mtProd = selectedOrder?.filter((prod: any) => prod?.temperature_zone === "MT")

          let htProdSorted: any =  []
      
          if(sec && sec?.length > 0){

            sec.map((rayon: any) => {
              htProd?.filter((prod: any) =>{ 
                if(rayon === prod.rayon ){
                  htProdSorted.push(prod)
                }
              })
            });
            
            
          }else {
            htProdSorted = htProd
          }

          let htProdNotSorted: any =   htProd?.reduce((acc: any, prod: any) => {
            if (!sec.includes(prod.rayon)) {
              acc.push(prod);
              prod.isUnknow = true
            }
            return acc;
          }, [])
          // let htProdNotSorted: any =  htProd?.filter((prod: any) => !sec.includes(prod.rayon))
     
        
        


    

          const liquidProd = selectedOrder?.filter((prod: any) =>
          liquide.includes(prod.rayon)
        );
        const isAlcool = liquidProd?.some((prod: any) => {
          const alcoolProd = prod?.rayon;
          return alcoolProd === "alcool";
        });

        setProductHT2(htProdSorted?.concat(htProdNotSorted))
        setProductMT2(mtProd)

        if (isAlcool === true) {
          setProductHT(liquidProd);
        } else {
          setProductMT(liquidProd);
        }

        handleAddSlotProduct(qtySlot-1)

        // setProductHTValidated([[]])
      // }, [isPreparing]);
      }, [selectedOrder]);
      
 

      React.useEffect(() => {

        const freshProd = selectedOrder?.filter((prod: any) =>
          frais.includes(prod.rayon)
        );
        const dryProd = selectedOrder?.filter((prod: any) =>
          sec.includes(prod.rayon)
        );

        const mtProd = productMT?.concat(freshProd);
        setProductMT(mtProd)
        const htProd = productHT?.concat(dryProd);
        setProductHT(htProd)
       
      }, [isPreparing])



      const handleUpdateCheckBox = (indx: number) => {
        const updateCheckedState = checkedState.map(
          (item: boolean, index: number) => (indx === index ? !item : item)
        );
        setCheckedState(updateCheckedState);
      };
    
      const allCheckBox = () => {
        setAllCheckedState(!allCheckedState)
        setCheckedState(new Array(selectedOrder.length).fill(!allCheckedState))
      }
    

   

      const handleChange = (event: any) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        
        const reader: any = new FileReader();
        reader.readAsDataURL(file);
        reader.addEventListener('load', () => {
          setImagePreviewUrl(reader.result);
        }, false);
      };
    
      async function handleSubmit(event: any) {
        event.preventDefault();
        try {
          const formData: any = new FormData();
          formData.append('image', selectedFile);
          await fetch('/your-backend-endpoint', {
            method: 'POST',
            body: formData,
          });
          
          // Update local state or perform other necessary operations after successful submission
        } catch (error) {
          console.error('Error submitting image: ', error);
        }
      }






      const slotLocationTab = (location: any) => {
        const filteredData = allSlot?.['hydra:member']?.filter(
          (lockers: any) => lockers?.slot?.temperatureZone?.locker?.location === location
        )
        return filteredData
      }

    

      const uniqueTempTab2 = (locker: any) => {
        const newTab = [
          ...new Set(
            slotLocationTab(locker)?.map((lock: any) => lock?.slot?.temperatureZone?.name)
          ),
        ]
        return newTab
      }


      const validedProduct = (selectedProduct: any, qtySlot: number, e: any) => {
        e.preventDefault()
        handleClose()
       
        productHTValidated[qtySlot-1]?.products?.push(selectedProduct)
        const newTab = productHT2?.filter((prod: any) => prod?.id_product !== selectedProduct.id_product)
        setProductHT2(newTab)
        const newTabMT = productMT2?.filter((prod: any) => prod?.id_product !== selectedProduct.id_product)
        setProductMT2(newTabMT)
      }

      const handleAddSlotProduct = (indx: number) => {
        let newTab: any = [...productHTValidated]
        newTab[indx] = 
          {
           
              "bookingSlot": {
                  "@id": chosenLocker?.["@id"],
                  "@type": "BookingSlot",
                  "slot": {
                      "@id": "/api/slots/2",
                      "@type": "Slot",
                      "temperatureZone": {
                          "@id": "/api/temperature_zones/2",
                          "@type": "TemperatureZone",
                          "locker": {
                              "@id": "/api/lockers/1",
                              "@type": "Locker",
                              "shortLocation": "Punaauia 2"
                          },
                          "name": "Ambiant"
                      },
                      "size": "M"
                  }
              },
              "products": [
                
              ]
          }
      
        setProductHTValidated(newTab)
      }

     const handleChangeSelect = (value: any, qtySLot: number) => {

     }
// console.log(selectedOrder[0])

  return (
    <Container className="order-list px-2">
      <Container className="my-2">
        <Container className="px-3 py-0 bg-gray rounded-pill border-ui-second my-auto">
          <Row>
            <Col xs={2}></Col>
            <Col className="text-center">Etape title</Col>
            <Col xs={2}></Col>
          </Row>
        </Container>
      </Container>

      {selectedOrder?.length === 0 ? (
        orders?.map((order: any, indx: number) => (
          <Container
            key={indx}
            className="list-item border-2 border-gray px-0 rounded mb-2"
            onClick={() =>{
              console.log(order)
              // setSelectedOrder(order)
              }}
            // onClick={() => setSelectedOrder(order?.detailRow)}
          >
            <Row className="m-auto">
              <Col>
                <i className="ri-file-text-fill fs-3"></i>
              </Col>
              <Col className="m-auto">Order N° {order?.id}</Col>
              <Col></Col>
            </Row>
          </Container>
        ))
      ) : !isPreparing ? (
        <Container>
           <Container className='border-2 border-bottom mb-4' >
              <Row>
              {/* <Col xs={2}><input type="checkbox" checked={true} /></Col> */}

                <Col xs={2}>qté</Col>
                <Col>Libellé</Col>
              </Row>
            </Container>
          {/* {selectedOrder?.map((prod: any, index: number) => (
            <Container key={index}>
              <Row>
                <Col xs={2}><input type="checkbox" checked={true} /></Col>
                <Col xs={2}>{prod?.quantity}</Col>
                <Col xs={10}>{prod?.name}</Col>
              </Row>
            </Container>
          ))} */}
          <Button
            className="fab bg-green border-0 rounded-pill"
            onClick={() => setIsPreparing(true)}
          >
            Préparer
          </Button>
        </Container>
      ) : !isChosenLocker ? (
        <>
          {uniqueTab?.map((locker: any, indx: number) => (
            <Card
              key={indx}
              className="cursor border-2 border-gray p-3 mb-3 text-ui-second"
              onClick={() => {
                setIsChosenLocker(true);
                _filteredLocker(locker, setChosenLocker, allSlot);
              }}
            >
              <Row className="px-1 px-sm-3">
                <Col className="m-auto ms-md-3 font-75 ps-2 px-0 mx-0 fs-5 text-center">
                  {uniqueBookingTab[indx]}
                </Col>
                <Col>
                  {uniqueTempTab2(locker)?.map((slots: any, index: any) => (
                    <div
                      key={index}
                      className="pb-1"
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <img
                        alt="Temp icon"
                        src={_iconFilter3(
                          _slotLocationTab(locker, allSlot)[index]?.slot
                            ?.temperatureZone?.name
                        )}
                        style={{ width: "30px" }}
                      />

                      <span className="font-65 pt-2 ms-1 ">
                        <i className="ri-arrow-right-line"></i>
                      </span>
                      {_slotLocationTab(locker, allSlot)
                        ?.filter(
                          (lock: any) =>
                            lock?.slot?.temperatureZone?.name === slots
                        )
                        ?.map((temp: any) => (
                          <div
                            key={Math.random()}
                            className="badge-hoster px-0 ms-1 pt-1"
                          >
                            <span className="font-75 fw-bold ms-2 mb-0 bg-warning rounded-pill  px-2">
                              {temp?.slot?.size}
                            </span>
                            <div className="my-badge px-0 font-65 fw-bold pb-0 text-light">
                              <span className="rounded-pill bg-green px-1 py-">
                                {temp?.available}
                              </span>
                            </div>
                          </div>
                        ))}
                    </div>
                  ))}
                </Col>
              </Row>
            </Card>
          ))}
        </>
      ) : (
        <Container>
          <h4 className="text-center">Panier N°{qtySlot}</h4>
          <Form.Select
            onChange={(e) => {
              handleChangeSelect(e, qtySlot);
            }}
            aria-label="zone"
            className="border border-1 border-secondary my-2 text-ui-second "
            required
          >
            <option value="" className="text-ui-second">
              Température du Panier n°{qtySlot}
            </option>
            {chosenLocker?.map((lockers: any, index: any) => (
              <option
                onChange={() => console.log("index")}
                key={index}
                value={JSON.stringify(lockers)}
                className={`text-ui-second ${_bgTemp(lockers)}`}
                disabled={lockers.available < 1 ? true : false}
              >
                {_tempZoneSelection(lockers)} ({lockers?.slot.size}) -{" "}
                {lockers?.slot?.temperatureZone?.name}
                {lockers?.available}{" "}
                {lockers?.available > 1 ? "casiers" : "casier"} -{" "}
                {lockers.slot.height}X{lockers.slot.width}X{lockers.slot.depth}
              </option>
            ))}
          </Form.Select>
          {productHT2?.length > 0 && "Produits Secs"}
          {productHT2?.length > 0 && productHT2?.map((prod: any, index: number) => (
           
            <Container
              key={index}
              className="border border-2 border-secondary rounded-1 mb-2"
              onClick={() => {
                handleShow();
                setSelectedProduct(prod);
                setProductQty(prod.quantity);
              }}
            >
              <Row>
                <Col xs={2}><Form.Check type="checkbox" checked={checkedState[index]} onChange={() => handleUpdateCheckBox(index)} /></Col>
                <Col xs={2}>{prod?.quantity}</Col>
                <Col>{prod?.name}</Col>
                {prod?.isUnknow && (
                  <Col xs={2}>
                    <i className="ri-prohibited-line text-danger"></i>
                  </Col>
                )}
              </Row>
            </Container>
          ))}
          {productMT2?.length > 0 && "Produits Frais"}
          {productMT2?.length > 0 && productMT2?.map((prod: any, index: number) => (
            <Container
              key={index}
              className="border border-2 border-secondary rounded-1 mb-2"
              onClick={() => {
                handleShow();
                setSelectedProduct(prod);
                setProductQty(prod.quantity);
              }}
            >
              <Row>
                <Col xs={2}><Form.Check type="checkbox" checked={checkedState[index]} onChange={() => handleUpdateCheckBox(index)} /></Col>
                <Col xs={2}>{prod?.quantity}</Col>
                <Col>{prod?.name}</Col>
              </Row>
            </Container>
          ))}
          {(productHT2?.length > 0 || productMT2?.length > 0) && (
            <Button
              variant="outline-secondary"
              className="rounded-pill pt-1"
              onClick={() => {
                handleShowAddCart();
              }}
            >
              <span className="font-85">+ Ajouter un panier</span>
            </Button>
          )}
          <Button className="fab bg-green border-0 rounded-pill">
            Valider
          </Button>
        </Container>
      )}
      <Modal
        show={showAddCart}
        onHide={handleCloseAddCart}
        keyboard={false}
        centered
      >
        <Modal.Header className="border-bottom-0">
          <Modal.Title>Panier N°{qtySlot}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container className="bg-gray rounded mt-3 pb-2 border border-2 border-gray">
            <Row className="py-2">
              <Col xs={3} className="text-secondary">
                <strong>Qté</strong>
              </Col>
              <Col xs={9} className="text-secondary text-start">
                <strong>Désignation</strong>
              </Col>
            </Row>
            {productHTValidated[qtySlot - 1] &&
              productHTValidated[qtySlot - 1]?.products?.map(
                (prod: any, index: any) => (
                  <Row
                    key={index}
                    className={`py-2 ${
                      index % 2 === 0 && "table-row"
                    } border-1 border-gray border-bottom`}
                  >
                    <Col
                      xs={3}
                      className="text-dark font-85 border-1 border-dark"
                    >
                      {prod?.quantity}
                    </Col>
                    <Col xs={9} className="text-dark font-85 text-start">
                      {prod?.name}{" "}
                    </Col>
                  </Row>
                )
              )}
            <Container className="text-end mt-4">
              <Button
                className="bg-green rounded-pill border-green text-light ms-3 fab"
                type="button"
                onClick={handleShow}
              >
                Valider
              </Button>
            </Container>
          </Container>

          <span className="font-85">
            Voulez-vous valider le panier en cours et en ajouter un autre
          </span>

          <div className="text-end">
            <Button
              variant="transparent"
              className="bg-yellow text-ui-second rounded-3"
              onClick={() => {
                handleCloseAddCart();
                setQtySlot(qtySlot + 1);
                handleAddSlotProduct(qtySlot);
              }}
            >
              Valider
            </Button>
          </div>
        </Modal.Body>
      </Modal>
     
      <Modal
        show={show}
        onHide={handleClose}
        keyboard={false}
        centered
      >
        <Modal.Body>
          <Form onSubmit={(e) =>   
                  validedProduct(selectedProduct, qtySlot , e)}>

          <Row className="justify-content-end pe-3">
            <Col className="m-auto"> {selectedProduct.name}</Col>
            <Col xs={3} className="text-end">
              <Form.Control
                type="number"
                placeholder=""
                max={productQty}
                value={productQty}
                
                onChange={(e) => {

                  const newQuantity = e.currentTarget.value;
                  const maxQuantity = productQty;
                  if (newQuantity <= maxQuantity) {
                  selectedProduct.quantity = e.currentTarget.value;
                  setProductQty(e.currentTarget.value);
                  setSelectedProduct(selectedProduct);
                  // setIsErrorQty(false)

                } else {
                  // Afficher un message d'erreur ou ignorer la modification
                  console.error("La quantité ne peut pas dépasser " + maxQuantity);
                  setIsErrorQty(true)
                }
                }}
              />

            </Col>
            <Col xs={3} className="text-end">
              <Button
                variant="transparent"
                className="bg-yellow text-ui-second rounded-3"
                type="submit"
                // onClick={() => {
                //   handleClose();
                //   validedProduct(selectedProduct, qtySlot);
                // }}
              >
                Valider
              </Button>
            </Col>
          </Row>
          </Form>


          {isErrorQty &&

            <p className='font-75 text-danger'><small>Vous ne pouvez pas augmenter la quantité</small></p>
          }
        </Modal.Body>
      </Modal>
    </Container>
  );
}

export default Preps
