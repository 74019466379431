import React from 'react'
import { Container, Row, Col,  } from 'react-bootstrap';
import BackButton from '../BackButton';
import noOrder from "../../../styles/astro.png"





const Anomaly = ({anomalyProps}: any) => {

    const {setSelectedOrder, setIsAnomaly, selectedOrder, scanCode, msgAnomaly} = anomalyProps

  return (
    <Container className="my-2 px-0">
    <Container className="py-0 bg-gray rounded-pill shadow my-auto mt-3">
      <Row>
        <Col
          xs={2}
          md={2}
          lg={2}
          className="m-auto py-0"
          onClick={() => {
            setSelectedOrder("");
            setIsAnomaly(false);
          }}
        >
          <BackButton />
        </Col>
        <Col className="m-auto text-ui text-center ps-1 pe-2 py-0">
          <span className="fw-bold font-85">
            {selectedOrder
              ? scanCode
              : "Une anomalie est survenu"}
          </span>
        </Col>
        <Col
          xs={2}
          md={2}
          lg={2}
          className="m-auto text-ui text-end ps-1 pe-2"
        >
          <i className="ri-question-line fs-3 rounded-pill"></i>
        </Col>
      </Row>
    </Container>
    <Container className="text-center mt-3 text-ui-second font-85">
      <p>{selectedOrder && "Une anomalie est survenue ..."}</p>
      <p className="font-75 text-green">
        <b>{msgAnomaly}</b>
      </p>
      <img
        src={noOrder}
        alt="no-order"
        style={{ height: "180px" }}
      />
      <p className="mt-3">
        Réessayez le scan ou recherchez d'où vient l'anomalie
      </p>
    </Container>
  </Container>
  )
}

export default Anomaly
