import React from "react";
import { Container, Row, Col, Modal, Card } from "react-bootstrap";

type LockerChoiceProps = {
  lockerChoiceProps: {
    show: boolean;
    handleClose: () => void;
    bookingTab: string[];
    setSelectedStore: React.Dispatch<React.SetStateAction<void>>;
    setIdStore: any;
    setSelectedOrderCity: React.Dispatch<React.SetStateAction<string[]>>;
    filteredStore: (place: string) => void;
    filteredCity: (place: string) => string[];
    filteredIdStore: (place: any) => any;
  };
};

const LockerChoice: React.FC<LockerChoiceProps> = ({ lockerChoiceProps }) => {
  const {
    show,
    handleClose,
    bookingTab,
    setSelectedStore,
    setIdStore,
    setSelectedOrderCity,
    filteredStore,
    filteredCity,
    filteredIdStore
  } = lockerChoiceProps;

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="text-ui-second h-100"
      fullscreen={true}
      animation={false}
      centered
    >
      <Container fluid className=" py-3 top-nav-design text-green">
        <Row>
          <Col xs={2} onClick={handleClose}>
            <i className={`ri-arrow-left-s-line fs-2`}></i>
          </Col>
          <Col className="text-center text-ui-secod m-auto">
            <h4>Choix du locker</h4>
          </Col>
          <Col xs={2}></Col>
        </Row>
      </Container>
      <Modal.Body className="bg-gray text-center d-flex flex-column justify-content-center locker-choice">
        {bookingTab?.map((booking: string, indx: number) => (
          <Card
            key={indx}
            className={`text-center bg-ui m-auto ${
              bookingTab.length > 3 && "mb-3"
            } `}
            style={{ width: "45%", height: "25%" }}
            onClick={() => {
              setSelectedStore(filteredStore(booking));
              setIdStore(filteredIdStore(booking));
              setSelectedOrderCity(filteredCity(booking));
              handleClose();
            }}
          >
            <Card.Body className="d-flex">
              <Card.Text className="m-auto">
                <i className="ri-box-3-fill text-green fs-1"></i> <br />
                <span className="font-75">{filteredCity(booking)}</span>
              </Card.Text>
            </Card.Body>
          </Card>
        ))}
      </Modal.Body>
    </Modal>
  );
};

export default LockerChoice;
