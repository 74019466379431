import React from "react";
import "../App.css";
import "animate.css";
import userDataStore, { UserState } from "../store/userDataStore";
import { Navigate, useOutletContext } from "react-router-dom";
import { message } from "antd";
import { Container } from "react-bootstrap";
import { _getStatus, _hasUserRole, _searchWithRegex } from "../utils/functions";
import SearchBar from "../components/ui/SearchBar";
import AlertIsError from "../components/ui/warning/AlertIsError";
import OrderList from "../components/ui/OrderList";
import Anomaly from "../components/ui/anomaly/Anomaly";
import DeliveryDetail from "../components/ui/DeliveryDetail";
import OrdersService from "../service/Orders/OrdersService";
import HistoryLoader from "../components/ui/loading/HistoryLoader";



const Delivered: React.FC = () => {
  //////////////////////////
  // booleans States
  /////////////////////////
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isError, setIsError] = React.useState<boolean>(false);

  //////////////////////////
  // Store & context state
  /////////////////////////
  const dataStore = userDataStore((state: UserState) => state);
  const userRoles = userDataStore((state: UserState) => state.roles);

  const hasSuperAdmin = _hasUserRole(userRoles, "ROLE_SUPER_ADMIN");
  const hasAdmin = _hasUserRole(userRoles, "ROLE_ADMIN");
  // ⚠  change ROLE_USER to ROLE_TECH on prod
  const hasTech = _hasUserRole(userRoles, "ROLE_TECH");


  const {
    setSelectedStore,
    setSelectedOrderCity,
    allSlot,
    setSelectedItem,
    selectedStore,
    selectedOrderCity,
    orderReady,
    setOrderReady,
    setOrderPickedUp,
    setIsSelectedOrder,
    idStore, setIdStore
    // orderOperin, setOrderOperin,
  } = useOutletContext<any>();

  const userToken = localStorage.getItem("user");

  //////////////////////////
  // States
  /////////////////////////

  const [orderOperin, setOrderOperin] = React.useState<any>([]);
  const [messageApi, contextHolder] = message.useMessage();

  const [selectedOrder, setSelectedOrder] = React.useState<any>("");
  const [searchOrder, setSearchOrder] = React.useState<string>("");
  const [filteredOrder, setFilteredOrder] = React.useState<any>([]);
  const [storeName, setStoreName] = React.useState<any>([]);

  const [scanCode, setScanCode] = React.useState<string>("");

  const [isAnomaly, setIsAnomaly] = React.useState<boolean>(false);
  const [msgAnomaly, setMsgAnomaly] = React.useState<string>("");

  const trigger: string = "operin";
  const newStatus: string = "operout";



    ///////////////////////////////////////////////////
  ////Filtrage des données par locker et par livreur
  //////////////////////////////////////////////////
  const orderByStatus = orderOperin && orderOperin["hydra:member"]
  ?.filter(
    (order: any) =>
 
      order?.bookingSlot?.slot?.temperatureZone?.locker &&
      order?.bookingSlot?.slot?.temperatureZone?.locker["@id"] === selectedStore
  );

//   console.log(idStore)
// console.log(orderOperin["hydra:member"])
// console.log(orderByStatus)

  //////////////////////////
  // UseEffect
  /////////////////////////

  React.useEffect(() => {
    setIsLoading(true);
    setSelectedItem("");
    setIsSelectedOrder(false);
    getOrdersByMultiStatus(dataStore.token, idStore, setOrderOperin,);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  React.useEffect(() => {
    if (orderByStatus) {
      setIsLoading(false);
    } else {
      if (orderByStatus?.length < 0) {
        setIsError(true);
        setIsLoading(false);
      } else {
        setIsLoading(true);
      }
    }
  }, [orderByStatus]);

  React.useEffect(() => {
    const myScan =
      orderReady &&
      orderReady["hydra:member"]?.filter(
        (order: any) =>
          order?.barcode === searchOrder ||
          order?.externalOrderId === searchOrder
      )[0];

    if (myScan) {
      setScanCode(searchOrder);
      setIsSelectedOrder(true);
      if (myScan?.status === "picked_up") {
        if (!myScan.shippedBy) {
          setIsAnomaly(true);
          setMsgAnomaly(
            'Cette commande n\'est assignée à aucun livreur mais son statuts est "En livraison".'
          );
          setIsSelectedOrder(true);
          setSelectedOrder(myScan);
        } else if (myScan.shippedBy.firstName === dataStore?.firstname) {
          setIsAnomaly(true);
          setMsgAnomaly(
            "Vous avez déjà prise en charge cette commande, vérifier la liste des commandes à livrer."
          );
          setIsSelectedOrder(true);
          setSelectedOrder(myScan);
        } else {
          setIsAnomaly(true);
          setMsgAnomaly(
            "Cette commande est déjà prise en charge par " +
              myScan?.shippedBy.firstName
          );
          setIsSelectedOrder(true);
          setSelectedOrder(myScan);
        }
      } else if (myScan?.status === "created") {
        setIsAnomaly(true);
        setMsgAnomaly("Cette commande est en cours de préparation");
        setIsSelectedOrder(true);
        setSelectedOrder(myScan);
      } else if (myScan?.status === "ready_for_delivery") {
        if (
          myScan.bookingSlot?.slot?.temperatureZone?.locker &&
          myScan.bookingSlot?.slot?.temperatureZone?.locker["@id"] !==
            selectedStore
        ) {
          setIsAnomaly(true);
          setMsgAnomaly(
            "Commande pour : " +
              myScan?.bookingSlot?.slot?.temperatureZone?.locker?.location
          );
          setIsSelectedOrder(true);
          setSelectedOrder(myScan);
        } else {
          //OK
          setSelectedOrder(myScan);
          setIsSelectedOrder(true);
        }
      } else if (
        
        myScan?.status === "reminder" ||
        myScan?.status === "overtimedue" ||
        myScan?.status === "overtime"
      ) {
        setIsAnomaly(true);
        setMsgAnomaly(
          "Cette commande est en status : " +
            _getStatus(myScan?.status) +
            ", consultez l'historique. Code barre : " +
            myScan?.barcode
        );
        setIsSelectedOrder(true);
        setSelectedOrder(myScan);
      }
    } else {
      //no exist
      _searchWithRegex(searchOrder, orderByStatus, setFilteredOrder);
    }
  }, [dataStore?.firstname, orderReady, searchOrder, selectedStore, setIsSelectedOrder]);

  React.useEffect(() => {
    setStoreName(
      allSlot?.["hydra:member"] &&
        allSlot?.["hydra:member"]?.filter(
          (locker: any) =>
            locker?.slot?.temperatureZone?.locker["@id"] === selectedStore
        )[0]
    );
  }, [allSlot, selectedStore]);

  const getOrdersByMultiStatus = async (
    token: string,
    idLocker: string,
    setData: React.Dispatch<React.SetStateAction<any>>
  ) => {
    try {
      const response = await OrdersService.ordersByMultiStatus(token, idLocker);
      setIsLoading(false);
      setData(response.data);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };
  const multiOrderCodeTab = orderOperin["hydra:member"]?.map((element: any) => (
    element?.externalOrderId)
  );
  const countOccurrences = (arr: any) => {
    if(arr){

      const countMap = arr?.reduce((acc: any, val: any) => {
        if(val !== undefined){
          acc[val] = (acc[val] || 0) + 1;
        }
        return acc;
      }, {});
      
      // return Object.keys(countMap).map(key => ({ [key]: countMap[key] }));
      return countMap;
    }
    };
    const externalIdTab = countOccurrences(multiOrderCodeTab);
  

  //////////////////////////
  // Component Props
  /////////////////////////

  const anomalyProps = {
    setSelectedOrder,
    setIsAnomaly,
    selectedOrder,
    scanCode,
    msgAnomaly,
  };

  const searchBarProps = {
    searchOrder,
    setSearchOrder,
    selectedStore,
    setSelectedStore,
    selectedOrderCity,
    setSelectedOrderCity,
    allSlot,
    setIdStore,
  };

  const orderListProps = {
    filteredOrder,
    setSelectedOrder,
    searchOrder,
    setSearchOrder,
    orderByStatus,
    storeName,
    trigger,
    setIsSelectedOrder,
  };

  const scanPageProps = {
    selectedOrder,
    messageApi,
    setSelectedOrder,
    newStatus,
    setOrderReady,
    setOrderPickedUp,
    setSearchOrder,
    setOrderOperin,
    trigger,
    externalIdTab
  };



  return (
    <>
      {(!hasSuperAdmin && !hasAdmin && !hasTech) && <Navigate to="/connexion" />}
      {!selectedOrder && !isAnomaly && (
        <div className="sticky-top pt-2 bg-ui">
          <SearchBar searchBarProps={searchBarProps} />
        </div>
      )}
      <Container fluid className=" px-0 bg-ui">
        {contextHolder}
        {!userToken && <Navigate to="/connexion" />}
        {isError ? (
          <Container className="text-center mt-5">
            <AlertIsError
              title="Une erreur s'est produite"
              msg="Vérifiez votre connexion internet ou contactez votre administrateur."
              colorIcon="danger"
            />
          </Container>
        ) : isLoading ? (
          <Container className="text-center mt-2">
            <HistoryLoader itemCount={10} />
          </Container>
        ) : (
          <>
            {isAnomaly ? (
              <Container fluid className="pb-5">
                <div className="col-12 text-center pt-2 font-75 text-light">
                  {storeName &&
                    storeName?.slot?.temperatureZone?.locker?.location}
                </div>
                <Anomaly anomalyProps={anomalyProps} />
              </Container>
            ) : !selectedOrder ? (
              <OrderList orderListProps={orderListProps} />
            ) : (
              <DeliveryDetail scanPageProps={scanPageProps} />
            )}
          </>
        )}
      </Container>

    </>
  );
};

export default Delivered;
