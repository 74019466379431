import axios from "axios";

const API_URL = process.env.REACT_APP_END_POINT
class OrderService {
    historyOrders(token: string | null, page: number) {
        return axios.get(API_URL + "orders?itemsPerPage=10&q[]=cleveronSlotId&q[]=multiOrderCode&status[]=ready_for_delivery&status[]=operin&status[]=picked_up&status[]=operout&status[]=left_for_customer_service&q[]=createdAt&q[]=ageRestriction&status[]=reminder&status[]=overtimedue&status[]=overtime&q[]=status&q[]=id&q[shippedBy][]=firstName&q[shippedBy][]=lastName&q[]=barcode&q[]=products&q[bookingSlot][slot][temperatureZone][]=name&q[bookingSlot][slot][temperatureZone][]=keyTemp&q[bookingSlot][slot][temperatureZone][locker][]=@id&q[bookingSlot][slot][temperatureZone][locker][]=shortLocation&q[bookingSlot][slot][]=size&q[]=updatedAt&q[history][]=status&q[history][]=createdAt&q[hydra:view][hydra:next]&page=" +
        page, {headers: {"Authorization": "Bearer " + token}})
    }
    allOrders(token: string) {
        return axios.get(API_URL + "orders?itemsPerPage=1"  , {headers: {"Authorization": "Bearer " + token}})
    }
    ordersByDate(token: string, date: any) {
        return axios.get(API_URL + "orders?itemsPerPage=30&createdAt[after]=" + date , {headers: {"Authorization": "Bearer " + token}})
    }
    ordersByStatus(token: string, status: string) {
        return axios.get(API_URL + "orders?itemsPerPage=60&status=" + status + "&q[]=receiveCode&q[]=externalOrderId&q[client]=name&q[]=status&q[]=cleveronSlotId&q[company][name]&q[]=multiOrderCode&q[]=createdAt&q[]=ageRestriction&q[]=id&q[shippedBy][]=firstName&q[shippedBy][]=lastName&q[]=barcode&q[]=products&q[bookingSlot][slot][temperatureZone][]=name&q[bookingSlot][slot][temperatureZone][]=keyTemp&q[bookingSlot][slot][temperatureZone][locker][]=@id&q[bookingSlot][slot][temperatureZone][locker][]=type&q[bookingSlot][slot][temperatureZone][locker][]=shortLocation&q[bookingSlot][slot][]=size" , {headers: {"Authorization": "Bearer " + token}})
    }
    ordersByMultiStatus(token: string, idLocker: string) {
        return axios.get(`${API_URL}orders?itemsPerPage=60&q[locker][id][]=${idLocker}&status[]=reminder&status[]=operin&status[]=overtimedue&q[]=status&q[]=cleveronSlotId&q[]=multiOrderCode&q[]=createdAt&q[]=ageRestriction&q[]=id&q[shippedBy][]=firstName&q[shippedBy][]=lastName&q[]=barcode&q[]=products&q[bookingSlot][slot][temperatureZone][]=name&q[bookingSlot][slot][temperatureZone][]=keyTemp&q[bookingSlot][slot][temperatureZone][locker][]=@id&q[bookingSlot][slot][temperatureZone][locker][]=shortLocation&q[bookingSlot][slot][]=size` , {headers: {Authorization: `Bearer ${token}`}})
        // return axios.get(API_URL + "orders?itemsPerPage=30&q[locker][id]=" + idLocker + "&status[]=reminder&status[]=operin&status[]=overtimedue&q[]=status&q[]=cleveronSlotId&q[]=multiOrderCode&q[]=createdAt&q[]=ageRestriction&q[]=id&q[shippedBy][]=firstName&q[shippedBy][]=lastName&q[]=barcode&q[]=products&q[bookingSlot][slot][temperatureZone][]=name&q[bookingSlot][slot][temperatureZone][]=keyTemp&q[bookingSlot][slot][temperatureZone][locker][]=@id&q[bookingSlot][slot][temperatureZone][locker][]=shortLocation&q[bookingSlot][slot][]=size" , {headers: {"Authorization": "Bearer " + token}})
    }
    // ordersByMultiStatus(token: string) {
    //     return axios.get(API_URL + "orders?itemsPerPage=30&status[]=reminder&status[]=operin&status[]=overtimedue&q[]=status&q[]=cleveronSlotId&q[]=multiOrderCode&q[]=createdAt&q[]=ageRestriction&q[]=id&q[shippedBy][]=firstName&q[shippedBy][]=lastName&q[]=barcode&q[]=products&q[bookingSlot][slot][temperatureZone][]=name&q[bookingSlot][slot][temperatureZone][]=keyTemp&q[bookingSlot][slot][temperatureZone][locker][]=@id&q[bookingSlot][slot][temperatureZone][locker][]=shortLocation&q[bookingSlot][slot][]=size" , {headers: {"Authorization": "Bearer " + token}})
    // }
    ordersByPage(itemNber: number, token: string, page: number) {
        return axios.get(API_URL + "orders?itemsPerPage=" + itemNber + "&page=" + page , {headers: {"Authorization": "Bearer " + token}})
    }
    create(token: string, data: any) {
        return axios.post(API_URL + "orders", {headers: {"Authorization": "Bearer " + token}, data: data})
    }
    update(id: any, token: string, data: any) {
        return axios.patch(API_URL + "orders/" + id , {headers: {'Content-Type': 'application/merge-patch+json', "Authorization": "Bearer " + token}, data: data})
    }
    order(id: any, token: string) {
        return axios.get(API_URL + "orders/" + id + "?status[]=ready_for_delivery&status[]=operin&status[]=picked_up&q[]=createdAt&q[]=ageRestriction&status[]=reminder&status[]=overtimedue&status[]=overtime&q[]=status&q[]=id&q[shippedBy][]=firstName&q[shippedBy][]=lastName&q[]=barcode&q[]=products&q[bookingSlot][slot][temperatureZone][]=name&q[bookingSlot][slot][temperatureZone][locker][]=@id&q[bookingSlot][slot][temperatureZone][locker][]=shortLocation&q[bookingSlot][slot][]=size&q[history][]=status&q[]=updatedAt&q[history][]=createdAt&q[hydra:view][hydra:next]", {headers: {"Authorization": "Bearer " + token}})
    }
    resend(id: any, token: string) {
        return axios.get(API_URL + "orders/" + id + "/resend-notification", {headers: {"Authorization": "Bearer " + token}})
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new OrderService();