import React from "react";
import { Container, Button, Modal, Form, Spinner, Row, Col } from "react-bootstrap";
import userDataStore from "../../store/userDataStore";
import { useOutletContext } from "react-router-dom";
import { _bgTemp, _getOrdersByStatus, _strRandom, _tempZoneSelection } from "../../utils/functions";
import ProductTable from "./ProductTable";
import axios from "axios";
import { orderStatusType } from "../../utils/definitions";
import Confirmation from "./modals/Confirmation";
import { CancelSlotModal, CancelValidation, CustomerServiceValidation, OperinModal } from "./modals/Modals";
import { SelectedOrderInfoBar } from "./SelectedOrderInfoBar";

const DeliveryDetail = ({ scanPageProps }: any) => {
  ////////////////////
  //Props & store
  ///////////////////

  const { setIsSelectedOrder, allSlot, setOrderReady } = useOutletContext<any>();

  const {
    selectedOrder,
    setSelectedOrder,
    newStatus,
    setOrderPickedUp,
    setOrderOperin,
    setOrderExpired,
    setSearchOrder,
    trigger,
    externalIdTab,
  } = scanPageProps;

  const dataStore: any = userDataStore((states: any) => states);

  const [isErrorValid, setIsErrorValid] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  // const [errorMsg, setErrorMsg] = React.useState<string>("");

  const [isScroll, setIsScroll] = React.useState(false);
  const [newOrderData, setNewOrderData] = React.useState<any>({});
  const [multiOrderCode, setMultiOrderCode] = React.useState<any>(_strRandom("popopopp").toLocaleUpperCase());

  const [selectedOrderProducts, setSelectedOrderProducts] = React.useState<any>(selectedOrder?.products);
  const [chosenProducts, setChosenProducts] = React.useState<any>([]);


  const [show, setShow] = React.useState<boolean>(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //Modal d'édition
  const [showOrderEditModal, setShowOrderEditModal] =
    React.useState<boolean>(false);
  const handleCloseOrderEditModal = () => setShowOrderEditModal(false);
  const handleShowOrderEditModal = () => setShowOrderEditModal(true);
  //Modal de confirmation de retrait
  const [showRetreiveModal, setShowRetreiveModal] =
    React.useState<boolean>(false);
  const handleCloseRetreiveModal = () => setShowRetreiveModal(false);
  const handleShowRetreiveModal = () => setShowRetreiveModal(true);

  //Modal de confirmation service client
  const [showCustomerService, setShowCustomerService] =
    React.useState<boolean>(false);
  const handleCloseCustomerService = () => setShowCustomerService(false);
  const handleShowCustomerService = () => setShowCustomerService(true);

  
  const [showCancel, setShowCancel] = React.useState<boolean>(false);
  const handleCloseCancel = () => setShowCancel(false);
  const handleShowCancel = () => {
    setShowCancel(true);

    setTimeout(() => {
      setSelectedOrder(null);
      handleCloseCancel();
      setIsSelectedOrder(false);
    }, 2000);
  };

  //Modal de confirmation pour suppression d'un panier
  const [showCancelSlot, setShowCancelSlot] = React.useState<boolean>(false);
  const handleCloseCancelSlot = () => setShowCancelSlot(false);
  const handleShowCancelSlot = () => setShowCancelSlot(true);

  const [showAddSlot, setShowAddSlot] = React.useState<boolean>(false);
  const handleCloseAddSlot = () => setShowAddSlot(false);
  const handleShowAddSlot = () => setShowAddSlot(true);

  const [showUpdateStatus, setShowUpdateStatus] =
    React.useState<boolean>(false);
  const handleCloseUpdateStatus = () => setShowUpdateStatus(false);
  const handleShowUpdateStatus = () => {
    setShowUpdateStatus(true);

    setTimeout(() => {
      setSelectedOrder(null);
      handleCloseUpdateStatus();
      setIsSelectedOrder(false);
    }, 2000);
  };

  const indexOfOccurence = externalIdTab[selectedOrder?.externalOrderId] || 0;

  React.useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > 50) {
        setIsScroll(true);
      } else {
        setIsScroll(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  
  }, []);
  React.useEffect(() => {
    setNewOrderData({
      barcode: selectedOrder?.barcode.slice(0, 11) + (indexOfOccurence + 1),
      bookingSlot: selectedOrder?.bookingSlot["@id"],
      receiveCode: selectedOrder?.receiveCode,
      multiOrderCode: selectedOrder?.multiOrderCode,
      hold: 2880,
      products: [
        
      ],
      ageRestriction: selectedOrder?.ageRestriction
        ? selectedOrder?.ageRestriction
        : null,
      externalOrderId: selectedOrder?.externalOrderId,
      client: {
        firstname: selectedOrder?.client?.firstname,
        lastname: selectedOrder?.client?.lastname,
        email: selectedOrder?.client?.email,
        phone: selectedOrder?.client?.phone,
      },
    });
  }, []);

  // Function to manage the chosen products
  const handleCheckboxChange = (product: any) => {
    if (chosenProducts.includes(product)) {

      setChosenProducts(chosenProducts.filter((p: any) => p !== product));
      setNewOrderData((prevData: any) => ({
        ...prevData,
        products:[ chosenProducts.filter((p: any) => p !== product)]
      }))

      setSelectedOrderProducts([...selectedOrderProducts, product]);

    } else {
      setChosenProducts([...chosenProducts, product]);
      
      const newTab = [...newOrderData.products, product]
      setNewOrderData((prevData: any) => ({
        ...prevData,
        products:newTab
      }))
      
      const updatedProducts = selectedOrderProducts.filter((prod: any) => prod !== product);
      setSelectedOrderProducts(updatedProducts);
    }
  };

  const changeStatus = (newStatus: orderStatusType, handleShowModal: any) => {
    setIsLoading(true);

    let data = {
      status: newStatus,
      shippedBy: "api/users/" + dataStore.id,
    };
    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_END_POINT + "orders/" + selectedOrder.id,
      headers: {
        "Content-Type": "application/merge-patch+json",
        Authorization: "Bearer " + dataStore.token,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response: any) => {
        _getOrdersByStatus(
          dataStore.token,
          "picked_up",
          setOrderPickedUp,
          setIsLoading
        );
        setIsLoading(false);
        handleCloseCustomerService();
        handleCloseCancelSlot();
        handleClose();

        handleShowModal();
      })
      .catch((error: any) => {
        console.log("update error");
        console.log(error);
        setIsErrorValid(true);
        setIsLoading(false);
      });
  };

  const message: string =
    "Voulez-vous vraiment déposer cette commande au service client ?";
  const CancelMessage: string = "Voulez-vous vraiment supprimer ce panier ?";

  const handleSubmitNewOrder = (e: any) => {
    e.preventDefault();
    setIsLoading(true);
   
    
    const createOrderConfig = {
      method: "post",
      url: process.env.REACT_APP_END_POINT + "orders",
      headers: {
          Authorization: "Bearer " + dataStore.token,
          "Content-Type": "application/json",
      },
      data: newOrderData,
    };

    //function update order status to ready for delivery
    const updateOrderStatus = (orderId: string) => {
      const data = JSON.stringify({ status: "ready_for_delivery" });

      const config = {
          method: "patch",
          maxBodyLength: Infinity,
          url: process.env.REACT_APP_END_POINT + "orders/" + orderId,
          headers: {
              "Content-Type": "application/merge-patch+json",
              Authorization: "Bearer " + dataStore.token,
          },
          data: data,
      };

      return axios.request(config);
  };


    // function update selected order : 
    //  - add multiOrderCode
    //  - update product list
    const updateSelectedOrder = (multiOrderCode: string | null = null) => {
      const data = JSON.stringify({
          ...(multiOrderCode && { multiOrderCode }),
          products: selectedOrderProducts,
      });

      const config = {
          method: "patch",
          maxBodyLength: Infinity,
          url: process.env.REACT_APP_END_POINT + "orders/" + selectedOrder.id,
          headers: {
              "Content-Type": "application/merge-patch+json",
              Authorization: "Bearer " + dataStore.token,
          },
          data: data,
      };

      return axios.request(config);
  };



  axios.request(createOrderConfig).then((response: any) => {
    const newOrderId = response.data.id;

   
      updateOrderStatus(newOrderId)
        .then(() => {
          console.log("commande créée");

          _getOrdersByStatus(
            dataStore.token,
            "ready_for_delivery",
            setOrderReady,
            setIsLoading
          );
        })
        .catch((error: any) => {
          console.log(error);
        });
          })
        .catch((error: any) => {
            console.log(error);
        });





    if (indexOfOccurence === 1) {
    
      updateSelectedOrder(multiOrderCode)
      .then(() => {
          console.log("Commande origine modifiée");
          handleCloseAddSlot();
          handleCloseOrderEditModal();
          setSelectedOrder();
          _getOrdersByStatus(dataStore.token, "picked_up", setOrderPickedUp, setIsLoading);
      setIsLoading(false);
      })
      .catch((error: any) => {
          console.log(error);
      })
   
    } else {
     
 

      updateSelectedOrder()
      .then(() => {
          console.log("Commande origine modifiée");
          handleCloseAddSlot();
          handleCloseOrderEditModal();
          setSelectedOrder();
          _getOrdersByStatus(dataStore.token, "picked_up", setOrderPickedUp, setIsLoading);
      setIsLoading(false);
      })
      .catch((error: any) => {
          console.log(error);
      })
     
    }
  };




  const productTableProps = {
    selectedOrder,
    handleShow,
    newStatus,
    trigger,
    handleShowRetreiveModal,
    handleShowCustomerService,
    handleShowCancelSlot,
    handleShowOrderEditModal,
  };
  const confirmationProps = {
    showCustomerService,
    handleCloseCustomerService,
    selectedOrder,
    changeStatus,
    newStatus,
    isLoading,
    message,
    handleShowUpdateStatus,
    handleShowCustomerService,
    setOrderExpired,
    setIsLoading,
    setSelectedOrder,
    setIsSelectedOrder,
  };
  
  const CancelSlotModalProps = {    showCancelSlot,
    handleCloseCancelSlot,
    selectedOrder,
    changeStatus,
    newStatus,
    isLoading,
    CancelMessage,
    handleShowUpdateStatus,
    handleShowCancelSlot,
    setOrderPickedUp,
    setIsLoading,
    setSelectedOrder,
    setIsSelectedOrder,
    handleShowCancel, };
  const cancelValidationProps = { showCancel, handleCloseCancel };
  const CustomerServiceProps = { showUpdateStatus, handleCloseUpdateStatus };
  const operinModalProps = {selectedOrder, handleClose, setOrderPickedUp, setOrderOperin, setIsLoading, isLoading, setSelectedOrder, trigger}
  const selectedOrderInfoBarProps = {isScroll, setSelectedOrder, setSearchOrder, setIsSelectedOrder, selectedOrder};

  const faaaBooking = allSlot?.["hydra:member"]?.filter(
    (order: any) =>
      order?.company?.name ===
      selectedOrder?.company?.name
  )


  return (
    <Container fluid className="order-list pb-5">
      <div className="text-center">
        <p className="col-12 mb-0 text-center font-75 text-ui">
          Détail de la commande à livrer
        </p>
        <SelectedOrderInfoBar
          selectedOrderInfoBarProps={selectedOrderInfoBarProps}
        />
        <ProductTable productTableProps={productTableProps} />
      </div>

      <Confirmation confirmationProps={confirmationProps} />
      <CancelSlotModal CancelSlotModalProps={CancelSlotModalProps} />
      <CustomerServiceValidation CustomerServiceProps={CustomerServiceProps} />
      <CancelValidation cancelValidationProps={cancelValidationProps} />

      <Modal show={showAddSlot} onHide={handleCloseAddSlot} className="px-0">
        <Modal.Header className="border-bottom-0" closeButton>
          <Modal.Title>
            <i className="ri-add-line fs-2"></i> Ajouter
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmitNewOrder}>
            <Form.Select
              size="sm"
              onChange={(e) => {
                const bookingSlotId = e?.currentTarget?.value;
                if (indexOfOccurence === 1) {
                  setNewOrderData((prevData: any) => ({
                    ...prevData,
                    bookingSlot: bookingSlotId,
                    multiOrderCode: multiOrderCode,
                  }));
                } else {
                  setNewOrderData((prevData: any) => ({
                    ...prevData,
                    bookingSlot: bookingSlotId,
                  }));
                }
              }}
              aria-label="zone"
              className="border border-1 border-secondary my-2 text-ui-second "
              required
            >
              <option value="" className="text-ui-second">
                Température du Panier *
              </option>
              {faaaBooking?.map((lockers: any, index: any) => (
                <option
                  key={index}
                  value={lockers && lockers["@id"]}
                  className={`text-ui-second ${_bgTemp(lockers)}`}
                  disabled={lockers.available < 1 ? true : false}
                >
                  {_tempZoneSelection(lockers)} ({lockers?.slot.size}) -{" "}
                  {lockers?.available}{" "}
                  {lockers?.available > 1 ? "casiers" : "casier"} -{" "}
                  {lockers.slot.temperatureZone.locker.shortLocation}
                </option>
              ))}
            </Form.Select>
            <Row className="py-2">
              <Col xs={3} className="text-secondary">
                <strong>Qté</strong>
              </Col>
              <Col xs={6} className="text-secondary text-start">
                <strong>Désignation</strong>
              </Col>
              <Col xs={3} className="text-secondary text-start">
                <strong></strong>
              </Col>
            </Row>
            {selectedOrder?.products?.map((prod: any, index: any) => (
              <Row
                key={index}
                className={`py-2 ${
                  index % 2 === 0 && "table-row"
                } border-1 border-gray border-bottom`}
              >
                <Col xs={3} className="text-dark font-85 border-1 border-dark">
                  {prod?.quantity}
                </Col>
                <Col xs={6} className="text-dark font-85 text-start">
                  {prod?.name}{" "}
                </Col>
                <Col xs={3} className="text-dark font-85 text-start">
                  <Form.Check // prettier-ignore
                    type="checkbox"
                    id={`default-checkbox-${index}`}
                    value={prod}
                    onChange={() => handleCheckboxChange(prod)}
                    checked={chosenProducts.includes(prod)}
                    disabled={
                      selectedOrder?.products?.length -
                        chosenProducts?.length ===
                      1
                        ? true
                        : false
                    }
                  />
                </Col>
              </Row>
            ))}
            {/* <ul>

                {selectedOrder?.products?.map((prod: any) => (
                  <li>{prod?.name}</li>
                ))}
                
              </ul> */}
            <Container className="mt-4">
              <div className="d-flex justify-content-between">
                <div className="text-start">
                  <Button
                    className="rounded-pill border-yellow bg-yellow text-success px-"
                    type="button"
                    onClick={() => {
                      handleCloseAddSlot();
                      setChosenProducts([]);
                    }}
                  >
                    <i className="ri-close-fill"></i>{" "}
                    <span className="">annuler</span>
                  </Button>
                </div>
                <div>
                  <Button
                    className="bg-green rounded-pill border-green text-light px-"
                    type="submit"
                    // onClick={handleShowOrderEditModal}
                  >
                    {isLoading ? (
                      <Spinner size="sm" as="span" />
                    ) : (
                      <span>
                        <i className="ri-check-fill"></i> Valider{" "}
                      </span>
                    )}
                  </Button>
                </div>
              </div>
            </Container>
          </form>
        </Modal.Body>
      </Modal>
      <Modal
        show={showOrderEditModal}
        onHide={handleCloseOrderEditModal}
        className="px-0"
      >
        <Modal.Header className="border-bottom-0" closeButton>
          <Modal.Title>
            <i className="ri-edit-line fs-2"></i> Edition
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container className="pb-2">
            <div className="d-flex justify-content-between">
              <div className="text-start">
                <Button
                  className="bg-danger rounded-pill border-danger text-light"
                  type="button"
                  onClick={handleShowCancelSlot}
                >
                  <i className="ri-subtract-fill"></i>{" "}
                  <span className="">Supprimer</span>
                </Button>
              </div>
              <div>
                <Button
                  className="bg-dark rounded-pill border-dark text-light"
                  type="button"
                  onClick={handleShowAddSlot}
                  disabled={selectedOrder?.products?.length < 2 ? true : false}
                >
                  <i className="ri-add-fill"></i> Ajouter
                </Button>
              </div>
            </div>
          </Container>
        </Modal.Body>
          <Modal.Footer className="py- px-">
            <Button
              className="bg-yellow rounded-pill border-yellow text-green px-3"
              type="button"
              onClick={handleCloseOrderEditModal}
            >
              <strong className="">Annuler</strong>
            </Button>
          </Modal.Footer>
     
      </Modal>
      {/* Error modal*/}

      <Modal show={show} onHide={handleClose} className="px-0">
        {isErrorValid ? (
          <>
            <Modal.Header closeButton>
              <Modal.Title>
                <i className="ri-error-warning-line fs-2 text-warning"></i>
                Attention
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Une anomalie est survenue... Rafraichissez la page
            </Modal.Body>
            <Modal.Footer>
              <Button
                size="lg"
                className=" rounded-pill border-warning text-light ms-3 px-4"
                variant="warning"
                onClick={() => {
                  setSelectedOrder("");
                  handleClose();
                  setIsErrorValid(false);
                }}
              >
                Réessayez
              </Button>
            </Modal.Footer>
          </>
        ) : (
          <OperinModal operinModalProps={operinModalProps} />
        )}
      </Modal>
    </Container>
  );
};

export default DeliveryDetail;
