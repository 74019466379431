export function PreparationIcon() {
  return (
    <div>
      <i className="ri-list-check-3 fs-2 text-green bg-yellow rounded-1 "></i>
    </div>
  );
}

export function DeliveryIcon() {
  return (
    <div>
      <i className="ri-truck-line border border-1 p-1 rounded-1 fs-2 text-green"></i>
    </div>
  );
}

export function RetreiveIcon() {
  return (
    <div>
     <i className="ri-inbox-unarchive-line fs-3 text-light bg-darkGray rounded-1 p-1 align-bottom"></i>
     </div>
  );
}

export function OperinIcon() {
  return (
    <div>
      <i className="ri-list-check-3 fs-2 text-green bg-yellow rounded-1 "></i>
    </div>
  );
}
