import { HistoryLoaderItems } from './ItemsLoader'

export default function HistoryLoader({itemCount}: {itemCount: number}) {
  return (
    <>
    
    {Array.from({ length: itemCount }).map((_: any, indx: number) => (
      <HistoryLoaderItems key={indx} />
    ))}
  </>
)
}
