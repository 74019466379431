import {_iconFilter } from '../../utils/functions'

const BadgedIcon = ({ slot, borderColor, imgSize, componentSize, design }: any) => {
 
  return (
    <div
      className=" m-auto"
      style={{ width: `${componentSize}`, height: `${componentSize}` }}
    >
      <img
        alt="zone"
        src={_iconFilter(slot?.slot?.temperatureZone?.keyTemp)}
        style={{ width: `${imgSize}`, height: `${imgSize}` }}
      />
      <div
        className={`size-${design} border-${borderColor} font-55 fw-bold text-secondary`}
      >
        {slot?.slot?.size}
      </div>
    </div>
  );
}

export default BadgedIcon
