import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import imagLogo from '../../../styles/logo-easybox.png'
import useWebInstallPrompt from '../../../hooks/useWebInstallPrompt';

const PwaInstallModal = ({pwaInstallModalProps}: any) => {

    const {showModal, handleCloseModal, webInstallPrompt, handleWebInstallDeclined, handleWebInstallAccepted} = pwaInstallModalProps


  return (
    <Modal show={showModal} onHide={handleCloseModal} centered>
      <Modal.Header className='text-center'>
        <div
        className=''
          style={{
            height: '90px',
            width: '100%',
            marginTop: '-60px',
          }}
        >
          <img
            src={imagLogo}
            alt='logo'
            className='border-3 bg-white px-4'
            width={140}
            style={{
              borderTopRightRadius: '90%',
              borderTopLeftRadius: '90%',
            }}
          />
        </div>
      </Modal.Header>
      <Modal.Body className='text-center text-ui'>
       ⭐
       {' '}
        Installer l'application Easy Box.
      </Modal.Body>
      <Modal.Footer>
        
        <Button
           variant='transparent'
           className='text-ui-second bg-yellow-mast'
          onClick={() => {
            handleWebInstallDeclined()
            handleCloseModal()
          }}
        >
          <i className='ri-close-fill fs-4'></i> Fermer
        </Button>
        <Button
          variant='transparent'
          className='text-light bg-green'
          onClick={() => {
            handleWebInstallAccepted()
            handleCloseModal()
          }}
        >
          <i className='ri-check-fill fs-4'></i>  Installer
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default PwaInstallModal
