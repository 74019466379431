import React from "react";
import {
  Accordion,
  Button,
  Form,
  Spinner,
  Row,
  Col,
  InputGroup,
  FormGroup,
  FormCheck,
  Alert,
} from "react-bootstrap";
import { _bgTemp, _tempZoneSelection } from "../../utils/functions";
import { supabase } from "../../utils/supabaseClient";

const ProductForm2 = ({ productFormProps }: any) => {
  const {
    isOrderCreate,
    validOrder,
    handleChangeSelect,
    chosenLocker,
    qtySlot,
    setQtySlot,
    productDetail,
    setProductDetail,
    handleChangeProduct,
    handleDeleteProduct,
    handleAddProduct,
    ageRestrict, setAgeRestrict,
    setIndxScan,
    setIndexScan,
    startScan,
    handleAddSlotProduct,
    handleDeleteSlot,
    dispoTab,
    tempZones,
    showProd,
    setShowProd,
    
  } = productFormProps;


    //count how many basket in temp zone (fresh, ambiant or freeze)
    const selectedFreshZone = tempZones?.filter(
      (zone: string) => zone === "Froid positif"
    )?.length;
    
    const selectedAmbiantZone = tempZones?.filter(
      (zone: string) => zone === "Ambiant"
    )?.length;
   
    const selectedFreezeZone = tempZones?.filter(
      (zone: string) => zone === "Froid négatif"
    )?.length;

    //check locker zone availability
    const isAmbiantZone = selectedAmbiantZone > dispoTab?.ambiant;
    const isFreshZone = selectedFreshZone > dispoTab?.fresh;
    const isFreezeZone = selectedFreezeZone > dispoTab?.Freeze;

   

  React.useEffect(() => {
    const newTab = ageRestrict
    const ageTab = Array.from({ length: qtySlot }).map((_, indx) => (
      newTab[indx] =  false
      ))
      setAgeRestrict(ageTab)
  }, [qtySlot])    

  const handleChangeAgeRestrict = (indx: number) => {
    const newTab = ageRestrict
    newTab[indx] = !ageRestrict[indx]
    setAgeRestrict(newTab)
 
  }

  //show or not basket product
  const handleShowProd = (indx: number, bool: any) => {
    let newShowProd: boolean[] = [...showProd];
    newShowProd[indx] = bool;
    setShowProd(newShowProd);
  }

  // const getProd = async() => {
  //   let { data: products, error } = await supabase
  //   .from('products')
  //   .select('*')
  //   .eq('EAN','88009320626')
  
  //   if(products){
  //     setProd(products)
  //   }
  
  //  }
  

  return (
  
      <form onSubmit={validOrder}>
        <Accordion defaultActiveKey="0" alwaysOpen>
          {Array.from({ length: qtySlot }).map((_, indx) => (
            <React.Fragment key={indx * 10 + 25}>
              <Accordion.Item eventKey={`${indx}`}>
                <Accordion.Header>Panier n°{indx + 1}</Accordion.Header>
                <Accordion.Body>
                  <Form.Select
                    size="sm"
                    name={"selectTemp" + indx}
                    onChange={(e) => {
                      handleChangeSelect(e, indx);
                    }}
                    aria-label="zone"
                    className="border border-1 border-secondary my-2 text-ui-second "
                    required
                  >
                    <option value="" className="text-ui-second">
                      Température du Panier n°{indx + 1} *
                    </option>
                    {chosenLocker?.map((lockers: any, index: any) => (
                      <option
                        onChange={() => console.log("index")}
                        key={index}
                        value={JSON.stringify(lockers)}
                        className={`text-ui-second ${_bgTemp(lockers)}`}
                        disabled={lockers.available < 1 ? true : false}
                      >
                        {_tempZoneSelection(lockers)} ({lockers?.slot.size}) -{" "}
                        {lockers?.available}{" "}
                        {lockers?.available > 1 ? "casiers" : "casier"} -{" "}
                        {lockers.slot.height}X{lockers.slot.width}X
                        {lockers.slot.depth}
                      </option>
                    ))}
                  </Form.Select>

                  {!showProd[indx] ? (
                    <Button
                      variant="outline-secondary"
                      size="sm"
                      className="rounded-pill"
                      onClick={() => handleShowProd(indx, true)}
                    >
                      Afficher la saisie des produits
                    </Button>
                  ) : (
                    <>
                      <Row>
                        <Col
                          className="font-75 mx-0 px-0"
                          xs={1}
                          style={{ width: 5 }}
                        >
                          {}
                        </Col>
                        <Col className="font-75 my-0 py-0">
                          Produit <span className="text-danger">*</span>
                        </Col>
                        <Col xs={2} className="font-75 my-0 py-0 ps-0">
                          Qté <span className="text-danger">*</span>
                        </Col>
                        <Col xs={3} className="font-75 my-0 py-0">
                          Prix
                        </Col>
                        <Col className="font-75 mx-0 px-0" xs={1}>
                          {}
                        </Col>
                      </Row>
                      {productDetail[indx] &&
                        productDetail[indx]?.map((prod: any, index: any) => (
                          <React.Fragment key={index}>
                            <Row style={{ height: 40 }}>
                              <React.Fragment key={index}>
                                <Col
                                  className="font-75 mx-0 px-0"
                                  xs={1}
                                  style={{ width: 5 }}
                                >
                                  {index + 1}
                                </Col>

                                <Col className="px-1">
                                  <InputGroup className="mb-4">
                                    <Form.Control
                                      id={`${Math.random()}`}
                                      className="border border-1 border-secondary font-75"
                                      placeholder="Produit"
                                      value={prod?.name}
                                      onChange={(e) =>
                                        handleChangeProduct(
                                          e,
                                          indx,
                                          index,
                                          "name",
                                          productDetail,
                                          setProductDetail
                                        )
                                      }
                                      required
                                    />
                                  </InputGroup>
                                </Col>
                                <Col xs={2} className="px-0">
                                  <InputGroup className="mb-4 pe-0">
                                    <Form.Control
                                      id={`${Math.random()}`}
                                      className="px-0 text-center border border-1 border-secondary"
                                      type="number"
                                      placeholder="Qté"
                                      min={1}
                                      value={parseFloat(prod?.quantity) || ""}
                                      onChange={(e) =>
                                        handleChangeProduct(
                                          e,
                                          indx,
                                          index,
                                          "quantity",
                                          productDetail,
                                          setProductDetail
                                        )
                                      }
                                      required
                                    />
                                  </InputGroup>
                                </Col>
                                <Col xs={3} className="px-1">
                                  <InputGroup className="mb-4">
                                    <Form.Control
                                      id={`${Math.random()}`}
                                      className="border border-1 border-secondary"
                                      type="number"
                                      placeholder="prix"
                                      min={0}
                                      value={parseFloat(prod?.price) || ""}
                                      onChange={(e) =>
                                        handleChangeProduct(
                                          e,
                                          indx,
                                          index,
                                          "price",
                                          productDetail,
                                          setProductDetail
                                        )
                                      }
                                    />
                                  </InputGroup>
                                </Col>
                                <Col
                                  xs={1}
                                  className="px-0 "
                                  onClick={() => {

                                    setIndxScan(indx);
                                    setIndexScan(index);
                                    startScan(indx, index);
                                  }}
                                >
                                  <i className="ri-qr-scan-2-line fs-5 align-top text-secondary"></i>
                                </Col>
                              </React.Fragment>
                            </Row>
                            {productDetail[indx] &&
                              productDetail[indx]?.length > 1 && (
                                <Col
                                  xs={1}
                                  className="px-0 text-end mb-2 "
                                  onClick={() => {
                                    handleDeleteProduct(indx, prod?.id);
                                  }}
                                >
                                  <i className="ri-delete-bin-2-line fs-5 text-secondary"></i>
                                </Col>
                              )}
                          </React.Fragment>
                        ))}
                      <Button
                        aria-label="Aria Ajouter produit"
                        onClick={() => {
                          handleAddProduct(indx);
                        }}
                        variant="warning"
                        size="sm"
                        className="rounded-pill text-ui-second m-auto mt-2 py-1"
                      >
                        <i
                          className="ri-add-circle-line fs align-bottom "
                          title="ajouter un produit"
                        ></i>{" "}
                        <span className="font-75 ">Ajouter un produit</span>
                      </Button>

                      <div className="text-end mt-2">
                        <Button
                          variant="outline-secondary"
                          size="sm"
                          onClick={() => handleShowProd(indx, false)}
                          className="rounded-pill"
                        >
                          Masquer la saisie des produits
                        </Button>
                      </div>
                    </>
                  )}
                  <div className="mt-3">
                    <FormGroup
                      className="text-ui-second w-auto  "
                      controlId="formBasicCheckbox"
                    >
                      <FormCheck
                        type="checkbox"
                        label="Alcool (Restriction d'âge)"
                        className="fw-bold"
                        value={ageRestrict[indx]}
                        onChange={() => {
                          handleChangeAgeRestrict(indx);
                        }}
                      />
                      <i
                        className="ri-error-warning-line align-bottom text-warning"
                        title="avez-vous 18 ans "
                      ></i>{" "}
                      <span className="font-75 text-ui-second">
                        Cochez la case, s'il y a des produits alcoolisés dans la
                        commande.
                      </span>
                    </FormGroup>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </React.Fragment>
          ))}
        </Accordion>

        {qtySlot > 1 && (
          <Button
            variant="transparent"
            className=" rounded-pill bg-gray py-0 mt-1 text-ui-second "
            onClick={() => handleDeleteSlot(qtySlot?.length - 1)}
          >
            <Row>
              <Col xs={1}>
                <i className="ri-close-circle-line fs-5"></i>{" "}
              </Col>
              <Col className="px-1">
                <span className="font-85 align-bottom">Dernier panier</span>
              </Col>
            </Row>
          </Button>
        )}
        {(isAmbiantZone || isFreshZone || isFreezeZone) && (
          <Alert variant="danger" className="mt-1 py-1 text-danger">
            <i className="ri-alert-line fs-4"></i> {' '}
            <span className=" font-5">Attention : </span>
            <p className=" font-75">Vous n'avez plus de place en <b>zone {
              isAmbiantZone
                ? "ambiant"
                : isFreshZone
                ? "Fraîche"
                : isFreezeZone && "congelée"
            }</b>, vous devez retirer un panier ou annuler cette commande</p>
          </Alert>
        )}

        <div className="w-100 text-end mt-3">
          <Button
            aria-label="Aria Ajouter panier"
            onClick={() => {
              handleAddSlotProduct(qtySlot);
              setQtySlot(qtySlot + 1);
            }}
            className="rounded-pill text-light border-0  me-2 bg-darkGray py- px-3 "
          >
            <i
              className="ri-shopping-basket-2-fill fs-5 align-bottom me-2"
              title="ajouter un produit"
            ></i>{" "}
            Ajouter un panier
          </Button>
          <Button
            aria-label="Aria Valider prod"
            type="submit"
            className={`bg-green rounded-pill border-green text-light px-3
        `}
            disabled={
              isAmbiantZone || isFreshZone || isFreezeZone ? true : false
            }
          >
            {isOrderCreate && <Spinner size="sm" className="me-2" />}
            Valider
          </Button>
        </div>
      </form>
    
  );
};

export default ProductForm2;
