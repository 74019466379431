import { Modal, Row, Col, Button, Container, Spinner } from "react-bootstrap";
import BadgedIcon from "../BadgedIcon";
import { _changeStatus, _getOrdersByStatus } from "../../../utils/functions";
import userDataStore from "../../../store/userDataStore";
import QrCode from "../../QrCode";
import { AuthData } from "../../../utils/definitions";
import axios from "axios";





export function Confirmation({confirmationProps}: any) {
  const dataStore = userDataStore((state: AuthData) => state);

    const {showCustomerService, handleCloseCustomerService, selectedOrder, changeStatus, newStatus, isLoading, message, handleShowUpdateStatus, setOrderExpired,
      setIsLoading, setSelectedOrder, setIsSelectedOrder} = confirmationProps;

  return (
    <Modal show={showCustomerService} onHide={handleCloseCustomerService}>
        <Modal.Body className="pb-0">
          <Container className="text-center m-auto w-100 ">
            <Row>
              <Col xs={2} md={4} className="m-auto ps-0 ps-md-5">
                {" "}
                <BadgedIcon
                  slot={selectedOrder?.bookingSlot}
                  borderColor="gray"
                  imgSize="25px"
                  componentSize="35px"
                  design="indicator-small"
                />
              </Col>
              <Col className="m-auto text-start">
                <b>
                {selectedOrder?.barcode}
                </b>
              </Col>
            </Row>
          </Container>
          <Container className="text-start">
             {message}
          </Container>
        </Modal.Body>
        <Modal.Footer className="text-center  border-0">
          <Row>
            <Col xs={6}>
              <Button
                className=" rounded-pill border-yellow bg-yellow text-success px-4"
                variant=""
                onClick={() => {
                  handleCloseCustomerService();
                }}
              >
                Annuler
              </Button>
            </Col>
            <Col xs={6}>
              <Button
                type="submit"
                className="bg-green rounded-pill border-green text-light px-4 "
                onClick={() => {
                    if (newStatus === "operin") {
                      changeStatus(
                        "left_for_customer_service",
                        handleShowUpdateStatus
                      );
                    } else if (newStatus === "operout") {
                      _getOrdersByStatus(
                        dataStore.token,
                        "overtime",
                        setOrderExpired,
                        setIsLoading
                      );
                      setSelectedOrder(null);
                      handleCloseCustomerService();
                      setIsSelectedOrder(false);
                    } else {
                      changeStatus(newStatus, handleShowUpdateStatus);
                    }
               
                }}
              >
                {isLoading ? <Spinner size="sm" as="span" /> : "Valider"}
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
  )
}

export function CancelSlotModal({CancelSlotModalProps}: any) {
  const dataStore = userDataStore((state: AuthData) => state);

  const {showCancelSlot, handleCloseCancelSlot, selectedOrder, changeStatus, newStatus, isLoading, CancelMessage, handleShowUpdateStatus, setOrderPickedUp,
    setIsLoading, setSelectedOrder, setIsSelectedOrder, handleShowCancel} = CancelSlotModalProps;
  const DeleteButtonProps = { showCancelSlot, selectedOrder, setOrderPickedUp, setIsLoading, isLoading, handleCloseCancelSlot };

  return (
    <Modal show={showCancelSlot} onHide={handleCloseCancelSlot}>
      <Modal.Body className="pb-0 bg-attention rounded-top">
        <Container className="text-center m-auto w-100 ">
          <Row>
            <Col xs={2} md={4} className="m-auto ps-0 ps-md-5">
              {" "}
              <BadgedIcon
                slot={selectedOrder?.bookingSlot}
                borderColor="gray"
                imgSize="25px"
                componentSize="35px"
                design="indicator-small"
              />
            </Col>
            <Col className="m-auto text-start">
              <b>{selectedOrder?.barcode}</b>
            </Col>
          </Row>
        </Container>
        <Container className="text-start">{CancelMessage}</Container>
      </Modal.Body>
      <Modal.Footer className="text-center  border-0 bg-attention">
        <Row>
          <Col xs={6}>
            <Button
              className=" rounded-pill border-yellow bg-yellow text-success px-4"
              variant=""
              onClick={() => {
                handleCloseCancelSlot();
              }}
            >
              Annuler
            </Button>
          </Col>
          <Col xs={6}>
            <Button
              type="submit"
              className="bg-green rounded-pill border-green text-light px-4 "
              onClick={() => {
                changeStatus("cancel", handleShowCancel);
              }}
            >
              {isLoading ? <Spinner size="sm" as="span" /> : "Valider"}
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
}
export function CustomerServiceValidation({CustomerServiceProps}: any) {
    const {showUpdateStatus, handleCloseUpdateStatus} = CustomerServiceProps
  return (
    <Modal show={showUpdateStatus} onHide={handleCloseUpdateStatus}>
    <Modal.Body className="shadow rounded">
      {" "}
      {""}
      <Row className="m-auto">
        <Col xs={2}>
          <i className="ri-checkbox-circle-line text-success fs-1 me-2 animate__animated animate__fadeInDown"></i>
        </Col>
        <Col className="m-auto">Commande déposée au service client</Col>
      </Row>
    </Modal.Body>
  </Modal>
  )
}

export function RetreiveModal({retreiveModalProps}: any) {
    const dataStore: any = userDataStore((states: any) => states);

    const {showRetreiveModal, handleCloseRetreiveModal, selectedOrder, setOrderExpired, setIsLoading, setSelectedOrder, setIsSelectedOrder, isLoading} = retreiveModalProps
  return (
    <Modal show={showRetreiveModal} onHide={handleCloseRetreiveModal}>
    <Modal.Body className="pb-0">
      <Container className="text-center m-auto w-75">
        <Row>
          <Col className="m-auto ">
            {" "}
            <BadgedIcon
              slot={selectedOrder?.bookingSlot}
              borderColor="gray"
              imgSize="25px"
              componentSize="35px"
              design="indicator-small"
            />
          </Col>
          {/* <Col className="m-auto text-start">
            <b>
              Zone {selectedOrder?.bookingSlot?.slot?.temperatureZone?.name}
            </b>
          </Col> */}
        </Row>
      </Container>
      <Container className="text-center">
        {selectedOrder?.barcode}
      </Container>
      <Container className="text-start">
        Avez-vous retiré cette commande du locker ?
      </Container>
    </Modal.Body>
    <Modal.Footer className="center  border-0">
      <Row>
        <Col xs={6}>
          <Button
            size="lg"
            className=" rounded-pill border-yellow bg-yellow text-success px-4"
            variant=""
            onClick={() => {
              handleCloseRetreiveModal();
            }}
          >
            Annuler
          </Button>
        </Col>
        <Col xs={6}>
          <Button
            size="lg"
            type="submit"
            className="bg-green rounded-pill border-green text-light px-4 "
            onClick={() => {
              _getOrdersByStatus(
                dataStore.token,
                "overtime",
                setOrderExpired,
                setIsLoading
              );
              setSelectedOrder(null);
              handleCloseRetreiveModal();
              setIsSelectedOrder(false);
            }}
          >
            {isLoading ? <Spinner size="sm" as="span" /> : "Valider"}
          </Button>
        </Col>
      </Row>
    </Modal.Footer>
  </Modal>
  )
}

//For  OrderDetail
export function PickedUpModal({pickedUpModalProps}: any) {
    const {handleClose, isLoading, option} = pickedUpModalProps

  return (
    <>
    <Modal.Header closeButton>
      <Modal.Title>Prise en charge</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      Voulez-vous prendre en charge cette commande ?
    </Modal.Body>
    <Modal.Footer>
      <Button
        size="lg"
        className=" rounded-pill border-yellow bg-yellow text-success ms-3 px-4"
        onClick={() => {
          handleClose();
        }}
      >
        Non
      </Button>
      <Button
        size="lg"
        type="submit"
        className="bg-green rounded-pill border-green ms-3 px-4 "
        onClick={() => _changeStatus({option})}
      >
        {isLoading ? <Spinner size="sm" as="span" /> : "Oui"}
      </Button>
    </Modal.Footer>
  </>
  );
}

export function OperinModal({operinModalProps}: any) {
    const dataStore: any = userDataStore((states: any) => states);
    const {selectedOrder, handleClose, setOrderPickedUp, setOrderOperin, setIsLoading, isLoading, setSelectedOrder, trigger} = operinModalProps
  return (
    <>
      <Modal.Body className="pb-0">
        <Container className="text-center m-auto w-75">
          <Row>
            <Col className="m-auto ">
              {" "}
              <BadgedIcon
                slot={selectedOrder?.bookingSlot}
                borderColor="gray"
                imgSize="25px"
                componentSize="35px"
                design="indicator-small"
              />
            
            </Col>
            {/* <Col className="m-auto text-center">
              <b>
                {selectedOrder?.bookingSlot?.slot?.temperatureZone?.name}
              </b>
            </Col> */}
          </Row>
        </Container>
        <Container className="bg-light p-2 border">
          <div className="m-auto">
            <QrCode data={`${selectedOrder?.barcode}`} />
          </div>
        </Container>
        <Container className="text-center">{selectedOrder?.barcode}</Container>
      </Modal.Body>
      <Modal.Footer className="center  border-0">
        <Row>
          <Col xs={6}>
            <Button
              size="lg"
              className=" rounded-pill border-yellow bg-yellow text-success px-4"
              variant=""
              onClick={() => {
                handleClose();
              }}
            >
              Annuler
            </Button>
          </Col>
          <Col xs={6}>
            {trigger === "livraisons" ? (
              <Button
                size="lg"
                type="submit"
                className="bg-green rounded-pill border-green text-light px-4 "
                onClick={() => {
                  _getOrdersByStatus(
                    dataStore.token,
                    "picked_up",
                    setOrderPickedUp,
                    setIsLoading
                  );
                  setSelectedOrder(null);
                  handleClose();
                }}
              >
                {isLoading ? <Spinner size="sm" as="span" /> : "Déposer"}
              </Button>
            ) : (
              <Button
                size="lg"
                type="submit"
                className="bg-green rounded-pill border-green text-light px-4 "
                onClick={() => {
                  _getOrdersByStatus(
                    dataStore.token,
                    "operin",
                    setOrderOperin,
                    setIsLoading
                  );
                  setSelectedOrder(null);
                  handleClose();
                }}
              >
                {isLoading ? <Spinner size="sm" as="span" /> : "Retirer"}
              </Button>
            )}
          </Col>
        </Row>
      </Modal.Footer>
    </>
  );
}

export function ConfirmationCancel({ confirmationCancelProps }: any) {
  const {
    show,
    handleClose,
    selectedOrder,
    changeStatus,
    isLoading,
    handleShowCancel,
  } = confirmationCancelProps;

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Body className="text-light pb-0 bg-attention rounded-top">
        <Container className="text-center m-auto w-100 ">
          <Row>
            <Col xs={2} md={4} className="m-auto ps-0 ps-md-5">
              {" "}
              <BadgedIcon
                slot={selectedOrder?.bookingSlot}
                borderColor="gray"
                imgSize="25px"
                componentSize="35px"
                design="indicator-small"
              />
            </Col>
            <Col className="m-auto text-start">
              <b>{selectedOrder?.barcode}</b>
            </Col>
          </Row>
        </Container>
        <Container className="text-start">
          Etes-vous sur de vouloir annuler cette commande ?
        </Container>
      </Modal.Body>
      <Modal.Footer className="text-center  border-0 bg-attention">
        <Row>
          <Col xs={6}>
            <Button
              className=" rounded-pill text-light px-4 border-2"
              variant="secondary"
              onClick={() => {
                handleClose();
              }}
            >
              Non
            </Button>
          </Col>
          <Col xs={6}>
            <Button
              type="submit"
              className="bg-yellow border-yellow rounded-pill text-danger px-4 "
              onClick={() => {
                changeStatus("cancel", handleShowCancel);
              }}
            >
              {isLoading ? <Spinner size="sm" as="span" /> : "Valider"}
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
}

export function CancelValidation({cancelValidationProps}: any) {
    const {showCancel, handleCloseCancel} = cancelValidationProps
  return (
    <Modal show={showCancel} onHide={handleCloseCancel}>
    <Modal.Body className="shadow rounded">
      {" "}
      {""}
      <Row className="m-auto">
        <Col xs={2}>
          <i className="ri-checkbox-circle-line text-success fs-1 me-2 animate__animated animate__fadeInDown"></i>
        </Col>
        <Col className="m-auto">Cette commande a été annulée</Col>
      </Row>
    </Modal.Body>
  </Modal>
  )
}


const DeleteButton = (DeleteButtonProps: any) => {
  const dataStore: any = userDataStore((states: any) => states);
  const { handleShowModal, selectedOrder, setOrderPickedUp, setIsLoading, isLoading, handleCloseCancelSlot, handleClose, handleShowCancel } = DeleteButtonProps;
  const changeStatusToCancel = (newStatus: any, handleShowModal: any) => {
    // setIsLoading(true);
  
    let data = {
      status: newStatus,
      shippedBy: "api/users/" + dataStore.id,
    };
    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_END_POINT + "orders/" + selectedOrder.id,
      headers: {
        "Content-Type": "application/merge-patch+json",
        Authorization: "Bearer " + dataStore.token,
      },
      data: data,
    };
  
    axios
      .request(config)
      .then((response: any) => {
        _getOrdersByStatus(
          dataStore.token,
          "picked_up",
          setOrderPickedUp,
          setIsLoading
        );
        setIsLoading(false);
        handleCloseCancelSlot();
        // handleClose();
  
        // handleShowModal();
        console.log("Deleted")
      })
      .catch((error: any) => {
        console.log("update error");
        console.log(error);
        // setIsErrorValid(true);
        setIsLoading(false);
      });
  };
  return (
    <Button
    type="submit"
    className="bg-green rounded-pill border-green text-light px-4 "
    onClick={() => {
      changeStatusToCancel('cancel', handleShowCancel);
    }}
  >
    {isLoading ? <Spinner size="sm" as="span" /> : "Valider"}
  </Button>
  )
}


