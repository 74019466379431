import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import BackButton from "../../components/ui/BackButton";

const InfoTopBar2 = ({ infoToBarProps }: any) => {
  const {
    chosenLocker,
    trigger2,
    handleSecondStepClick,
    handleThirdStepClick,
  } = infoToBarProps;

  return (
    <>
      <Col
        xs={2}
        md={4}
        lg={4}
        className="py-2  text-ui-second"
        onClick={!trigger2 ? handleSecondStepClick : handleThirdStepClick}
      >
        {chosenLocker?.length === 0 ? (
          <Link
            to="/preparations"
            className="text-decoration-none text-ui-second"
          >
            <BackButton />
          </Link>
        ) : (
          <BackButton />
        )}
      </Col>
      <Col className="m-auto text-ui-second text-center">
        {chosenLocker?.length === 0 ? (
          <>
            <i className="ri-box-3-fill fs-3  align-bottom me-2"></i>
            <span className="fw-bold font-85">Sélectionnez un locker</span>
          </>
        ) : !trigger2 ? (
          <>
            <i className="ri-inbox-archive-fill fs-3 align-bottom me-2"></i>
            <span className="fw-bold font-85">Ajouter des produits</span>
          </>
        ) : (
          <>
            <i className="ri-user-fill fs-3 align-bottom me-2"></i>
            <span className="fw-bold font-85">Informations client</span>
          </>
        )}
      </Col>
      <Col xs={2} md={4} lg={4}></Col>
    </>
  );
};

export default InfoTopBar2;
