import React from "react";
import { Card, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useOutletContext } from "react-router-dom";

const LockerChoice = () => {
  const { setSelectedStore, setSelectedOrderCity, allSlot } =
    useOutletContext<any>();

  const [bookingTab, setBookingTab] = React.useState<string[] | undefined>();

  React.useEffect(() => {}, []);
  React.useEffect(() => {
    const bookingLocker: any = allSlot?.["hydra:member"]?.map(
      (locker: any) => locker?.slot?.temperatureZone?.locker
    );
    const unique: any = [
      ...new Set(bookingLocker?.map((locker: any) => locker?.shortLocation)),
    ];

    setBookingTab(unique);
  }, []);

  const filteredStore = (place: any) => {
    const city: any = allSlot?.["hydra:member"]
      ?.map((locker: any) => locker?.slot?.temperatureZone?.locker)
      .filter((lockerCity: any) => lockerCity?.shortLocation === place);
    return city && city[0]["@id"];
  };


  return (
    <Container
      fluid
      className="text-center d-flex flex-column justify-content-center locker-choice mt-5 pt-5"
    >
      {bookingTab?.map((booking: string) => (
        <Link
          to="/preparations"
          className="text-decoration-none"
          onClick={() => {
            setSelectedStore(filteredStore(booking));
            setSelectedOrderCity(booking);
          }}
        >
          <Card
            className={`g- bg-light mx-auto mb-3`}
            style={{ width: "32%", height: "100px" }}
          >
            <Card.Body>
              <Card.Text>
                <i className="ri-box-3-fill text-green fs-3"></i>{" "}
                <p className="font-75">{booking}</p>
              </Card.Text>
            </Card.Body>
          </Card>
        </Link>
      ))}
    </Container>
  );
};

export default LockerChoice;
