import { QRCode } from 'antd'

const QrCode = ({ data }: any) => {
  return (
    <QRCode
      errorLevel='H'
      value={data}
      // size={240}
      size={180}

      bgColor='#eee'
      color='#180202'
      style={{
        margin: 'auto',
      }}
    />
  )
}

export default QrCode
