import React from "react";
import { Navigate, useOutletContext } from "react-router-dom";
import userDataStore, { UserState } from "../store/userDataStore";
import { message } from "antd";
import { Alert, Button, Container, Modal } from "react-bootstrap";
import { _getStatus, _hasUserRole, _searchWithRegex } from "../utils/functions";
import SearchBar from "../components/ui/SearchBar";
import AlertIsError from "../components/ui/warning/AlertIsError";
import PlaceHolder from "../components/ui/loading/PlaceHolder";
import "../App.css";
import "animate.css";
import OrderList from "../components/ui/OrderList";
import jsQR from "jsqr";
import DeliveryDetail from "../components/ui/DeliveryDetail";
import UserQrcode from "../components/ui/modals/UserQrcode";
import Anomaly from "../components/ui/anomaly/Anomaly";
import { AuthData } from "../utils/definitions";



const InDelivery: React.FC = () => {
  //////////////////////////
  // booleans States
  /////////////////////////
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isError, setIsError] = React.useState<boolean>(false);

  //////////////////////////
  // Store & context state
  /////////////////////////
  const dataStore = userDataStore((state: AuthData) => state);
  const authLogin = userDataStore((state: any) => state.authLogin);

  const {
    setSelectedStore,
    setSelectedOrderCity,
    allSlot,
    setSelectedItem,
    selectedStore,
    selectedOrderCity,
    orderPickedUp,
    setOrderPickedUp,
    setIsSelectedOrder,
    idStore,
    setIdStore,
  } = useOutletContext<any>();

  const userToken = localStorage.getItem("user");
  const userRoles = userDataStore((state: UserState) => state.roles);
  const hasSuperAdmin = _hasUserRole(userRoles, "ROLE_SUPER_ADMIN");
  const hasAdmin = _hasUserRole(userRoles, "ROLE_ADMIN");

  //////////////////////////
  // States
  /////////////////////////

  const [messageApi, contextHolder] = message.useMessage();

  const [selectedOrder, setSelectedOrder] = React.useState<any>("");
  const [searchOrder, setSearchOrder] = React.useState<string>("");
  const [filteredOrder, setFilteredOrder] = React.useState<any>([]);
  const [storeName, setStoreName] = React.useState<any>([]);

  const [isScan, setIsScan] = React.useState<boolean>(false);
  const videoRef = React.useRef<HTMLVideoElement>(null);
  const [scanCode, setScanCode] = React.useState<string>("");

  const [isAnomaly, setIsAnomaly] = React.useState<boolean>(false);
  const [msgAnomaly, setMsgAnomaly] = React.useState<string>("");

  let videoStream: MediaStream | null = null;

  const trigger = "livraisons";
  const newStatus = "operin";

  //////////////////////////
  //Auth deliverer modal
  //////////////////////////
  const [show, setShow] = React.useState<boolean>(false);
  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  const [showAlert, setShowAlert] = React.useState<boolean>(false);
  const handleCloseAlert = () => setShowAlert(false);
  const handleShowAlert = () => {
    setShowAlert(true);
    authLogin(
      true,
      dataStore.id,
      dataStore.firstname,
      dataStore?.company_id,
      dataStore?.cleveronCompany_id,
      dataStore?.company_name,
      dataStore?.token,
      dataStore?.subToken ? dataStore?.subToken + 1 : 1,
      dataStore.apm_access_code,
      dataStore.roles
    );
  };

  ///////////////////////////////////////////////////
  ////Filtrage des données par locker et par livreur
  //////////////////////////////////////////////////
  const orderByStatus = orderPickedUp["hydra:member"]?.filter(
    (order: any) =>
      hasSuperAdmin ?
      order?.bookingSlot?.slot?.temperatureZone?.locker &&
      order?.bookingSlot?.slot?.temperatureZone?.locker["@id"] ===
        selectedStore
      
      : 
      order?.bookingSlot?.slot?.temperatureZone?.locker &&
      order?.bookingSlot?.slot?.temperatureZone?.locker["@id"] ===
        selectedStore &&
      order?.shippedBy &&
      order?.shippedBy["@id"] === `/api/users/${dataStore.id}`
  );


  //////////////////////////
  // UseEffect
  /////////////////////////

  React.useEffect(() => {
    setIsLoading(true);
    setSelectedItem("progress");
    setIsSelectedOrder(false);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  React.useEffect(() => {
    if (orderByStatus) {
      setIsLoading(false);
    } else {
      if (orderByStatus?.length < 0) {
        setIsError(true);
        setIsLoading(false);
      } else {
        setIsLoading(true);
      }
    }
  }, [orderByStatus]);

  React.useEffect(() => {
    const myScan = orderPickedUp["hydra:member"]?.filter(
      (order: any) =>
        order?.barcode === searchOrder || order?.externalOrderId === searchOrder
    )[0];

    if (myScan) {
      setScanCode(searchOrder);
      if (myScan?.status === "picked_up") {
        if (!myScan.shippedBy) {
          setIsAnomaly(true);
          setMsgAnomaly(
            'Cette commande n\'est assignée à aucun livreur mais son statuts est "En livraison".'
          );
           setIsSelectedOrder(true)
                  setSelectedOrder(myScan);
        } else if (myScan.shippedBy.firstName !== dataStore?.firstname) {
          setIsAnomaly(true);
          setMsgAnomaly(
            "Cette commande est déjà prise en charge par " +
              myScan?.shippedBy.firstName
          );
           setIsSelectedOrder(true)
                  setSelectedOrder(myScan);
        } else if (
          myScan.bookingSlot?.slot?.temperatureZone?.locker &&
          myScan.bookingSlot?.slot?.temperatureZone?.locker["@id"] !==
            selectedStore
        ) {
          setIsAnomaly(true);
          setMsgAnomaly(
            "Commande pour : " +
              myScan?.bookingSlot?.slot?.temperatureZone?.locker?.location
          );
           setIsSelectedOrder(true)
                  setSelectedOrder(myScan);
        } else {
          //OK
           setIsSelectedOrder(true)
                  setSelectedOrder(myScan);
        }
      } else if (myScan?.status === "created") {
        setIsAnomaly(true);
        setMsgAnomaly("Cette commande est en cours de préparation");
         setIsSelectedOrder(true)
                  setSelectedOrder(myScan);
      } else if (myScan?.status === "ready_for_delivery") {
        if (
          myScan.bookingSlot?.slot?.temperatureZone?.locker &&
          myScan.bookingSlot?.slot?.temperatureZone?.locker["@id"] !==
            selectedStore
        ) {
          setIsAnomaly(true);
          setMsgAnomaly(
            "Commande pour : " +
              myScan?.bookingSlot?.slot?.temperatureZone?.locker?.location
          );
           setIsSelectedOrder(true)
                  setSelectedOrder(myScan);
        } else {
          setIsAnomaly(true);
          setMsgAnomaly("Cette commande est sur le quai des livraisons");
           setIsSelectedOrder(true)
                  setSelectedOrder(myScan);
        }
      } else if (
        myScan?.status === "operin" ||
        myScan?.status === "reminder" ||
        myScan?.status === "overtimedue" ||
        myScan?.status === "overtime"
      ) {
        setIsAnomaly(true);
        setMsgAnomaly(
          "Cette commande est en status : " +
            _getStatus(myScan?.status) +
            ", consultez l'historique. Code barre : " +
            myScan?.barcode
        );
        setIsSelectedOrder(true)
        setSelectedOrder(myScan);
      }
    } else {
      //no exist
      _searchWithRegex(searchOrder, orderByStatus, setFilteredOrder);
    }
  }, [searchOrder]);

  React.useEffect(() => {
    setStoreName(
      allSlot?.["hydra:member"] &&
        allSlot?.["hydra:member"]?.filter(
          (locker: any) =>
            locker?.slot?.temperatureZone?.locker["@id"] === selectedStore
        )[0]
    );
  }, [selectedStore, allSlot]);

  React.useEffect(() => {
    if (selectedOrder !== "") {
      if (dataStore.subToken < 3) handleShowAlert();
    }
  }, [selectedOrder]);

  const handleScan = async () => {
    setIsAnomaly(false);
    if (navigator?.mediaDevices) {
      setIsScan(true);

      try {
        const stream = await navigator?.mediaDevices?.getUserMedia({
          video: { facingMode: "environment" },
        });
        videoStream = stream;
        videoRef.current!.srcObject = stream;
        await videoRef.current!.play(); // Attendre la lecture vidéo
        requestAnimationFrame(scanQRCode);
        setIsScan(true);
      } catch (error) {
        console.error("Error accessing camera:", error);
      }
    }
  };

  const stopScan = () => {
    videoStream?.getTracks().forEach((track) => track.stop());
    videoStream = null;
    setIsScan(false);
  };

  const scanQRCode = () => {
    if (
      videoRef.current &&
      videoStream &&
      videoRef.current.videoWidth &&
      videoRef.current.videoHeight
    ) {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");

      canvas.width = videoRef.current.videoWidth;
      canvas.height = videoRef.current.videoHeight;
      context?.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

      const imageData = context?.getImageData(
        0,
        0,
        canvas.width,
        canvas.height
      );
      if (imageData) {
        const code = jsQR(imageData.data, imageData.width, imageData.height);

        if (code) {
          console.log("QR Code detected:", code.data);
          setScanCode(code.data);
          if (code.data === "") {
            setIsAnomaly(false);
            setIsScan(false);
          } else {
            const myScan: any = orderPickedUp["hydra:member"]?.filter(
              (order: any) =>
                order?.barcode === code?.data ||
                order?.externalOrderId === code?.data
            )[0];
            if (myScan) {
              if (myScan?.status === "picked_up") {
                if (!myScan.shippedBy) {
                  setIsAnomaly(true);
                  setMsgAnomaly(
                    'Cette commande n\'est assignée à aucun livreur mais son statuts est "En livraison".'
                  );
                   setIsSelectedOrder(true)
                  setSelectedOrder(myScan);
                } else if (
                  myScan.shippedBy.firstName !== dataStore?.firstname
                ) {
                  setIsAnomaly(true);
                  setMsgAnomaly(
                    "Cette commande est déjà prise en charge par " +
                      myScan?.shippedBy.firstName
                  );
                   setIsSelectedOrder(true)
                  setSelectedOrder(myScan);
                } else if (
                  myScan.bookingSlot?.slot?.temperatureZone?.locker &&
                  myScan.bookingSlot?.slot?.temperatureZone?.locker["@id"] !==
                    selectedStore
                ) {
                  setIsAnomaly(true);
                  setMsgAnomaly(
                    "Commande pour : " +
                      myScan?.bookingSlot?.slot?.temperatureZone?.locker
                        ?.location
                  );
                   setIsSelectedOrder(true)
                  setSelectedOrder(myScan);
                } else {
                  //OK
                   setIsSelectedOrder(true)
                  setSelectedOrder(myScan);
                }
              } else if (myScan?.status === "created") {
                setIsAnomaly(true);
                setMsgAnomaly("Cette commande est en cours de préparation");
                 setIsSelectedOrder(true)
                  setSelectedOrder(myScan);
              } else if (myScan?.status === "ready_for_delivery") {
                if (
                  myScan.bookingSlot?.slot?.temperatureZone?.locker &&
                  myScan.bookingSlot?.slot?.temperatureZone?.locker["@id"] !==
                    selectedStore
                ) {
                  setIsAnomaly(true);
                  setMsgAnomaly(
                    "Commande pour : " +
                      myScan?.bookingSlot?.slot?.temperatureZone?.locker
                        ?.location
                  );
                   setIsSelectedOrder(true)
                  setSelectedOrder(myScan);
                } else {
                  setIsAnomaly(true);
                  setMsgAnomaly(
                    "Cette commande est sur le quai des livraisons"
                  );
                   setIsSelectedOrder(true)
                  setSelectedOrder(myScan);
                }
              } else if (
                myScan?.status === "operin" ||
                myScan?.status === "reminder" ||
                myScan?.status === "overtimedue" ||
                myScan?.status === "overtime"
              ) {
                setIsAnomaly(true);
                setMsgAnomaly(
                  "Cette commande est en status : " +
                    _getStatus(myScan?.status) +
                    ", consultez l'historique. Code barre : " +
                    myScan?.barcode
                );
                 setIsSelectedOrder(true)
                  setSelectedOrder(myScan);
              }
            } else {
              //no exist
              setMsgAnomaly("Cette commande n'existe pas.");
              setIsAnomaly(true);
            }
          }
          stopScan();
        }
      }
      requestAnimationFrame(scanQRCode);
    }
  };

  const multiOrderCodeTab = orderPickedUp["hydra:member"]?.map((element: any) => (
    element?.externalOrderId)
  );
  const countOccurrences = (arr: any) => {
    if(arr){

      const countMap = arr?.reduce((acc: any, val: any) => {
        if(val !== undefined){
          acc[val] = (acc[val] || 0) + 1;
        }
        return acc;
      }, {});
      
      // return Object.keys(countMap).map(key => ({ [key]: countMap[key] }));
      return countMap;
    }
    };
  
  const externalIdTab = countOccurrences(multiOrderCodeTab);


  //////////////////////////
  // Component Props
  /////////////////////////

  const anomalyProps = {
    setSelectedOrder,
    setIsAnomaly,
    selectedOrder,
    scanCode,
    msgAnomaly,
  };

  const searchBarProps = {
    searchOrder,
    setSearchOrder,
    selectedStore,
    setSelectedStore,
    selectedOrderCity,
    setSelectedOrderCity,
    allSlot,
    setIdStore,
  };

  const orderListProps = {
    filteredOrder,
    setSelectedOrder,
    searchOrder,
    setSearchOrder,
    orderByStatus,
    storeName,
    trigger,
    setIsSelectedOrder,
  };

  const scanPageProps = {
    selectedOrder,
    messageApi,
    setSelectedOrder,
    newStatus,
    setOrderPickedUp,
    setSearchOrder,
    trigger,
    externalIdTab,
  };

  console.log(orderPickedUp)
  
 
  return (
    <>
      {!selectedOrder && !isAnomaly && (
        <div className={`${!isScan ? "sticky-top pt-2 " : "d-none"} bg-ui`}>
          <SearchBar searchBarProps={searchBarProps} />
        </div>
      )}
      <UserQrcode show={show} handleClose={handleClose} />

      <Container fluid className="cde App px-0">
        {contextHolder}
        {!dataStore.token && <Navigate to="/connexion" />}
        {isError ? (
          <Container className="text-center mt-5">
            <AlertIsError
              title="Une erreur s'est produite"
              msg="Vérifiez votre connexion internet ou contactez votre administrateur."
              colorIcon="danger"
            />
          </Container>
        ) : isLoading ? (
          <Container className="text-center mt-2">
            <PlaceHolder itemCount={10} />
          </Container>
        ) : (
          <>
            {isAnomaly ? (
              <Container fluid className="pb-5">
                <div className="col-12 text-center font-75 pt-2 text-light">
                  {storeName &&
                    storeName?.slot?.temperatureZone?.locker?.location}
                </div>
                <Anomaly anomalyProps={anomalyProps} />
              </Container>
            ) : !selectedOrder ? (
              <>
                <OrderList orderListProps={orderListProps} />
              </>
            ) : (
              <DeliveryDetail scanPageProps={scanPageProps} />
            )}
          </>
        )}
      </Container>
      {isScan && (
        <div className="video-container text-center">
          <video ref={videoRef} />
        </div>
      )}
      {!selectedOrder && (
        <Button
          aria-label="Aria Scan"
          title="scan"
          className={`fab rounded-circle ${
            isScan ? "bg-warning" : "bg-green"
          } border-0`}
          onClick={() => {
            if (isScan) {
              stopScan();
            } else {
              handleScan();
              setIsScan(true);
            }
          }}
          style={{ width: 55, height: 55 }}
        >
          <i
            className={`ri-${
              isScan ? "close-line" : "qr-scan-2-line"
            } text-light align-bottom fs-2`}
          ></i>
        </Button>
      )}

      {/* Warning carrier message */}
      <Modal show={showAlert} onHide={handleCloseAlert} centered>
        <Modal.Header className="border-0" closeButton>
          <Modal.Title className="text-danger">
            <i className="ri-file-warning-line text-danger fs-1 me-2 animate__animated animate__fadeInDown"></i>
            Attention :
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="rounded text-ui">
          {" "}
          {""}
          <Container className="mb-2 text-center">
            {/* <img src={msgOperin} alt="instructions" width={200} /> */}
          </Container>
          <Alert variant="danger" className="border-2 border-danger">
            <p className="text-dark fw-bold m-0 font-75 ">
              Après le dépôt d'une commande, vous devez impérativement valider
              la zone de température sur la console du locker
            </p>
          </Alert>
          <div className="text-end">
            <Button
              variant=""
              className="bg-green text-light"
              onClick={handleCloseAlert}
            >
              Ok
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default InDelivery;
