import React from 'react'
import { Alert, Button, Dropdown, DropdownButton, Form, InputGroup, Spinner } from 'react-bootstrap';
import InfoAlert from '../ui/warning/InfoAlert';
// import userDataStore from '../../store/userDataStore';
// import newOrderDataStore from '../../store/newOrderDataStore';
// import bookingStore from '../../store/bookingStore';
// import AlertIsError from '../ui/warning/AlertIsError';

const ClientForm = ({clientFormProps}: any) => {
    // const dataStore = userDataStore((state: any) => state)
    // const newOrderRegister = newOrderDataStore((state: any) => state.newOrderRegister)
    // const orderStore = newOrderDataStore((state: any) => state)
    const {
      clientPhone,
      setClientPhone,
      choosedPhone,
      setChoosedPhone,
      choosedName,
      choosedLastName,
      setChoosedName,
      setChoosedLastName,
      clientName,
      clientLastName,
      clientEmail,
      setClientEmail,
      setClientName,
      setClientLastName,
      choosedEmail,
      setChoosedEmail,
      setIsValidPhone2,
      setIsValidPhone,
      newOrderModal,
      // bookingSlotIds,
      // tempZones,
      // slotSizes,
      // qtySlot,
      // ageRestriction,
      filteredName,
      filteredLastName,
      setFilteredName,
      setFilteredLastName,
      filteredEmail,
      setFilteredEmail,
      filteredPhone,
      setFilteredPhone,
      isMsgErrorName,
      isMsgErrorEmail,
      isValidPhone,
      isValidPhone2,
      isOrderCreate,
    } = clientFormProps;

    

    return (
    <form
      onSubmit={(e) => {
        if (
          (clientPhone?.length !== 8 && choosedPhone === "") ||
          (choosedPhone?.length !== 8 && clientPhone === "")
        ) {
          e.preventDefault();
          setIsValidPhone2(false);
        } else {
          newOrderModal(e);
          setIsValidPhone(true);
          setIsValidPhone2(true);
        }
      }}
    >
      <InputGroup className="mb-3 mt-2">
        <InputGroup.Text
          id="basic-addon1"
          className="border-end-0 bg-secondary-500"
        >
          <i className="ri-user-line text-secondary"></i>
        </InputGroup.Text>
        <Form.Control
          aria-label="Text input with dropdown button"
          value={choosedName ? choosedName : clientName}
          onChange={(e: any) => {
            choosedName
              ? setChoosedName(e.currentTarget.value)
              : setClientName(e.currentTarget.value);
        
          }}
          placeholder="Prénom du client*"
          required
          className="border-start-0"
        />
        {filteredName && filteredName?.length > 0 && (
          <DropdownButton
            variant="secondary"
            title=""
            className=""
            id="input-group-dropdown-3"
            align="end"
            show={true}
          >
            {filteredName?.map((user: any) => (
              <Dropdown.Item
                key={Math.random() * 4}
                onClick={() => {
                  setChoosedName(user.firstname);
                  setChoosedLastName(user.lastname);
                  setChoosedEmail(user.email);
                  setChoosedPhone(user.phone);
                  setFilteredName([]);
                  setFilteredEmail([]);
                  setFilteredPhone([]);
                }}
              >
                <i className="ri-user-line"></i> {user.firstname} - {user.email} -{" "}
                {user?.phone}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        )}

      </InputGroup>
      <InputGroup className="mb-3 mt-2">
        <InputGroup.Text
          id="basic-addon1"
          className="border-end-0 bg-secondary-500"
        >
          <i className="ri-user-fill text-secondary"></i>
        </InputGroup.Text>
        <Form.Control
          type="text"
          aria-label="Text input with dropdown button"
          value={choosedLastName ? choosedLastName : clientLastName}
          onChange={(e: any) => {
            choosedLastName
              ? setChoosedLastName(e.currentTarget.value)
              : setClientLastName(e.currentTarget.value);
          }}
          placeholder="Nom du client*"
          required
          className="border-start-0"
        />

        {filteredLastName && filteredLastName?.length > 0 && (
          <DropdownButton
            variant="secondary"
            title=""
            className=""
            id="input-group-dropdown-3"
            align="end"
            show={true}
          >
            {filteredLastName?.map((user: any) => (
              <Dropdown.Item
                key={Math.random() * 4}
                onClick={() => {
                  setChoosedName(user.firstname);
                  setChoosedLastName(user.lastname);
                  setChoosedEmail(user.email);
                  setChoosedPhone(user.phone);
                  setFilteredName([]);
                  setFilteredLastName([]);
                  setFilteredEmail([]);
                  setFilteredPhone([]);
                }}
                className=''
              >
                <i className="ri-user-line"></i> {user.firstname} - {user.email} -{" "}
                {user?.phone}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        )}
      </InputGroup>
      {isMsgErrorName && (
        <Alert variant="danger" className="mt-2 py-0">
          <InfoAlert
            icon="ri-error-warning-line"
            iconColor="danger"
            message={"Ce champ est obligatoire"}
            fontSize="font-75"
          />
        </Alert>
      )}
      <InputGroup className="mb-3">
        <InputGroup.Text
          id="basic-addon1"
          className="border-end-0 bg-secondary-500"
        >
          <i className="ri-at-line text-secondary"></i>
        </InputGroup.Text>
        <Form.Control
          aria-label="Text input with dropdown button"
          value={choosedEmail ? choosedEmail : clientEmail}
          onChange={(e: any) => {
            choosedEmail
              ? setChoosedEmail(e.currentTarget.value)
              : setClientEmail(e.currentTarget.value);
          }}
          placeholder="Email du client*"
          required
          className="border-start-0"
        />
        {filteredEmail && filteredEmail?.length > 0 && (
          <DropdownButton
            variant=""
            title=""
            className=""
            id="input-group-dropdown-1"
            align="end"
            show={true}
          >
            {filteredEmail?.map((user: any) => (
              <Dropdown.Item
                key={Math.random()}
                onClick={() => {
                  setChoosedName(user.first);
                  setChoosedLastName(user.lastname);
                  setChoosedEmail(user.email);
                  setChoosedPhone(user.phone);
                  setFilteredName([]);
                  setFilteredEmail([]);
                  setFilteredPhone([]);
                }}
              >
                <i className="ri-user-line"></i> {user.name} - {user.email} -{" "}
                {user.email}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        )}
      </InputGroup>
      {isMsgErrorEmail &&
        clientName &&
        clientName?.length < 1 &&
        choosedEmail &&
        choosedEmail?.length < 1 && (
          <Alert variant="danger" className="mt-2 py-0 ">
            <InfoAlert
              icon="ri-error-warning-line"
              iconColor="danger"
              message={"Ce champ est obligatoire"}
              fontSize="font-75"
            />
          </Alert>
        )}
      <InputGroup className="mb-3">
        <InputGroup.Text
          id="basic-addon1"
          className="border-end-0 bg-secondary-500"
        >
          <i className="ri-phone-line text-secondary"></i>
        </InputGroup.Text>
        <Form.Control
          aria-label="Text input with dropdown button"
          type="number"
          value={choosedPhone ? choosedPhone : clientPhone}
          onChange={(e: any) => {
            choosedPhone
              ? setChoosedPhone(e.currentTarget.value)
              : setClientPhone(e.currentTarget.value);
          }}
          placeholder="Téléphone du client*"
          required
          className="border-start-0"
        />
        {filteredPhone && filteredPhone?.length > 0 && (
          <DropdownButton
            variant=""
            title=""
            className=""
            id="input-group-dropdown-2"
            align="end"
            show={true}
          >
            {filteredPhone?.map((user: any) => (
              <Dropdown.Item
                key={Math.random()}
                onClick={() => {
                  setChoosedName(user.firstname);
                  setChoosedLastName(user.lastname);
                  setChoosedEmail(user.email);
                  setChoosedPhone(user.phone);
                  setFilteredName([]);
                  setFilteredEmail([]);
                  setFilteredPhone([]);
                }}
              >
                <i className="ri-user-line"></i> {user.firstname} - {user.email} -{" "}
                {user.phone}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        )}
      </InputGroup>
      {!isValidPhone && clientPhone?.length > 2 && (
        <Alert variant="danger" className="mt-2 py-0 ">
          <InfoAlert
            icon="ri-error-warning-line"
            iconColor="danger"
            message={'Ce champ doit commencer par "87" ou "88" ou "89" '}
            fontSize="font-75"
          />
        </Alert>
      )}
      {!isValidPhone2 && (
        <Alert variant="danger" className="mt-2 py-0 ">
          <InfoAlert
            icon="ri-error-warning-line"
            iconColor="danger"
            message={'Ce champ doit être composé de 8 chiffres"'}
            fontSize="font-75"
          />
        </Alert>
      )}
      {clientName &&
        clientName?.length < 1 &&
        choosedPhone &&
        choosedPhone?.length < 1 && (
          <Alert variant="danger" className="mt-2 py-0 ">
            <InfoAlert
              icon="ri-error-warning-line"
              iconColor="danger"
              message={"Ce champ est obligatoire"}
              fontSize="font-75"
            />
          </Alert>
        )}
   
      <div className="w-100 text-end">
        <Button
          aria-label="Aria commande"
          title="Valider commande"
          type="submit"
          className={`bg-green rounded-pill border-green text-light 
        `}
        >
          {isOrderCreate && <Spinner size="sm" className="me-1" />}
          Valider
        </Button>
      </div>
    </form>
  );
}

export default ClientForm
