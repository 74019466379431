import { Container } from "react-bootstrap";
import ItemList from "./ItemList";
import images from "../../styles/no-order.webp";
import React from "react";
import NoData from "./warning/NoData";
import HistoryLoader from "./loading/HistoryLoader";


const OrderList = ({ orderListProps }:  any) => {
  //////////////////////////
  // Props
  /////////////////////////
  const {
    filteredOrder,
    setSelectedOrder,
    searchOrder,
    setSearchOrder,
    orderByStatus,
    trigger,
    historyOrder,
    items,
    loader,
    isNext,
    setIsSelectedOrder,
    handleShowFullLocker,
    lockerFull,
  } = orderListProps;

  const [orderList, setOrderList] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [multiOrderCodes, setMultiOrderCodes] = React.useState<any>([]);

  //filtered Data
  const isFilteredOrders =
    filteredOrder?.length === 0 &&
    orderList?.length === 0 &&
    searchOrder?.length > 2;

    
    ////useEffect
    React.useEffect(() => {
    setIsLoading(true);
    // let newMultiOrderCodes = { ...multiOrderCodes };
    // let newOrderList: any = [];

    if (filteredOrder?.length > 0 && searchOrder?.length > 1) {
      setOrderList(filteredOrder);
      setIsLoading(false);
    } else if (filteredOrder?.length === 0 && searchOrder?.length > 2) {
      setOrderList([]);
      setIsLoading(false);
    } else if (trigger === "history") {
      setOrderList(historyOrder);
      // setOrderList(items);
      setIsLoading(false);
    } else {
      setOrderList(orderByStatus);
      setIsLoading(false);
    }

    setIsLoading(false);
  }, [orderByStatus, filteredOrder, searchOrder, historyOrder, items, trigger]);
 



  return (
    <Container className="order-list px-2 ">
      {/* {isLoading && (
        <Container className="text-center mt-2">
          <PlaceHolder itemCount={10} />
        </Container>
      )} */}
      {!isLoading &&
        (orderList?.length > 0
          ? orderList?.map((liv: any, indx: number) => 
            { 
             
              
              return (
              <ItemList
                key={indx}
                liv={liv}
                indx={indx}
                setSelectedOrder={setSelectedOrder}
                setSearchOrder={setSearchOrder}
                trigger={trigger}
                setIsSelectedOrder={setIsSelectedOrder}
                handleShowFullLocker={handleShowFullLocker}
                lockerFull={lockerFull}
                multiOrderCodes={multiOrderCodes}
                setMultiOrderCodes={setMultiOrderCodes}
              />
            )})
          : trigger !== "history" && (
              <NoData
                images={images}
                isFilteredOrders={isFilteredOrders}
                msg="Aucune commande"
                msg2="Aucune commande trouvée"
              />
            ))}
      {trigger === "history" && isNext && (
        <Container ref={loader} className="text-center mt-2 px-0">
          <HistoryLoader itemCount={10} />
        </Container>
      )}
    </Container>
  );
};

export default OrderList;
