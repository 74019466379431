import React from "react";
import { Container, Row, Col, Button, Offcanvas } from "react-bootstrap";
import userDataStore, { UserState } from "../../store/userDataStore";
import AuthService from "../../service/Auth/AuthService";
import { Divider } from "antd";
import { Link, useOutletContext } from "react-router-dom";
import newOrderDataStore from "../../store/newOrderDataStore";
import bookingStore from "../../store/bookingStore";
import logsStore from "../../store/logsStore";
import UserQrcode from "../ui/modals/UserQrcode";
import { _hasUserRole } from "../../utils/functions";

interface headerProps {
  title: string;
}

const HeaderDesign: React.FC<headerProps> = ({ title }) => {
  const { isSelectedOrder } = useOutletContext<any>();

  //////////////////////////
  // Store states
  /////////////////////////
  const authLogout = userDataStore((state: any) => state.authLogout);
  const newOrderDelete = newOrderDataStore(
    (state: any) => state.newOrderDelete
  );
  const bookingRemove = bookingStore((state: any) => state.bookingRemove);
  const clearLogCatcher = logsStore((state: any) => state.logCatcher);
  const userRoles = userDataStore((state: UserState) => state.roles);


 

  const hasSuperAdmin = _hasUserRole(userRoles, "ROLE_SUPER_ADMIN");
  const hasAdmin = _hasUserRole(userRoles, "ROLE_ADMIN");
  // ⚠  change ROLE_USER to ROLE_TECH on prod
  // const hasTech = _hasUserRole(userRoles, "ROLE_SHIPPER");
  const hasTech = _hasUserRole(userRoles, "ROLE_TECH");

  const [isIphone, setIsIphone] = React.useState<boolean>(false);

  React.useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    const isIphone = /iphone|ipad|ipod/.test(userAgent);
    
    setIsIphone(isIphone);
  }, []);
  
  const handleReload = () => {
    window.location.reload();
  };



  //////////////////////////
  //Auth deliverer modal
  //////////////////////////
  const [show, setShow] = React.useState<boolean>(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //////////////////////////
  //menu Right (offcanvas)
  //////////////////////////
  const [showOffcanvas, setShowOffcanvas] = React.useState<boolean>(false);
  const handleCloseOffcanvas = () => setShowOffcanvas(false);
  const handleShowOffcanvas = () => setShowOffcanvas(true);

  return (
    <>
      <UserQrcode show={show} handleClose={handleClose} />
      <Container fluid className="top-nav-design text-green">
        <Row className="pe-0 full-row">
          <Col xs={2} className="py-2 align-bottom m-auto text-center">
            {!isSelectedOrder && (
              <Button
                aria-label="Aria Auth"
                className=" bg-transparent border-0 ps-1"
                title="Auth"
                onClick={handleShow}
              >
                <i
                  className={`ri-user-line text-green fs-5 `}
                  onClick={handleShow}
                ></i>
              </Button>
            )}
          </Col>
          <Col
            xs={8}
            sm={8}
            className="header-title text-green m-auto text-center"
          >
            <b>{title}</b>
          </Col>
          <Col xs={2} className="py-2 m-auto  align-bottom text-center">
            <Button
              aria-label="Aria Menu"
              className="pe-1 bg-transparent border-0"
              title="Menu"
              onClick={handleShowOffcanvas}
            >
              <i className="ri-more-2-fill text-green fs-5"></i>
            </Button>
          </Col>
        </Row>
      </Container>
      <Offcanvas
        show={showOffcanvas}
        onHide={handleCloseOffcanvas}
        placement="end"
        className="menu-right bg-gray border-0 font-85"
      >
        <Offcanvas.Header className="pb-0 " closeButton></Offcanvas.Header>
        <Offcanvas.Body className=" px-1 py-2">
          <Container className="">
            <Link
              className="text-decoration-none "
              to="/historique"
              onClick={handleCloseOffcanvas}
            >
              <Row className=" menu-link">
                <Col xs={2}>
                  {" "}
                  <i className="ri-history-line fs-5 text-green"></i>
                </Col>{" "}
                <Col className="m-auto user-name">Historique</Col>
              </Row>
            </Link>
          </Container>
          <Container className="">
            <Link
              className="text-decoration-none "
              to="/dashboard"
              onClick={handleCloseOffcanvas}
            >
              <Row className=" menu-link">
                <Col xs={2}>
                  {" "}
                  <i className="ri-line-chart-line fs-5 text-green"></i>
                </Col>{" "}
                <Col className="m-auto user-name">Tableau de bord</Col>
              </Row>
            </Link>
          </Container>
          <Container className="">
            <Link
              className="text-decoration-none "
              to="/retirées"
              onClick={handleCloseOffcanvas}
            >
              <Row className=" menu-link">
                <Col xs={2}>
                  {" "}
                  <i className="ri-prohibited-line fs-5 text-green"></i>
                </Col>{" "}
                <Col className="m-auto user-name">Commandes retirées</Col>
              </Row>
            </Link>
          </Container>
          {(hasSuperAdmin || hasAdmin || hasTech) && (
            <Container className="">
              <Link
                className="text-decoration-none "
                to="/deposees"
                onClick={handleCloseOffcanvas}
              >
                <Row className=" menu-link">
                  <Col xs={2}>
                    {" "}
                    <i className="ri-inbox-archive-line fs-5 text-green"></i>
                  </Col>{" "}
                  <Col className="m-auto user-name">Commandes déposées</Col>
                </Row>
              </Link>
            </Container>
          )}
          {(hasSuperAdmin || hasAdmin ) && (
            <Container className="">
              <Link
                className="text-decoration-none text-light"
                to="/nouvelle-commande"
                onClick={handleCloseOffcanvas}
              >
                <Row className="menu-link">
                  <Col xs={2}>
                    {" "}
                    <i className="ri-file-add-line fs-5 text-green"></i>
                  </Col>{" "}
                  <Col className="m-auto user-name">Nouvelle commande</Col>
                </Row>
              </Link>
            </Container>
          )}

          <Container className="">
            <Link
              className="text-decoration-none text-light"
              to="/update-password"
              onClick={handleCloseOffcanvas}
            >
              <Row className="menu-link">
                <Col xs={2}>
                  {" "}
                  <i className="ri-lock-unlock-line fs-5 text-green"></i>
                </Col>{" "}
                <Col className="m-auto user-name">
                  Modifier votre mot de passe
                </Col>
              </Row>
            </Link>
          </Container>
         
         {
         isIphone &&

          <Container className="" onClick={handleReload}>
           
              <Row className="menu-link">
                <Col xs={2}>
                  {" "}
                  <i className="ri-loop-right-line fs-5 text-green"></i>
                </Col>{" "}
                <Col className="m-auto user-name">
                  Actualiser
                </Col>
              </Row>
          </Container>
         }

          <Divider className="log-out pb-5 me-3"></Divider>
            
          <Container className="log-out">
          <Link
              className="text-decoration-none text-light"
              to="/connexion"
              onClick={handleCloseOffcanvas}
            >

            <Row
              className="menu-link"
              onClick={() => {
                authLogout();
                newOrderDelete();
                bookingRemove();
                AuthService.logout();
                clearLogCatcher();
              }}
              >
              <Col xs={2}>
                {" "}
                <i className="ri-logout-box-r-line fs-5 text-green"></i>
              </Col>{" "}
              <Col className="m-auto user-name">Déconnexion</Col>
            </Row>
              </Link>
          </Container>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default HeaderDesign;
