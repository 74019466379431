import React from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  Spinner,
} from "react-bootstrap";
import BadgedIcon from "./BadgedIcon";
import userDataStore from "../../store/userDataStore";
import { useOutletContext } from "react-router-dom";
import { _getOrdersByStatus } from "../../utils/functions";
import ProductTable from "./ProductTable";
import { SelectedOrderInfoBar } from "./SelectedOrderInfoBar";
import QrCode from "../QrCode";


const RetreiveDetail = ({ scanPageProps }: any) => {

  ////////////////////
  //Props & store
  ///////////////////

  const { setIsSelectedOrder } = useOutletContext<any>();

  const {
    selectedOrder,
    setSelectedOrder,
    newStatus,
    setOrderExpired,
    setSearchOrder,
    trigger,
  } = scanPageProps;

  const dataStore: any = userDataStore((states: any) => states);

  const [isErrorValid, setIsErrorValid] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  // const [errorMsg, setErrorMsg] = React.useState<string>("");

  const [isScroll, setIsScroll] = React.useState(false);

  const [show, setShow] = React.useState<boolean>(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  //Modal de confirmation de retrait
  // const [showRetreiveModal, setShowRetreiveModal] = React.useState<boolean>(false);
  // const handleCloseRetreiveModal = () => setShowRetreiveModal(false);
  // const handleShowRetreiveModal = () => setShowRetreiveModal(true);

   //Modal de confirmation de retrait
  //  const [showCustomerService, setShowCustomerService] = React.useState<boolean>(false);
  //  const handleCloseCustomerService = () => setShowCustomerService(false);
  //  const handleShowCustomerService = () => setShowCustomerService(true);


  //  const [showUpdateStatus, setShowUpdateStatus] = React.useState<boolean>(false)
  // const handleCloseUpdateStatus = () => setShowUpdateStatus(false)
  // const handleShowUpdateStatus = () => {
  //   setShowUpdateStatus(true)
    
  //   setTimeout(() => {
  //     setSelectedOrder(null)
  //     handleCloseUpdateStatus()
  //     setIsSelectedOrder(false);
  //   }, 2000)
  // }

  React.useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > 50) {
        setIsScroll(true);
      } else {
        setIsScroll(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);
 

  const productTableProps = {
    selectedOrder,
    handleShow,
    newStatus,
    trigger,
    // handleShowCustomerService,
  };
  const selectedOrderInfoBarProps = {
    isScroll,
    setSelectedOrder,
    setSearchOrder,
    setIsSelectedOrder,
    selectedOrder,
  };

console.log(selectedOrder)
  return (
    <Container fluid className="order-list pb-5">
      <div className="text-center">
        <p className="col-12 mb-0 text-center font-75 text-ui">
          Détail de la commande à livrer
        </p>
        <SelectedOrderInfoBar selectedOrderInfoBarProps={selectedOrderInfoBarProps} />

        <ProductTable productTableProps={productTableProps} />
      </div>

  
      {/* Error modal*/}
      <Modal show={show} onHide={handleClose} className="px-0">
        {isErrorValid ? (
          <>
            <Modal.Header closeButton>
              <Modal.Title>
                <i className="ri-error-warning-line fs-2 text-warning"></i>
                Attention
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Une anomalie est survenue... Rafraichissez la page
            </Modal.Body>
            <Modal.Footer>
              <Button
                size="lg"
                className=" rounded-pill border-warning text-light ms-3 px-4"
                variant="warning"
                onClick={() => {
                  setSelectedOrder("");
                  handleClose();
                  setIsErrorValid(false);
                }}
              >
                Réessayez
              </Button>
            </Modal.Footer>
          </>
        ) : (
          <>
             <Modal.Body className="pb-0">
              <Container className="text-center m-auto w-75">
                <Row>
                  <Col xs={3} className="m-auto ">
                    {" "}
                    <BadgedIcon
                      slot={selectedOrder?.bookingSlot}
                      borderColor="gray"
                      imgSize="25px"
                      componentSize="35px"
                      design="indicator-small"
                    />
                  </Col>
                  <Col className="m-auto text-start">
                    <b>
                      Zone{" "}
                      {selectedOrder?.bookingSlot?.slot?.temperatureZone?.name}
                    </b>
                  </Col>
                </Row>
              </Container>
              <Container className="bg-light p-2 border">
                <div className="m-auto">
                  <QrCode data={`${selectedOrder?.barcode}`} />
                </div>
              </Container>
              <Container className="text-center">
                {selectedOrder?.barcode}
              </Container>
              {/* <Container className="text-start">
            Avez-vous retiré cette commande du locker ?
          </Container> */}
            </Modal.Body> 
          {/* <Modal.Body className="pb-0">
          <Container className="text-center m-auto w-100 ">
            <Row>
              <Col xs={2} md={4} className="m-auto ps-0 ps-md-5">
                {" "}
                <BadgedIcon
                  slot={selectedOrder?.bookingSlot}
                  borderColor="gray"
                  imgSize="25px"
                  componentSize="35px"
                  design="indicator-small"
                />
              </Col>
              <Col className="m-auto text-start">
                <b>{selectedOrder?.barcode}</b>
              </Col>
            </Row>
          </Container>

          <Container className="text-start">
            Avez-vous retiré cette commande du locker ?
          </Container>
        </Modal.Body> */}
            <Modal.Footer className="center  border-0">
              <Row>
                <Col xs={6}>
                  <Button
                    size="lg"
                    className=" rounded-pill border-yellow bg-yellow text-success px-4"
                    variant=""
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Annuler
                  </Button>
                </Col>
                <Col xs={6}>
                  <Button
                    size="lg"
                    type="submit"
                    className="bg-green rounded-pill border-green text-light px-4 "
                    onClick={() => {
                      _getOrdersByStatus(
                        dataStore.token,
                        "overtime",
                        setOrderExpired,
                        setIsLoading
                      );
                      setSelectedOrder(null);
                      handleClose();
                    }}
                  >
                    {isLoading ? <Spinner size="sm" as="span" /> : "Retirer"}
                  </Button>
                </Col>
              </Row>
            </Modal.Footer>
          </>
        )}
      </Modal>
    </Container>
  );
};

export default RetreiveDetail;
