import React from 'react'
import { Card, Row, Col } from 'react-bootstrap'
import { _iconFilter, _iconFilter3, _slotLocationTab } from '../../utils/functions'


const LokcerCard = ({lockerCardProps}: any) => {



const { 
  transformedData,
  filteredLocker,
  setTrigger,
  handleAddStartProduct,
  setAgeRestriction,
} = lockerCardProps


 

  /////////////////
  //Events
  ////////////////////



    
    
  //   const condition3 = uniqueSizeTab(locker)?.length === 3;
  //   const condition2 = uniqueSizeTab(locker)?.length === 2;
    
  //   const xsValue = condition3 ? 5 : condition2 ? 6 : 7;
  // const mdValue = condition3 ? 4 : condition2 ? 8 : 8;
  // const colValue = condition3 ? 7 : condition2 ? 6 : 5;
  // const colMdValue = condition3 ? 8 : condition2 ? 4 : 4;
  


  

  return (
    <div className="mt-4">
    {transformedData?.map((company: any, index: number) => (
      <React.Fragment key={index}>
        <div>{company.companyName}</div>
        {company?.locker?.map((lockers: any, indx: any) => (
          <React.Fragment key={indx}>
            <Card
              className="cursor border-2 border-gray p-3 mb-3 text-ui-second"
              onClick={() => {
                filteredLocker(
                  lockers?.location,
                  company.companyName
                );
                setTrigger(true);
                handleAddStartProduct();
                setAgeRestriction(false);
              }}
            >
              <Row className="px-1 px-sm-3">
                <Col className="m-auto ms-md- font-75 ps-2 px-0 mx-0 fs-5 text-md-center">
                  {lockers?.shortLocation}
                </Col>
                <Col>
                  {lockers?.temperatureZone?.map(
                    (slots: any, slotIndex: any) => (
                      <div
                        key={slotIndex}
                        className="pb-1"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <img
                          alt="Temp icon"
                          src={_iconFilter(slots?.keyTemp)}
                          style={{ width: "30px" }}
                        />
                        <span className="font-65 pt-2 ms-1 ">
                          <i className="ri-arrow-right-line"></i>
                        </span>
                        <div
                          key={Math.random()}
                          className="badge-hoster px-0 ms-1 pt-1"
                        >
                          <span className="font-75 fw-bold ms-2 mb-0 bg-warning rounded-pill  px-2">
                            {slots?.size}
                          </span>
                          <div className="my-badge px-0 font-65 fw-bold pb-0 text-light">
                            <span className="rounded-pill bg-green px-1 py-">
                              {slots?.available}
                            </span>
                          </div>
                        </div>
                        {/* ))} */}
                      </div>
                    )
                  )}
                </Col>
              </Row>
            </Card>
          </React.Fragment>
        ))}
      </React.Fragment>
    ))}
  </div>
  );
}

export default LokcerCard
