function calculateEaster(year: number): Date {
    const a = year % 19;
    const b = Math.floor(year / 100);
    const c = year % 100;
    const d = Math.floor(b / 4);
    const e = b % 4;
    const f = Math.floor((b + 8) / 25);
    const g = Math.floor((b - f + 1) / 3);
    const h = (19 * a + b - d - g + 15) % 30;
    const i = Math.floor(c / 4);
    const k = c % 4;
    const l = (32 + 2 * e + 2 * i - h - k) % 7;
    const m = Math.floor((a + 11 * h + 22 * l) / 451);
    const month = Math.floor((h + l - 7 * m + 114) / 31);
    const day = ((h + l - 7 * m + 114) % 31) + 1;

    return new Date(year, month - 1, day); // month is 0-indexed
}

const holidays = (year: number) => {
    return [
        new Date(year, 0, 1).toISOString().split('T')[0],  // Nouvel An
        new Date(year, 2, 5).toISOString().split('T')[0],  // Arrivée de l'évangile
        new Date(year, 4, 1).toISOString().split('T')[0],  // Fête du Travail
        new Date(year, 4, 8).toISOString().split('T')[0],  // Victoire 1945
        new Date(year, 5, 29).toISOString().split('T')[0], // Fête Autonomie
        new Date(year, 6, 14).toISOString().split('T')[0], // Fête Nationale
        new Date(year, 7, 15).toISOString().split('T')[0], // Assomption
        new Date(year, 10, 1).toISOString().split('T')[0], // Toussaint
        new Date(year, 10, 11).toISOString().split('T')[0],// Armistice 1918
        new Date(year, 11, 25).toISOString().split('T')[0], // Noël
    ];
};

const easterMonday = (year: number) => {
    const easter = calculateEaster(year);
    easter.setDate(easter.getDate() + 1);
    return easter.toISOString().split('T')[0];
};

const goodFriday = (year: number) => {
    const easter = calculateEaster(year);
    easter.setDate(easter.getDate() - 2);
    return easter.toISOString().split('T')[0];
};

const ascensionDay = (year: number) => {
    const easter = calculateEaster(year);
    easter.setDate(easter.getDate() + 39);
    return easter.toISOString().split('T')[0];
};

const pentecostMonday = (year: number) => {
    const easter = calculateEaster(year);
    easter.setDate(easter.getDate() + 50);
    return easter.toISOString().split('T')[0];
};

export const addHolidays = (year: number) => {
    const fixedHolidays = holidays(year);
    fixedHolidays.push(easterMonday(year));
    fixedHolidays.push(goodFriday(year));
    fixedHolidays.push(ascensionDay(year));
    fixedHolidays.push(pentecostMonday(year));
    return fixedHolidays;
};

//Formatte la date courante en yyyy-mm-dd
export const _todayDate = () => {
    const today = new Date()
    // Obtenir l'année
    const year = today.getFullYear()
    // Obtenir le mois (ajouter 1 car les mois commencent à 0)
    const month = String(today.getMonth() + 1).padStart(2, '0')
    // Obtenir le jour du mois
    const day = String(today.getDate()).padStart(2, '0')
    // Combiner le tout dans le format souhaité
    const formattedDate = `${year}-${month}-${day}`
    return formattedDate
  }