import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import "./App.css";
import userDataStore, { UserState } from "./store/userDataStore";
import "animate.css";
import BottomNavBar from "./components/layout/BottomNavBar";
import OrdersService from "./service/Orders/OrdersService";
import Loading from "./components/ui/Loading";
import { Alert, Container, Toast, ToastContainer } from "react-bootstrap";
import BookingSlotservice from "./service/BookingSlot/BookingSlotservice";


function App() {
  /////////////////////
  //States
  ////////////////////
  const authLogout = userDataStore((state: UserState) => state.authLogout);
  const token = userDataStore((state: UserState) => state.token);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [expireToken, setExpireToken] = React.useState<boolean>(false);
  const [isSelectedOrder, setIsSelectedOrder] = React.useState<boolean>(false);
  
  const [idStore, setIdStore] = React.useState<any>(0);
  const [selectedStore, setSelectedStore] = React.useState<string>("");
  const [selectedOrderCity, setSelectedOrderCity] = React.useState<string>("");
  const [selectedItem, setSelectedItem] = React.useState<string>("preparations");
  const [allSlot, setAllSlot] = React.useState<any>([]);
  const [orderReady, setOrderReady] = React.useState<any>([]);
  const [orderPickedUp, setOrderPickedUp] = React.useState<any>([]);
  const [orderExpired, setOrderExpired] = React.useState<any>([]);
  const [orderCreated, setOrderCreated] = React.useState<any>([]);
  const [orderOperin, setOrderOperin] = React.useState<any>([]);
  const [orderOperout, setOrderOperout] = React.useState<any>([]);

  const [historyOrder, setHistoryOrder] = React.useState<any>([]);


  const [isOnline, setIsOnline] = React.useState<boolean>(
    window.navigator.onLine
  );

  const [showA, setShowA] = React.useState<boolean>(false)
  const toggleShowA = () => setShowA(!showA)


  /////////////////////
  //UseEffect
  ////////////////////
  React.useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
    };
  
    const handleOffline = () => {
      setIsOnline(false);
      alert("Connexion perdue, reconnectez-vous");
      authLogout();
    };
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, [authLogout, isOnline]);


  React.useEffect(() => {
    if (token && token?.length > 0) {
      getBookingAllSlot(token);
      getOrdersByStatus(token, "ready_for_delivery", setOrderReady);
      getOrdersByStatus(token, "picked_up", setOrderPickedUp);
      getOrdersByStatus(token, "overtime", setOrderExpired);

    }
  }, [token]);
  
  React.useEffect(() => {
    setSelectedOrderCity(
      allSlot?.["hydra:member"]
        ? allSlot?.["hydra:member"][0]?.slot?.temperatureZone?.locker
            ?.shortLocation
        : ""
    );
    setSelectedStore(
      allSlot?.["hydra:member"]
        ? allSlot?.["hydra:member"][0]?.slot?.temperatureZone?.locker &&
            allSlot?.["hydra:member"][0]?.slot?.temperatureZone?.locker["@id"]
        : ""
    );
    setIdStore(
      allSlot?.["hydra:member"]
        ? allSlot?.["hydra:member"][0]?.slot?.temperatureZone?.locker &&
            allSlot?.["hydra:member"][0]?.slot?.temperatureZone?.locker.id
        : null
    );
  }, [allSlot]);

  /////////////////////
  //Events
  ////////////////////
  const expiredToken = (error: any) => {
    if (!expireToken) {
      if (error?.response?.data?.message === "Expired JWT Token") {
        setExpireToken(true);
        alert("Session expirée, reconnectez-vous.");
        console.log("allOrder_app");
        authLogout();
        return;
      }
      if (error?.response?.data?.message === "Invalid JWT Token") {
        setExpireToken(true);
        alert("Token invalide, reconnectez-vous.");
        authLogout();
        return;
      }
    }
  };


  const getOrdersByStatus = async (
    token: string,
    status: string,
    setData: React.Dispatch<React.SetStateAction<any>>
  ) => {
    try {
      const response = await OrdersService.ordersByStatus(token, status);
      setIsLoading(false);
      setData(response.data);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };




  const getBookingAllSlot = async (token: string) => {
    try {
      const response = await BookingSlotservice.allSlot(token);
      setIsLoading(false);
      setAllSlot(response.data);
    } catch (error) {
      console.error(error);
      expiredToken(error);
      setIsLoading(false);
    }
  };

  const bottomProps = {
    orderReady,
    orderPickedUp,
    orderExpired,
    setIsSelectedOrder,
  };

 
 
  return (
    <div data-bs-theme="light" className="bg-ui">
      {!token && <Navigate to="/connexion" />}
      {isLoading ? (
        <>
          <Container className="text-center pt-5 vh-100">
            <Loading vairant="warning" className="" />
          </Container>
        </>
      ) : (
        <div className="w-100">
          <Outlet
            context={{
              idStore, setIdStore,
              setSelectedStore,
              setSelectedOrderCity,
              allSlot,
              setSelectedItem,
              selectedStore,
              selectedOrderCity,
              setAllSlot,
              setHistoryOrder,
              historyOrder,
              orderReady,
              setOrderReady,
              orderPickedUp,
              setOrderPickedUp,
              orderExpired,
              setOrderExpired,
              orderCreated,
              setOrderCreated,
              selectedItem,
              expireToken,
              setExpireToken,
              isSelectedOrder,
              setIsSelectedOrder,
              orderOperin,
              setOrderOperin,
              orderOperout,
              setOrderOperout,
              toggleShowA,
            }}
          />
        </div>
      )}

      <BottomNavBar
        selectedStore={selectedStore}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        bottomProps={bottomProps}
      />
       <ToastContainer position='top-end' className='mt-2 me-2' style={{ zIndex: 1500000 }}>
        <Toast show={showA} onClose={toggleShowA} delay={5000} autohide>
          <Alert  
            variant='danger'
            className='p-3 mb-0 d-flex justify-content-start align-items-center'
          >
            <i className='ri-close-circle-line text-danger fs-3 me-2'></i>{' '}
            <strong className=''>Cette commande contient de l'alcool et ne peut être traitée en dehors des heures légales !</strong>
          </Alert>
        </Toast>
      </ToastContainer>
      
    </div>
  );
}

export default App;
