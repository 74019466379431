

import {create} from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

export type UserState = {
  isLogged: boolean;
  id: string | null;
  firstname: string | null;
  company_id: number | null;
  cleveronCompany_id: string | null;
  company_name: string | object | null;
  token: any | null;
  subToken: any | null;
  apm_access_code: any | null;
  roles: any | null;
  username: string | null;
  authLogin: any;
  authLogout: () => void;
};

const userDataStore = create(
  persist<UserState>(
    (set) => ({
      // initial state
      isLogged: false,
      id: null,
      firstname: null,
      company_id: null,
      cleveronCompany_id: null,
      company_name: null,
      token: null,
      subToken: null,
      apm_access_code: null,
      roles: null,
      username: null,

      // methods for manipulating state
      authLogin: (
        isLogged: boolean,
        id: string | null,
        firstname: string | null,
        company_id: number | null,
        cleveronCompany_id: string | null,
        company_name: string | object | null,
        token: string | null,
        subToken: string | null,
        apm_access_code: number | null,
        roles: any | null,
        username: string | null
      ) =>
        set((state: any) => ({
          isLogged: isLogged,
          id: id,
          firstname: firstname,
          company_id: company_id,
          cleveronCompany_id: cleveronCompany_id,
          company_name: company_name,
          token: token,
          subToken: subToken,
          apm_access_code: apm_access_code,
          roles: roles,
          username: username,
        })),
      authLogout: () =>
        set((state: any) => ({
          isLogged: false,
          id: null,
          firstname: null,
          company_id: null,
          cleveronCompany_id: null,
          company_name: null,
          token: null,
          subToken: null,
          apm_access_code: null,
          roles: null,
          username: null,
        })),
    }),
    {
      name: "userLog", // unique name
      storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage sessionStorage' is used
    }
  )
);

export default userDataStore;