import React from 'react'
import {  Card, Container, InputGroup, Form, Spinner } from 'react-bootstrap'

import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import userDataStore from '../../store/userDataStore'
import axios from 'axios'


const UpdatePassword = () => {

  const API_URL = process.env.REACT_APP_END_POINT

  //////////////////////////
  // booleans States
  /////////////////////////

  const [isLoadingPass, setIsLoadingPass] = React.useState<boolean>(false)
  const [isError, setIsError] = React.useState<boolean>(false)
  const [isView, setIsView] = React.useState<boolean>(false)
  const [isView1, setIsView1] = React.useState<boolean>(false)
  const [isView2, setIsView2] = React.useState<boolean>(false)


  //////////////////////////
  // states
  /////////////////////////

 
  const [isValidPassword, setIsValidPassword] = React.useState<boolean>(true);



  const [pass, setPass] = React.useState<string>('')
  const [pass1, setPass1] = React.useState<string>('')
  const [pass2, setPass2] = React.useState<string>('')

  const dataStore = userDataStore((state: any) => state)

  const navigate = useNavigate()
  
  

  React.useEffect(() => {
    
    const validatePassword = () => {
      const regex: any  = /^(?=.{12,})(?=.*?[A-Z])(?=.*?[^a-zA-Z0-9])/
      const match = regex?.test(pass1);

      setIsValidPassword(match ? true : false);
    };

    if(pass1?.length > 8){

      validatePassword();
    }
  

   
  }, [pass1]);

 
  const changePassword = (e: any) => {
    setIsLoadingPass(true)
    setIsError(false)
    e.preventDefault()
    if (pass1 === pass2 && isValidPassword) {
      let data = {
        currentPassword: pass,
        newPassword: pass1,
        newPasswordRetyped: pass2,
      }

      let config = {
        method: 'patch',
        maxBodyLength: Infinity,
        url: API_URL + 'users/' + dataStore.id + '/update-password',
        headers: {
          'Content-Type': 'application/merge-patch+json',
          'Authorization': 'Bearer ' + dataStore.token,
        },
        data: data,
      }

      axios
        .request(config)
        .then((response) => {
          Swal.fire({
            position: 'top-end',
            toast: true,
            icon: 'success',
            title: 'Modification du mot de passe effectué',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            didOpen: () => {
              setTimeout(() => {
                navigate('/preparations')
              }, 2200)
            },
          })
          setIsLoadingPass(false)
          setIsError(false)
        })
        .catch((error) => {
            console.log(error)
            setIsLoadingPass(false)
            setIsError(true)
            // setCodeError(error.response.status)
            // setMsgError(error.response.data["hydra:description"])
            
        })
    }else {
      setIsLoadingPass(false)
      alert('Le mot de passe doit contenir au moins 12 caractères, une lettre majuscule et un caractère spécial.');
    }
  }

  return (
    <Container fluid className="px-0 pb-5">
      <Card className="update-form bg-darkGray shadow animate__animated animate__fadeIn rounded-0 border-0 vh-100">
        <Card.Body className="body-form">
          <div className=" text-center my- text-light">
            <h5>Modifiez votre mot de passe</h5>
          </div>
          <Form onSubmit={changePassword}>
            <Form.Group className="mb-4 mt-3" controlId="formBasicEmail">
              <Form.Label className="d-none">Mot de passe actuel</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text
                  id="basic-addon1"
                  className="rounded-0 border-0"
                >
                  <i className="ri-lock-fill text-muted"></i>
                </InputGroup.Text>
                <Form.Control
                  className="shadow rounded-0 border-0"
                  type={isView ? "text" : "password"}
                  placeholder="Mot de passe actuel"
                  name="pass"
                  value={pass}
                  onChange={(e) => setPass(e.currentTarget.value)}
                  required
                />
                <InputGroup.Text
                  id="eyeOrNot"
                  className="rounded-0 border-0"
                  onClick={() => setIsView(!isView)}
                >
                  {" "}
                  <i
                    className={
                      !isView
                        ? "ri-eye-off-fill text-secondary"
                        : "ri-eye-fill text-secondary"
                    }
                  ></i>
                </InputGroup.Text>
              </InputGroup>
              {isError && (
              
                <p className="ps-2 font-75 text-yellow">
                  Votre mot de passe est incorrect, <b>Vous l'avez oublié ?</b>
                </p>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPass1">
              <Form.Label className="d-none">Nouveau mot de passe</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text
                  id="basic-addon1"
                  className="rounded-0 border-0"
                >
                  <i className="ri-lock-unlock-fill text-muted"></i>
                </InputGroup.Text>
                <Form.Control
                  className="shadow rounded-0 border-0"
                  type={isView1 ? "text" : "password"}
                  placeholder="Nouveau mot de passe"
                  name="pass1"
                  value={pass1}
                  onChange={(e) => setPass1(e.currentTarget.value)}
                  required
                />

                <InputGroup.Text
                  id="eyeOrNot"
                  className="rounded-0 border-0"
                  onClick={() => setIsView1(!isView1)}
                >
                  {" "}
                  <i
                    className={
                      !isView1
                        ? "ri-eye-off-fill text-secondary"
                        : "ri-eye-fill text-secondary"
                    }
                  ></i>
                </InputGroup.Text>
                {!isValidPassword && (
                  <p className="ps-2 mt-1 font-65 text-yellow">
                    Le mot de passe doit contenir au moins 12 caractères, une
                    lettre majuscule et un caractère spécial,{" "}
                    <b>Indiquez le au format approprié.</b>.
                  </p>
                )}
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label className="d-none">
                Mot de passe de confirmation
              </Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text
                  id="basic-addon1"
                  className="rounded-0 border-0"
                >
                  <i className="ri-rotate-lock-fill text-muted"></i>
                </InputGroup.Text>
                <Form.Control
                  className="shadow border-0"
                  style={{ position: "relative" }}
                  type={isView2 ? "text" : "password"}
                  name="pass2"
                  placeholder="Confirmez votre mot de passe"
                  value={pass2}
                  onChange={(e) => setPass2(e.currentTarget.value)}
                  required
                />
                <InputGroup.Text
                  id="eyeOrNot"
                  className="rounded-0 border-0"
                  onClick={() => setIsView2(!isView2)}
                >
                  {" "}
                  <i
                    className={
                      !isView2
                        ? "ri-eye-off-fill text-secondary"
                        : "ri-eye-fill text-secondary"
                    }
                  ></i>
                </InputGroup.Text>
              </InputGroup>
              {/* {!passwordIsValid(pass1) && <span>Le mot de passe doit contenir au moins 12 caractères, une lettre majuscule et un caractère spécial.</span>} */}

              {pass1 && pass2 && pass2.length > 2 && pass1 !== pass2 && (
               <p className="ps-2 font-75 text-yellow bg-dangr">
               Vous devez saisir le même mot de passe, <b>réessayez.</b>
             </p>
              
              )}
              {/* {pass1 ===  && (
                <Alert variant='danger' className='mt-2 py-0 text-cente'>
                  <InfoAlert
                    icon='ri-error-warning-line'
                    iconColor='danger'
                    message={"le mot de passe doit contenir au moins une lettre minuscule, une lettre majuscule, un chiffre et un caractère spécial, et qu'il a une longueur minimale de 12 caractères"}
                    fontSize='font-75'
                  />
                </Alert>
              )} */}
            </Form.Group>

            <button
              type="submit"
              id=""
              className="button-auth rounded  w-100 py-2 mt-4 text-light shadow"
              disabled={pass1 && pass2 && pass1 !== pass2 ? true : false}
            >
              {isLoadingPass && <Spinner variant="light" size="sm" />} Modifier
            </button>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default UpdatePassword
