import React from "react";
import { Container, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import UserQrcode from "../ui/modals/UserQrcode";
import userDataStore from "../../store/userDataStore";

const BottomNavBar = ({
  selectedStore,
  selectedItem,
  setSelectedItem,
  bottomProps,
  
}: any) => {
  const { orderReady, orderPickedUp, orderExpired, } = bottomProps;
  //////////////////////////
  // Store
  /////////////////////////
  const dataStore = userDataStore((state: any) => state);

  //////////////////////
  //Auth deliverer modal
  //////////////////////
  const [show, setShow] = React.useState(false);
  const [isScroll, setIsScroll] = React.useState(false);
  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true)

  ///////////////////////
  //Filter by status
  ///////////////////////
  const retrieve = orderExpired["hydra:member"]?.filter(
    (order: any) =>
      order?.bookingSlot?.slot?.temperatureZone?.locker &&
      order?.bookingSlot?.slot?.temperatureZone?.locker["@id"] === selectedStore
  );
  const progress = orderPickedUp["hydra:member"]?.filter(
    (order: any) =>
      order?.bookingSlot?.slot?.temperatureZone?.locker["@id"] &&
      order?.bookingSlot?.slot?.temperatureZone?.locker["@id"] ===
        selectedStore &&
      order?.shippedBy &&
      order?.shippedBy["@id"] === `/api/users/${dataStore.id}`
  );
  const ready_for_delivery = orderReady["hydra:member"]?.filter(
    (order: any) =>
      order?.bookingSlot?.slot?.temperatureZone?.locker &&
      order?.bookingSlot.slot?.temperatureZone?.locker["@id"] === selectedStore
  );

  ///////////////////////////////
  //onSelect color font change
  ///////////////////////////////
  const handleSelect = (item: string) => {
    setSelectedItem(item);
  };

  React.useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > 100) {
        setIsScroll(true);
      } else {
        setIsScroll(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <Container
      fluid
      className={`bottom-navbar ${
        isScroll
          ? "animate__animated animate__fadeOutDown"
          : "animate__animated animate__fadeInUp"
      } py-1  px-0 mt-auto`}
    >
      <UserQrcode show={show} handleClose={handleClose} />
      <Nav className="justify-content-around border-0 rounded" activeKey="home">
        <Nav.Item
          className="nav-item text-center"
          onClick={() => handleSelect("preparations")}
        >
          <Link
            to="/preparations"
            className={`nav-lin  text-${
              selectedItem === "preparations" ? "ui" : "green"
            } py-1 pb-2 px-0 text-decoration-none`}
          >
            <i className="ri-list-check-3 fs-3"></i>
            {ready_for_delivery?.length > 0 && (
              <span
                className={`badge border-1 border-yellow rounded-pill text-${
                  selectedItem === "preparations" ? "green" : "ui"
                } bg-${selectedItem === "preparations" ? "ui" : "green"}`}
              >
                {ready_for_delivery?.length}
              </span>
            )}
            <p>
              <b>Chargements</b>
            </p>
          </Link>
        </Nav.Item>
        <Nav.Item
          className="nav-item text-center"
          onClick={() => handleSelect("progress")}
        >
          <Link
            className={`nav-lin px-0 text-${
              selectedItem === "progress" ? "ui" : "green"
            } py-1 pb-2 text-decoration-none`}
            to="/livraisons"
          >
            <i className="ri-truck-line fs-3 "></i>
            {progress?.length > 0 && (
              <span
                className={`badge border-1 border-yellow rounded-pill text-${
                  selectedItem === "progress" ? "green" : "ui"
                }  bg-${selectedItem === "progress" ? "ui" : "green"}`}
              >
                {progress?.length}
              </span>
            )}
            <p>
              <b>Livraisons</b>
            </p>
          </Link>
        </Nav.Item>
        <Nav.Item
          className="nav-item text-center"
          onClick={() => handleSelect("retrieve")}
        >
          <Link
            className={`nav-lin px-0  text-${
              selectedItem === "retrieve" ? "ui" : "green"
            } py-1 pb-2 text-decoration-none`}
            to="/retraits"
          >
            <i className="ri-inbox-unarchive-line fs-3 text-center"></i>
            {retrieve?.length > 0 && (
              <span
                className={`badge border-1 border-yellow rounded-pill text-${
                  selectedItem === "retrieve" ? "green" : "ui"
                } bg-${selectedItem === "retrieve" ? "ui" : "green"}`}
              >
                {retrieve?.length}
              </span>
            )}
            <p>
              <b>Retraits</b>
            </p>
          </Link>
        </Nav.Item>
      </Nav>
    </Container>
  );
};
export default BottomNavBar;
