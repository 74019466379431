import React from "react";
import { placeHolderProps } from "../../../utils/definitions";
import { OrderLoaderItems } from "./ItemsLoader";



const PlaceHolder: React.FC<placeHolderProps> = ({ itemCount }) => {
  return (
    <>
      {Array.from({ length: itemCount })?.map((_: any, indx: number) => (
      <OrderLoaderItems key={indx} />
      ))}
    </>
  );
};

export default PlaceHolder;
