export const diversProd = [
    {
      id: "984968",
      ean: ["9300605113152", "3971846220018", "3504750011035", "9791069982376"],
      name: "200G Café NesCafé",
      price: 450,
      taxe: 16,
    },
    {
      id: "984969",
      ean: ["3616474434639", "3971846220036", "3560070656569", "3560070656257"],
      name: "4 PILES AA CRF",
      price: 650,
      taxe: 16,
    },
    {
      id: "984970",
      ean: ["5397184622001", "3608580006657", "3508473300612"],
      name: "PORTABLE VOSTRO 3510 DELL",
      price: 120000,
      taxe: 16,
    },
    {
      id: "984971",
      ean: ["3245390058782", "3228021170046", "3560070636363", "3560070626262"],
      name: "Ramette A4",
      price: 3500,
      taxe: 16,
    },
  ];