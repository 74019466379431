export const orders = [
  {
      "@id": "/api/orders/417",
      "@type": "Order",
      "id": 417,
      "barcode": "CFP-176-7971",
      "status": "operin",
      "bookingSlot": {
          "@id": "/api/booking_slots/1",
          "@type": "BookingSlot",
          "slot": {
              "@id": "/api/slots/1",
              "@type": "Slot",
              "temperatureZone": {
                  "@id": "/api/temperature_zones/1",
                  "@type": "TemperatureZone",
                  "locker": {
                      "@id": "/api/lockers/1",
                      "@type": "Locker",
                      "shortLocation": "Punaauia 2"
                  },
                  "name": "Froid positif"
              },
              "size": "M"
          }
      },
      "shippedBy": {
          "@id": "/api/users/5",
          "@type": "User",
          "id": 5,
          "email": "bertille.johnston@lwane.com",
          "username": "bertille",
          "roles": [
              "ROLE_SHIPPER",
              "ROLE_USER"
          ],
          "firstName": "Bertille",
          "lastName": "Johnston",
          "phone": "89571833",
          "apmAccessCode": 697978,
          "password": "$2y$13$Jo4B50MwjYvSps5tjbQwyOnPnfd7hwqzI.IJV/4/ESO0mFOo8UI7.",
          "enabled": true,
          "ownerships": [],
          "memberships": [
              "/api/companies/1"
          ],
          "deliveries": [
              "/api/orders/9",
              "/api/orders/10",
              "/api/orders/11",
              "/api/orders/12",
              "/api/orders/13",
              "/api/orders/14",
              "/api/orders/15",
              "/api/orders/16",
              "/api/orders/17",
              "/api/orders/18",
              "/api/orders/19",
              "/api/orders/20",
              "/api/orders/21",
              "/api/orders/22",
              "/api/orders/23",
              "/api/orders/24",
              "/api/orders/25",
              "/api/orders/27",
              "/api/orders/30",
              "/api/orders/31",
              "/api/orders/32",
              "/api/orders/33",
              "/api/orders/34",
              "/api/orders/35",
              "/api/orders/36",
              "/api/orders/37",
              "/api/orders/38",
              "/api/orders/39",
              "/api/orders/40",
              "/api/orders/41",
              "/api/orders/42",
              "/api/orders/43",
              "/api/orders/44",
              "/api/orders/45",
              "/api/orders/46",
              "/api/orders/47",
              "/api/orders/48",
              "/api/orders/49",
              "/api/orders/50",
              "/api/orders/51",
              "/api/orders/52",
              "/api/orders/53",
              "/api/orders/54",
              "/api/orders/60",
              "/api/orders/61",
              "/api/orders/62",
              "/api/orders/63",
              "/api/orders/64",
              "/api/orders/72",
              "/api/orders/73",
              "/api/orders/74",
              "/api/orders/75",
              "/api/orders/76",
              "/api/orders/77",
              "/api/orders/78",
              "/api/orders/79",
              "/api/orders/80",
              "/api/orders/81",
              "/api/orders/82",
              "/api/orders/83",
              "/api/orders/84",
              "/api/orders/85",
              "/api/orders/86",
              "/api/orders/87",
              "/api/orders/88",
              "/api/orders/89",
              "/api/orders/90",
              "/api/orders/93",
              "/api/orders/94",
              "/api/orders/95",
              "/api/orders/102",
              "/api/orders/103",
              "/api/orders/104",
              "/api/orders/115",
              "/api/orders/116",
              "/api/orders/117",
              "/api/orders/118",
              "/api/orders/119",
              "/api/orders/120",
              "/api/orders/121",
              "/api/orders/122",
              "/api/orders/123",
              "/api/orders/124",
              "/api/orders/125",
              "/api/orders/126",
              "/api/orders/127",
              "/api/orders/128",
              "/api/orders/129",
              "/api/orders/130",
              "/api/orders/147",
              "/api/orders/148",
              "/api/orders/154",
              "/api/orders/155",
              "/api/orders/156",
              "/api/orders/157",
              "/api/orders/158",
              "/api/orders/159",
              "/api/orders/160",
              "/api/orders/162",
              "/api/orders/163",
              "/api/orders/170",
              "/api/orders/185",
              "/api/orders/186",
              "/api/orders/187",
              "/api/orders/188",
              "/api/orders/189",
              "/api/orders/200",
              "/api/orders/201",
              "/api/orders/215",
              "/api/orders/216",
              "/api/orders/218",
              "/api/orders/236",
              "/api/orders/237",
              "/api/orders/238",
              "/api/orders/248",
              "/api/orders/249",
              "/api/orders/250",
              "/api/orders/251",
              "/api/orders/256",
              "/api/orders/257",
              "/api/orders/265",
              "/api/orders/266",
              "/api/orders/267",
              "/api/orders/277",
              "/api/orders/278",
              "/api/orders/306",
              "/api/orders/307",
              "/api/orders/308",
              "/api/orders/317",
              "/api/orders/318",
              "/api/orders/319",
              "/api/orders/349",
              "/api/orders/350",
              "/api/orders/351",
              "/api/orders/352",
              "/api/orders/353",
              "/api/orders/354",
              "/api/orders/355",
              "/api/orders/356",
              "/api/orders/357",
              "/api/orders/368",
              "/api/orders/382",
              "/api/orders/383",
              "/api/orders/384",
              "/api/orders/385",
              "/api/orders/386",
              "/api/orders/387",
              "/api/orders/388",
              "/api/orders/417",
              "/api/orders/418",
              "/api/orders/419",
              "/api/orders/420",
              "/api/orders/421"
          ],
          "createdAt": "2024-02-23T15:46:09-10:00",
          "updatedAt": "2024-04-10T12:17:13-10:00",
          "userIdentifier": "bertille"
      },
      "history": [
          {
              "@id": "/api/order_histories/1599",
              "@type": "OrderHistory",
              "status": "created",
              "createdAt": "2024-06-03T11:16:00-10:00"
          },
          {
              "@id": "/api/order_histories/1611",
              "@type": "OrderHistory",
              "status": "operin",
              "createdAt": "2024-06-03T12:11:55-10:00"
          },
          {
              "@id": "/api/order_histories/1604",
              "@type": "OrderHistory",
              "status": "ready_for_delivery",
              "createdAt": "2024-06-03T11:16:00-10:00"
          }
      ],
      "products": [
          {
              "name": "Jambon Supérieur à l'étouffée LE CAMPAGNÉ - la barquette de 4 tranches de 170g",
              "quantity": 1,
              "price": 1
          },
          {
              "name": "Lait Entier MEADOW FRESH - la brique d'1L",
              "quantity": 2,
              "price": 1
          },
          {
              "name": "Comté au Lait Cru AOP CARREFOUR EXTRA - le fromage de 250g",
              "quantity": 1,
              "price": 1
          },
          {
              "name": "Fromage de Brebis CARREFOUR CLASSIC' - le paquet de 200g",
              "quantity": 1,
              "price": 1
          },
          {
              "name": "Tarti'bon Nature CARREFOUR CLASSIC' - la barquette de 300g",
              "quantity": 1,
              "price": 1
          },
          {
              "name": "La Chèvrie PRÉSIDENT - le fromage de 180g",
              "quantity": 1,
              "price": 1
          }
      ],
      "multiOrderCode": "AVNIODYH",
      "createdAt": "2024-06-03T11:16:00-10:00",
      "updatedAt": "2024-06-03T12:11:55-10:00",
      "cleveronSlotId": 79
  },
  {
      "@id": "/api/orders/418",
      "@type": "Order",
      "id": 418,
      "barcode": "CFP-176-7972",
      "status": "operin",
      "bookingSlot": {
          "@id": "/api/booking_slots/2",
          "@type": "BookingSlot",
          "slot": {
              "@id": "/api/slots/2",
              "@type": "Slot",
              "temperatureZone": {
                  "@id": "/api/temperature_zones/2",
                  "@type": "TemperatureZone",
                  "locker": {
                      "@id": "/api/lockers/1",
                      "@type": "Locker",
                      "shortLocation": "Punaauia 2"
                  },
                  "name": "Ambiant"
              },
              "size": "M"
          }
      },
      "shippedBy": {
          "@id": "/api/users/5",
          "@type": "User",
          "id": 5,
          "email": "bertille.johnston@lwane.com",
          "username": "bertille",
          "roles": [
              "ROLE_SHIPPER",
              "ROLE_USER"
          ],
          "firstName": "Bertille",
          "lastName": "Johnston",
          "phone": "89571833",
          "apmAccessCode": 697978,
          "password": "$2y$13$Jo4B50MwjYvSps5tjbQwyOnPnfd7hwqzI.IJV/4/ESO0mFOo8UI7.",
          "enabled": true,
          "ownerships": [],
          "memberships": [
              "/api/companies/1"
          ],
          "deliveries": [
              "/api/orders/9",
              "/api/orders/10",
              "/api/orders/11",
              "/api/orders/12",
              "/api/orders/13",
              "/api/orders/14",
              "/api/orders/15",
              "/api/orders/16",
              "/api/orders/17",
              "/api/orders/18",
              "/api/orders/19",
              "/api/orders/20",
              "/api/orders/21",
              "/api/orders/22",
              "/api/orders/23",
              "/api/orders/24",
              "/api/orders/25",
              "/api/orders/27",
              "/api/orders/30",
              "/api/orders/31",
              "/api/orders/32",
              "/api/orders/33",
              "/api/orders/34",
              "/api/orders/35",
              "/api/orders/36",
              "/api/orders/37",
              "/api/orders/38",
              "/api/orders/39",
              "/api/orders/40",
              "/api/orders/41",
              "/api/orders/42",
              "/api/orders/43",
              "/api/orders/44",
              "/api/orders/45",
              "/api/orders/46",
              "/api/orders/47",
              "/api/orders/48",
              "/api/orders/49",
              "/api/orders/50",
              "/api/orders/51",
              "/api/orders/52",
              "/api/orders/53",
              "/api/orders/54",
              "/api/orders/60",
              "/api/orders/61",
              "/api/orders/62",
              "/api/orders/63",
              "/api/orders/64",
              "/api/orders/72",
              "/api/orders/73",
              "/api/orders/74",
              "/api/orders/75",
              "/api/orders/76",
              "/api/orders/77",
              "/api/orders/78",
              "/api/orders/79",
              "/api/orders/80",
              "/api/orders/81",
              "/api/orders/82",
              "/api/orders/83",
              "/api/orders/84",
              "/api/orders/85",
              "/api/orders/86",
              "/api/orders/87",
              "/api/orders/88",
              "/api/orders/89",
              "/api/orders/90",
              "/api/orders/93",
              "/api/orders/94",
              "/api/orders/95",
              "/api/orders/102",
              "/api/orders/103",
              "/api/orders/104",
              "/api/orders/115",
              "/api/orders/116",
              "/api/orders/117",
              "/api/orders/118",
              "/api/orders/119",
              "/api/orders/120",
              "/api/orders/121",
              "/api/orders/122",
              "/api/orders/123",
              "/api/orders/124",
              "/api/orders/125",
              "/api/orders/126",
              "/api/orders/127",
              "/api/orders/128",
              "/api/orders/129",
              "/api/orders/130",
              "/api/orders/147",
              "/api/orders/148",
              "/api/orders/154",
              "/api/orders/155",
              "/api/orders/156",
              "/api/orders/157",
              "/api/orders/158",
              "/api/orders/159",
              "/api/orders/160",
              "/api/orders/162",
              "/api/orders/163",
              "/api/orders/170",
              "/api/orders/185",
              "/api/orders/186",
              "/api/orders/187",
              "/api/orders/188",
              "/api/orders/189",
              "/api/orders/200",
              "/api/orders/201",
              "/api/orders/215",
              "/api/orders/216",
              "/api/orders/218",
              "/api/orders/236",
              "/api/orders/237",
              "/api/orders/238",
              "/api/orders/248",
              "/api/orders/249",
              "/api/orders/250",
              "/api/orders/251",
              "/api/orders/256",
              "/api/orders/257",
              "/api/orders/265",
              "/api/orders/266",
              "/api/orders/267",
              "/api/orders/277",
              "/api/orders/278",
              "/api/orders/306",
              "/api/orders/307",
              "/api/orders/308",
              "/api/orders/317",
              "/api/orders/318",
              "/api/orders/319",
              "/api/orders/349",
              "/api/orders/350",
              "/api/orders/351",
              "/api/orders/352",
              "/api/orders/353",
              "/api/orders/354",
              "/api/orders/355",
              "/api/orders/356",
              "/api/orders/357",
              "/api/orders/368",
              "/api/orders/382",
              "/api/orders/383",
              "/api/orders/384",
              "/api/orders/385",
              "/api/orders/386",
              "/api/orders/387",
              "/api/orders/388",
              "/api/orders/417",
              "/api/orders/418",
              "/api/orders/419",
              "/api/orders/420",
              "/api/orders/421"
          ],
          "createdAt": "2024-02-23T15:46:09-10:00",
          "updatedAt": "2024-04-10T12:17:13-10:00",
          "userIdentifier": "bertille"
      },
      "history": [
          {
              "@id": "/api/order_histories/1600",
              "@type": "OrderHistory",
              "status": "created",
              "createdAt": "2024-06-03T11:16:01-10:00"
          },
          {
              "@id": "/api/order_histories/1612",
              "@type": "OrderHistory",
              "status": "operin",
              "createdAt": "2024-06-03T12:16:22-10:00"
          },
          {
              "@id": "/api/order_histories/1605",
              "@type": "OrderHistory",
              "status": "ready_for_delivery",
              "createdAt": "2024-06-03T11:16:02-10:00"
          }
      ],
      "products": [
          {
              "name": "Blédilait Croissance+ Tout doux BLÉDINA - la boîte de 900g",
              "quantity": 2,
              "price": 1
          },
          {
              "name": "Lentilles Corail CARREFOUR CLASSIC' - le paquet 500g",
              "quantity": 2,
              "price": 1
          },
          {
              "name": "Purée Classic CARREFOUR - les 4 sachets de 125g",
              "quantity": 1,
              "price": 1
          },
          {
              "name": "Riz Basmati CARREFOUR EXTRA - le paquet d'1Kg",
              "quantity": 1,
              "price": 1
          },
          {
              "name": "Tomates Pelées au Jus CARREFOUR CLASSIC' - la boîte de 480g ",
              "quantity": 1,
              "price": 1
          },
          {
              "name": "Haricots Rouges CARREFOUR CLASSIC' - la boîte de 500g ",
              "quantity": 1,
              "price": 1
          },
          {
              "name": "Lentilles CARREFOUR CLASSIC' - la boîte de 530g",
              "quantity": 1,
              "price": 1
          },
          {
              "name": "Tomates séchées CARREFOUR EXTRA - le pot de 165g net égoutté",
              "quantity": 1,
              "price": 1
          },
          {
              "name": "Courgettes à la Provençale CARREFOUR - le bocal de 530g",
              "quantity": 1,
              "price": 1
          },
          {
              "name": "Aubergines à la Provençale CARREFOUR EXTRA - le bocal de 530g",
              "quantity": 1,
              "price": 1
          },
          {
              "name": "Haricots Verts extra-fins CARREFOUR EXTRA - le bocal de 660g",
              "quantity": 1,
              "price": 1
          },
          {
              "name": "Ratatouille à la Provençale CARREFOUR EXTRA - le bocal de 530g",
              "quantity": 1,
              "price": 1
          }
      ],
      "multiOrderCode": "AVNIODYH",
      "createdAt": "2024-06-03T11:16:01-10:00",
      "updatedAt": "2024-06-03T12:16:22-10:00",
      "cleveronSlotId": 106
  },
  {
      "@id": "/api/orders/419",
      "@type": "Order",
      "id": 419,
      "barcode": "CFP-176-7973",
      "status": "operin",
      "bookingSlot": {
          "@id": "/api/booking_slots/2",
          "@type": "BookingSlot",
          "slot": {
              "@id": "/api/slots/2",
              "@type": "Slot",
              "temperatureZone": {
                  "@id": "/api/temperature_zones/2",
                  "@type": "TemperatureZone",
                  "locker": {
                      "@id": "/api/lockers/1",
                      "@type": "Locker",
                      "shortLocation": "Punaauia 2"
                  },
                  "name": "Ambiant"
              },
              "size": "M"
          }
      },
      "shippedBy": {
          "@id": "/api/users/5",
          "@type": "User",
          "id": 5,
          "email": "bertille.johnston@lwane.com",
          "username": "bertille",
          "roles": [
              "ROLE_SHIPPER",
              "ROLE_USER"
          ],
          "firstName": "Bertille",
          "lastName": "Johnston",
          "phone": "89571833",
          "apmAccessCode": 697978,
          "password": "$2y$13$Jo4B50MwjYvSps5tjbQwyOnPnfd7hwqzI.IJV/4/ESO0mFOo8UI7.",
          "enabled": true,
          "ownerships": [],
          "memberships": [
              "/api/companies/1"
          ],
          "deliveries": [
              "/api/orders/9",
              "/api/orders/10",
              "/api/orders/11",
              "/api/orders/12",
              "/api/orders/13",
              "/api/orders/14",
              "/api/orders/15",
              "/api/orders/16",
              "/api/orders/17",
              "/api/orders/18",
              "/api/orders/19",
              "/api/orders/20",
              "/api/orders/21",
              "/api/orders/22",
              "/api/orders/23",
              "/api/orders/24",
              "/api/orders/25",
              "/api/orders/27",
              "/api/orders/30",
              "/api/orders/31",
              "/api/orders/32",
              "/api/orders/33",
              "/api/orders/34",
              "/api/orders/35",
              "/api/orders/36",
              "/api/orders/37",
              "/api/orders/38",
              "/api/orders/39",
              "/api/orders/40",
              "/api/orders/41",
              "/api/orders/42",
              "/api/orders/43",
              "/api/orders/44",
              "/api/orders/45",
              "/api/orders/46",
              "/api/orders/47",
              "/api/orders/48",
              "/api/orders/49",
              "/api/orders/50",
              "/api/orders/51",
              "/api/orders/52",
              "/api/orders/53",
              "/api/orders/54",
              "/api/orders/60",
              "/api/orders/61",
              "/api/orders/62",
              "/api/orders/63",
              "/api/orders/64",
              "/api/orders/72",
              "/api/orders/73",
              "/api/orders/74",
              "/api/orders/75",
              "/api/orders/76",
              "/api/orders/77",
              "/api/orders/78",
              "/api/orders/79",
              "/api/orders/80",
              "/api/orders/81",
              "/api/orders/82",
              "/api/orders/83",
              "/api/orders/84",
              "/api/orders/85",
              "/api/orders/86",
              "/api/orders/87",
              "/api/orders/88",
              "/api/orders/89",
              "/api/orders/90",
              "/api/orders/93",
              "/api/orders/94",
              "/api/orders/95",
              "/api/orders/102",
              "/api/orders/103",
              "/api/orders/104",
              "/api/orders/115",
              "/api/orders/116",
              "/api/orders/117",
              "/api/orders/118",
              "/api/orders/119",
              "/api/orders/120",
              "/api/orders/121",
              "/api/orders/122",
              "/api/orders/123",
              "/api/orders/124",
              "/api/orders/125",
              "/api/orders/126",
              "/api/orders/127",
              "/api/orders/128",
              "/api/orders/129",
              "/api/orders/130",
              "/api/orders/147",
              "/api/orders/148",
              "/api/orders/154",
              "/api/orders/155",
              "/api/orders/156",
              "/api/orders/157",
              "/api/orders/158",
              "/api/orders/159",
              "/api/orders/160",
              "/api/orders/162",
              "/api/orders/163",
              "/api/orders/170",
              "/api/orders/185",
              "/api/orders/186",
              "/api/orders/187",
              "/api/orders/188",
              "/api/orders/189",
              "/api/orders/200",
              "/api/orders/201",
              "/api/orders/215",
              "/api/orders/216",
              "/api/orders/218",
              "/api/orders/236",
              "/api/orders/237",
              "/api/orders/238",
              "/api/orders/248",
              "/api/orders/249",
              "/api/orders/250",
              "/api/orders/251",
              "/api/orders/256",
              "/api/orders/257",
              "/api/orders/265",
              "/api/orders/266",
              "/api/orders/267",
              "/api/orders/277",
              "/api/orders/278",
              "/api/orders/306",
              "/api/orders/307",
              "/api/orders/308",
              "/api/orders/317",
              "/api/orders/318",
              "/api/orders/319",
              "/api/orders/349",
              "/api/orders/350",
              "/api/orders/351",
              "/api/orders/352",
              "/api/orders/353",
              "/api/orders/354",
              "/api/orders/355",
              "/api/orders/356",
              "/api/orders/357",
              "/api/orders/368",
              "/api/orders/382",
              "/api/orders/383",
              "/api/orders/384",
              "/api/orders/385",
              "/api/orders/386",
              "/api/orders/387",
              "/api/orders/388",
              "/api/orders/417",
              "/api/orders/418",
              "/api/orders/419",
              "/api/orders/420",
              "/api/orders/421"
          ],
          "createdAt": "2024-02-23T15:46:09-10:00",
          "updatedAt": "2024-04-10T12:17:13-10:00",
          "userIdentifier": "bertille"
      },
      "history": [
          {
              "@id": "/api/order_histories/1601",
              "@type": "OrderHistory",
              "status": "created",
              "createdAt": "2024-06-03T11:16:02-10:00"
          },
          {
              "@id": "/api/order_histories/1613",
              "@type": "OrderHistory",
              "status": "operin",
              "createdAt": "2024-06-03T12:17:36-10:00"
          },
          {
              "@id": "/api/order_histories/1606",
              "@type": "OrderHistory",
              "status": "ready_for_delivery",
              "createdAt": "2024-06-03T11:16:03-10:00"
          }
      ],
      "products": [
          {
              "name": "Câpres au vinaigre CARREFOUR - le bocal de 120g net égoutté",
              "quantity": 1,
              "price": 1
          },
          {
              "name": "Sucre de Canne CHELSEA - le paquet d'1kg",
              "quantity": 1,
              "price": 1
          },
          {
              "name": "Pâtes Coquillettes PANZANI - le paquet de 400g",
              "quantity": 1,
              "price": 1
          },
          {
              "name": "Pâtes Penne PANZANI - le paquet de 400g",
              "quantity": 1,
              "price": 1
          }
      ],
      "multiOrderCode": "AVNIODYH",
      "createdAt": "2024-06-03T11:16:02-10:00",
      "updatedAt": "2024-06-03T12:17:36-10:00",
      "cleveronSlotId": 100
  },
  {
      "@id": "/api/orders/420",
      "@type": "Order",
      "id": 420,
      "barcode": "CFP-177-BYV1",
      "status": "operin",
      "bookingSlot": {
          "@id": "/api/booking_slots/1",
          "@type": "BookingSlot",
          "slot": {
              "@id": "/api/slots/1",
              "@type": "Slot",
              "temperatureZone": {
                  "@id": "/api/temperature_zones/1",
                  "@type": "TemperatureZone",
                  "locker": {
                      "@id": "/api/lockers/1",
                      "@type": "Locker",
                      "shortLocation": "Punaauia 2"
                  },
                  "name": "Froid positif"
              },
              "size": "M"
          }
      },
      "shippedBy": {
          "@id": "/api/users/5",
          "@type": "User",
          "id": 5,
          "email": "bertille.johnston@lwane.com",
          "username": "bertille",
          "roles": [
              "ROLE_SHIPPER",
              "ROLE_USER"
          ],
          "firstName": "Bertille",
          "lastName": "Johnston",
          "phone": "89571833",
          "apmAccessCode": 697978,
          "password": "$2y$13$Jo4B50MwjYvSps5tjbQwyOnPnfd7hwqzI.IJV/4/ESO0mFOo8UI7.",
          "enabled": true,
          "ownerships": [],
          "memberships": [
              "/api/companies/1"
          ],
          "deliveries": [
              "/api/orders/9",
              "/api/orders/10",
              "/api/orders/11",
              "/api/orders/12",
              "/api/orders/13",
              "/api/orders/14",
              "/api/orders/15",
              "/api/orders/16",
              "/api/orders/17",
              "/api/orders/18",
              "/api/orders/19",
              "/api/orders/20",
              "/api/orders/21",
              "/api/orders/22",
              "/api/orders/23",
              "/api/orders/24",
              "/api/orders/25",
              "/api/orders/27",
              "/api/orders/30",
              "/api/orders/31",
              "/api/orders/32",
              "/api/orders/33",
              "/api/orders/34",
              "/api/orders/35",
              "/api/orders/36",
              "/api/orders/37",
              "/api/orders/38",
              "/api/orders/39",
              "/api/orders/40",
              "/api/orders/41",
              "/api/orders/42",
              "/api/orders/43",
              "/api/orders/44",
              "/api/orders/45",
              "/api/orders/46",
              "/api/orders/47",
              "/api/orders/48",
              "/api/orders/49",
              "/api/orders/50",
              "/api/orders/51",
              "/api/orders/52",
              "/api/orders/53",
              "/api/orders/54",
              "/api/orders/60",
              "/api/orders/61",
              "/api/orders/62",
              "/api/orders/63",
              "/api/orders/64",
              "/api/orders/72",
              "/api/orders/73",
              "/api/orders/74",
              "/api/orders/75",
              "/api/orders/76",
              "/api/orders/77",
              "/api/orders/78",
              "/api/orders/79",
              "/api/orders/80",
              "/api/orders/81",
              "/api/orders/82",
              "/api/orders/83",
              "/api/orders/84",
              "/api/orders/85",
              "/api/orders/86",
              "/api/orders/87",
              "/api/orders/88",
              "/api/orders/89",
              "/api/orders/90",
              "/api/orders/93",
              "/api/orders/94",
              "/api/orders/95",
              "/api/orders/102",
              "/api/orders/103",
              "/api/orders/104",
              "/api/orders/115",
              "/api/orders/116",
              "/api/orders/117",
              "/api/orders/118",
              "/api/orders/119",
              "/api/orders/120",
              "/api/orders/121",
              "/api/orders/122",
              "/api/orders/123",
              "/api/orders/124",
              "/api/orders/125",
              "/api/orders/126",
              "/api/orders/127",
              "/api/orders/128",
              "/api/orders/129",
              "/api/orders/130",
              "/api/orders/147",
              "/api/orders/148",
              "/api/orders/154",
              "/api/orders/155",
              "/api/orders/156",
              "/api/orders/157",
              "/api/orders/158",
              "/api/orders/159",
              "/api/orders/160",
              "/api/orders/162",
              "/api/orders/163",
              "/api/orders/170",
              "/api/orders/185",
              "/api/orders/186",
              "/api/orders/187",
              "/api/orders/188",
              "/api/orders/189",
              "/api/orders/200",
              "/api/orders/201",
              "/api/orders/215",
              "/api/orders/216",
              "/api/orders/218",
              "/api/orders/236",
              "/api/orders/237",
              "/api/orders/238",
              "/api/orders/248",
              "/api/orders/249",
              "/api/orders/250",
              "/api/orders/251",
              "/api/orders/256",
              "/api/orders/257",
              "/api/orders/265",
              "/api/orders/266",
              "/api/orders/267",
              "/api/orders/277",
              "/api/orders/278",
              "/api/orders/306",
              "/api/orders/307",
              "/api/orders/308",
              "/api/orders/317",
              "/api/orders/318",
              "/api/orders/319",
              "/api/orders/349",
              "/api/orders/350",
              "/api/orders/351",
              "/api/orders/352",
              "/api/orders/353",
              "/api/orders/354",
              "/api/orders/355",
              "/api/orders/356",
              "/api/orders/357",
              "/api/orders/368",
              "/api/orders/382",
              "/api/orders/383",
              "/api/orders/384",
              "/api/orders/385",
              "/api/orders/386",
              "/api/orders/387",
              "/api/orders/388",
              "/api/orders/417",
              "/api/orders/418",
              "/api/orders/419",
              "/api/orders/420",
              "/api/orders/421"
          ],
          "createdAt": "2024-02-23T15:46:09-10:00",
          "updatedAt": "2024-04-10T12:17:13-10:00",
          "userIdentifier": "bertille"
      },
      "history": [
          {
              "@id": "/api/order_histories/1602",
              "@type": "OrderHistory",
              "status": "created",
              "createdAt": "2024-06-03T11:36:23-10:00"
          },
          {
              "@id": "/api/order_histories/1609",
              "@type": "OrderHistory",
              "status": "operin",
              "createdAt": "2024-06-03T12:09:21-10:00"
          },
          {
              "@id": "/api/order_histories/1607",
              "@type": "OrderHistory",
              "status": "ready_for_delivery",
              "createdAt": "2024-06-03T11:36:24-10:00"
          }
      ],
      "products": [
          {
              "name": "Emmental Râpé Fondant PRÉSIDENT - le sachet de 70g",
              "quantity": 1,
              "price": 1
          }
      ],
      "multiOrderCode": "WPXSFFDD",
      "createdAt": "2024-06-03T11:36:23-10:00",
      "updatedAt": "2024-06-03T12:09:21-10:00",
      "cleveronSlotId": 3
  },
  {
      "@id": "/api/orders/421",
      "@type": "Order",
      "id": 421,
      "barcode": "CFP-177-BYV2",
      "status": "operin",
      "bookingSlot": {
          "@id": "/api/booking_slots/2",
          "@type": "BookingSlot",
          "slot": {
              "@id": "/api/slots/2",
              "@type": "Slot",
              "temperatureZone": {
                  "@id": "/api/temperature_zones/2",
                  "@type": "TemperatureZone",
                  "locker": {
                      "@id": "/api/lockers/1",
                      "@type": "Locker",
                      "shortLocation": "Punaauia 2"
                  },
                  "name": "Ambiant"
              },
              "size": "M"
          }
      },
      "shippedBy": {
          "@id": "/api/users/5",
          "@type": "User",
          "id": 5,
          "email": "bertille.johnston@lwane.com",
          "username": "bertille",
          "roles": [
              "ROLE_SHIPPER",
              "ROLE_USER"
          ],
          "firstName": "Bertille",
          "lastName": "Johnston",
          "phone": "89571833",
          "apmAccessCode": 697978,
          "password": "$2y$13$Jo4B50MwjYvSps5tjbQwyOnPnfd7hwqzI.IJV/4/ESO0mFOo8UI7.",
          "enabled": true,
          "ownerships": [],
          "memberships": [
              "/api/companies/1"
          ],
          "deliveries": [
              "/api/orders/9",
              "/api/orders/10",
              "/api/orders/11",
              "/api/orders/12",
              "/api/orders/13",
              "/api/orders/14",
              "/api/orders/15",
              "/api/orders/16",
              "/api/orders/17",
              "/api/orders/18",
              "/api/orders/19",
              "/api/orders/20",
              "/api/orders/21",
              "/api/orders/22",
              "/api/orders/23",
              "/api/orders/24",
              "/api/orders/25",
              "/api/orders/27",
              "/api/orders/30",
              "/api/orders/31",
              "/api/orders/32",
              "/api/orders/33",
              "/api/orders/34",
              "/api/orders/35",
              "/api/orders/36",
              "/api/orders/37",
              "/api/orders/38",
              "/api/orders/39",
              "/api/orders/40",
              "/api/orders/41",
              "/api/orders/42",
              "/api/orders/43",
              "/api/orders/44",
              "/api/orders/45",
              "/api/orders/46",
              "/api/orders/47",
              "/api/orders/48",
              "/api/orders/49",
              "/api/orders/50",
              "/api/orders/51",
              "/api/orders/52",
              "/api/orders/53",
              "/api/orders/54",
              "/api/orders/60",
              "/api/orders/61",
              "/api/orders/62",
              "/api/orders/63",
              "/api/orders/64",
              "/api/orders/72",
              "/api/orders/73",
              "/api/orders/74",
              "/api/orders/75",
              "/api/orders/76",
              "/api/orders/77",
              "/api/orders/78",
              "/api/orders/79",
              "/api/orders/80",
              "/api/orders/81",
              "/api/orders/82",
              "/api/orders/83",
              "/api/orders/84",
              "/api/orders/85",
              "/api/orders/86",
              "/api/orders/87",
              "/api/orders/88",
              "/api/orders/89",
              "/api/orders/90",
              "/api/orders/93",
              "/api/orders/94",
              "/api/orders/95",
              "/api/orders/102",
              "/api/orders/103",
              "/api/orders/104",
              "/api/orders/115",
              "/api/orders/116",
              "/api/orders/117",
              "/api/orders/118",
              "/api/orders/119",
              "/api/orders/120",
              "/api/orders/121",
              "/api/orders/122",
              "/api/orders/123",
              "/api/orders/124",
              "/api/orders/125",
              "/api/orders/126",
              "/api/orders/127",
              "/api/orders/128",
              "/api/orders/129",
              "/api/orders/130",
              "/api/orders/147",
              "/api/orders/148",
              "/api/orders/154",
              "/api/orders/155",
              "/api/orders/156",
              "/api/orders/157",
              "/api/orders/158",
              "/api/orders/159",
              "/api/orders/160",
              "/api/orders/162",
              "/api/orders/163",
              "/api/orders/170",
              "/api/orders/185",
              "/api/orders/186",
              "/api/orders/187",
              "/api/orders/188",
              "/api/orders/189",
              "/api/orders/200",
              "/api/orders/201",
              "/api/orders/215",
              "/api/orders/216",
              "/api/orders/218",
              "/api/orders/236",
              "/api/orders/237",
              "/api/orders/238",
              "/api/orders/248",
              "/api/orders/249",
              "/api/orders/250",
              "/api/orders/251",
              "/api/orders/256",
              "/api/orders/257",
              "/api/orders/265",
              "/api/orders/266",
              "/api/orders/267",
              "/api/orders/277",
              "/api/orders/278",
              "/api/orders/306",
              "/api/orders/307",
              "/api/orders/308",
              "/api/orders/317",
              "/api/orders/318",
              "/api/orders/319",
              "/api/orders/349",
              "/api/orders/350",
              "/api/orders/351",
              "/api/orders/352",
              "/api/orders/353",
              "/api/orders/354",
              "/api/orders/355",
              "/api/orders/356",
              "/api/orders/357",
              "/api/orders/368",
              "/api/orders/382",
              "/api/orders/383",
              "/api/orders/384",
              "/api/orders/385",
              "/api/orders/386",
              "/api/orders/387",
              "/api/orders/388",
              "/api/orders/417",
              "/api/orders/418",
              "/api/orders/419",
              "/api/orders/420",
              "/api/orders/421"
          ],
          "createdAt": "2024-02-23T15:46:09-10:00",
          "updatedAt": "2024-04-10T12:17:13-10:00",
          "userIdentifier": "bertille"
      },
      "history": [
          {
              "@id": "/api/order_histories/1603",
              "@type": "OrderHistory",
              "status": "created",
              "createdAt": "2024-06-03T11:36:25-10:00"
          },
          {
              "@id": "/api/order_histories/1610",
              "@type": "OrderHistory",
              "status": "operin",
              "createdAt": "2024-06-03T12:10:50-10:00"
          },
          {
              "@id": "/api/order_histories/1608",
              "@type": "OrderHistory",
              "status": "ready_for_delivery",
              "createdAt": "2024-06-03T11:36:25-10:00"
          }
      ],
      "products": [
          {
              "name": "Muesli Énergie MARKAL - le paquet de 500g",
              "quantity": 1,
              "price": 1
          },
          {
              "name": "Compote Pomme Nature ANDROS - le bocal de 750g",
              "quantity": 1,
              "price": 1
          },
          {
              "name": "Tablette de Chocolat Noir Caramel Fleur de Sel EXCELLENCE LINDT - la tablette de 100g",
              "quantity": 1,
              "price": 1
          },
          {
              "name": "Café Colombie CARREFOUR SENSATION - le paquet de 250g",
              "quantity": 1,
              "price": 1
          },
          {
              "name": "Gel Douche Monoï CARREFOUR SOFT - le flacon de 250mL",
              "quantity": 1,
              "price": 1
          },
          {
              "name": "Savon Mousse Vanille DETTOL - le flacon de 250mL",
              "quantity": 1,
              "price": 1
          },
          {
              "name": "Papier toilette DOUCEUR NATURE - les 6 rouleaux",
              "quantity": 1,
              "price": 1
          },
          {
              "name": "Piles Alcalines AA/LR6 MN1500 PLUS DURACELL - le lot de 4 piles",
              "quantity": 1,
              "price": 1
          },
          {
              "name": "Piles Alcalines AAA/LR3 MN2400 OPTIMUM DURACELL - le lot de 4 piles",
              "quantity": 1,
              "price": 1
          }
      ],
      "multiOrderCode": "WPXSFFDD",
      "createdAt": "2024-06-03T11:36:25-10:00",
      "updatedAt": "2024-06-03T12:10:50-10:00",
      "cleveronSlotId": 9
  },
  {
    "@id": "/api/orders/420",
    "@type": "Order",
    "id": 420,
    "barcode": "CFP-177-BUX1",
    "status": "operin",
    "bookingSlot": {
        "@id": "/api/booking_slots/1",
        "@type": "BookingSlot",
        "slot": {
            "@id": "/api/slots/1",
            "@type": "Slot",
            "temperatureZone": {
                "@id": "/api/temperature_zones/1",
                "@type": "TemperatureZone",
                "locker": {
                    "@id": "/api/lockers/1",
                    "@type": "Locker",
                    "shortLocation": "Punaauia 2"
                },
                "name": "Froid positif"
            },
            "size": "M"
        }
    },
    "shippedBy": {
        "@id": "/api/users/5",
        "@type": "User",
        "id": 5,
        "email": "bertille.johnston@lwane.com",
        "username": "bertille",
        "roles": [
            "ROLE_SHIPPER",
            "ROLE_USER"
        ],
        "firstName": "Bertille",
        "lastName": "Johnston",
        "phone": "89571833",
        "apmAccessCode": 697978,
        "password": "$2y$13$Jo4B50MwjYvSps5tjbQwyOnPnfd7hwqzI.IJV/4/ESO0mFOo8UI7.",
        "enabled": true,
        "ownerships": [],
        "memberships": [
            "/api/companies/1"
        ],
        "deliveries": [
            "/api/orders/9",
            "/api/orders/10",
            "/api/orders/11",
            "/api/orders/12",
            "/api/orders/13",
            "/api/orders/14",
            "/api/orders/15",
            "/api/orders/16",
            "/api/orders/17",
            "/api/orders/18",
            "/api/orders/19",
            "/api/orders/20",
            "/api/orders/21",
            "/api/orders/22",
            "/api/orders/23",
            "/api/orders/24",
            "/api/orders/25",
            "/api/orders/27",
            "/api/orders/30",
            "/api/orders/31",
            "/api/orders/32",
            "/api/orders/33",
            "/api/orders/34",
            "/api/orders/35",
            "/api/orders/36",
            "/api/orders/37",
            "/api/orders/38",
            "/api/orders/39",
            "/api/orders/40",
            "/api/orders/41",
            "/api/orders/42",
            "/api/orders/43",
            "/api/orders/44",
            "/api/orders/45",
            "/api/orders/46",
            "/api/orders/47",
            "/api/orders/48",
            "/api/orders/49",
            "/api/orders/50",
            "/api/orders/51",
            "/api/orders/52",
            "/api/orders/53",
            "/api/orders/54",
            "/api/orders/60",
            "/api/orders/61",
            "/api/orders/62",
            "/api/orders/63",
            "/api/orders/64",
            "/api/orders/72",
            "/api/orders/73",
            "/api/orders/74",
            "/api/orders/75",
            "/api/orders/76",
            "/api/orders/77",
            "/api/orders/78",
            "/api/orders/79",
            "/api/orders/80",
            "/api/orders/81",
            "/api/orders/82",
            "/api/orders/83",
            "/api/orders/84",
            "/api/orders/85",
            "/api/orders/86",
            "/api/orders/87",
            "/api/orders/88",
            "/api/orders/89",
            "/api/orders/90",
            "/api/orders/93",
            "/api/orders/94",
            "/api/orders/95",
            "/api/orders/102",
            "/api/orders/103",
            "/api/orders/104",
            "/api/orders/115",
            "/api/orders/116",
            "/api/orders/117",
            "/api/orders/118",
            "/api/orders/119",
            "/api/orders/120",
            "/api/orders/121",
            "/api/orders/122",
            "/api/orders/123",
            "/api/orders/124",
            "/api/orders/125",
            "/api/orders/126",
            "/api/orders/127",
            "/api/orders/128",
            "/api/orders/129",
            "/api/orders/130",
            "/api/orders/147",
            "/api/orders/148",
            "/api/orders/154",
            "/api/orders/155",
            "/api/orders/156",
            "/api/orders/157",
            "/api/orders/158",
            "/api/orders/159",
            "/api/orders/160",
            "/api/orders/162",
            "/api/orders/163",
            "/api/orders/170",
            "/api/orders/185",
            "/api/orders/186",
            "/api/orders/187",
            "/api/orders/188",
            "/api/orders/189",
            "/api/orders/200",
            "/api/orders/201",
            "/api/orders/215",
            "/api/orders/216",
            "/api/orders/218",
            "/api/orders/236",
            "/api/orders/237",
            "/api/orders/238",
            "/api/orders/248",
            "/api/orders/249",
            "/api/orders/250",
            "/api/orders/251",
            "/api/orders/256",
            "/api/orders/257",
            "/api/orders/265",
            "/api/orders/266",
            "/api/orders/267",
            "/api/orders/277",
            "/api/orders/278",
            "/api/orders/306",
            "/api/orders/307",
            "/api/orders/308",
            "/api/orders/317",
            "/api/orders/318",
            "/api/orders/319",
            "/api/orders/349",
            "/api/orders/350",
            "/api/orders/351",
            "/api/orders/352",
            "/api/orders/353",
            "/api/orders/354",
            "/api/orders/355",
            "/api/orders/356",
            "/api/orders/357",
            "/api/orders/368",
            "/api/orders/382",
            "/api/orders/383",
            "/api/orders/384",
            "/api/orders/385",
            "/api/orders/386",
            "/api/orders/387",
            "/api/orders/388",
            "/api/orders/417",
            "/api/orders/418",
            "/api/orders/419",
            "/api/orders/420",
            "/api/orders/421"
        ],
        "createdAt": "2024-02-23T15:46:09-10:00",
        "updatedAt": "2024-04-10T12:17:13-10:00",
        "userIdentifier": "bertille"
    },
    "history": [
        {
            "@id": "/api/order_histories/1602",
            "@type": "OrderHistory",
            "status": "created",
            "createdAt": "2024-06-03T11:36:23-10:00"
        },
        {
            "@id": "/api/order_histories/1609",
            "@type": "OrderHistory",
            "status": "operin",
            "createdAt": "2024-06-03T12:09:21-10:00"
        },
        {
            "@id": "/api/order_histories/1607",
            "@type": "OrderHistory",
            "status": "ready_for_delivery",
            "createdAt": "2024-06-03T11:36:24-10:00"
        }
    ],
    "products": [
        {
            "name": "Emmental Râpé Fondant PRÉSIDENT - le sachet de 70g",
            "quantity": 1,
            "price": 1
        }
    ],
    "multiOrderCode": "WPXSFFDE",
    "createdAt": "2024-06-03T11:36:23-10:00",
    "updatedAt": "2024-06-03T12:09:21-10:00",
    "cleveronSlotId": 3
},
{
    "@id": "/api/orders/421",
    "@type": "Order",
    "id": 421,
    "barcode": "CFP-177-BUX2",
    "status": "operin",
    "bookingSlot": {
        "@id": "/api/booking_slots/2",
        "@type": "BookingSlot",
        "slot": {
            "@id": "/api/slots/2",
            "@type": "Slot",
            "temperatureZone": {
                "@id": "/api/temperature_zones/2",
                "@type": "TemperatureZone",
                "locker": {
                    "@id": "/api/lockers/1",
                    "@type": "Locker",
                    "shortLocation": "Punaauia 2"
                },
                "name": "Ambiant"
            },
            "size": "M"
        }
    },
    "shippedBy": {
        "@id": "/api/users/5",
        "@type": "User",
        "id": 5,
        "email": "bertille.johnston@lwane.com",
        "username": "bertille",
        "roles": [
            "ROLE_SHIPPER",
            "ROLE_USER"
        ],
        "firstName": "Bertille",
        "lastName": "Johnston",
        "phone": "89571833",
        "apmAccessCode": 697978,
        "password": "$2y$13$Jo4B50MwjYvSps5tjbQwyOnPnfd7hwqzI.IJV/4/ESO0mFOo8UI7.",
        "enabled": true,
        "ownerships": [],
        "memberships": [
            "/api/companies/1"
        ],
        "deliveries": [
            "/api/orders/9",
            "/api/orders/10",
            "/api/orders/11",
            "/api/orders/12",
            "/api/orders/13",
            "/api/orders/14",
            "/api/orders/15",
            "/api/orders/16",
            "/api/orders/17",
            "/api/orders/18",
            "/api/orders/19",
            "/api/orders/20",
            "/api/orders/21",
            "/api/orders/22",
            "/api/orders/23",
            "/api/orders/24",
            "/api/orders/25",
            "/api/orders/27",
            "/api/orders/30",
            "/api/orders/31",
            "/api/orders/32",
            "/api/orders/33",
            "/api/orders/34",
            "/api/orders/35",
            "/api/orders/36",
            "/api/orders/37",
            "/api/orders/38",
            "/api/orders/39",
            "/api/orders/40",
            "/api/orders/41",
            "/api/orders/42",
            "/api/orders/43",
            "/api/orders/44",
            "/api/orders/45",
            "/api/orders/46",
            "/api/orders/47",
            "/api/orders/48",
            "/api/orders/49",
            "/api/orders/50",
            "/api/orders/51",
            "/api/orders/52",
            "/api/orders/53",
            "/api/orders/54",
            "/api/orders/60",
            "/api/orders/61",
            "/api/orders/62",
            "/api/orders/63",
            "/api/orders/64",
            "/api/orders/72",
            "/api/orders/73",
            "/api/orders/74",
            "/api/orders/75",
            "/api/orders/76",
            "/api/orders/77",
            "/api/orders/78",
            "/api/orders/79",
            "/api/orders/80",
            "/api/orders/81",
            "/api/orders/82",
            "/api/orders/83",
            "/api/orders/84",
            "/api/orders/85",
            "/api/orders/86",
            "/api/orders/87",
            "/api/orders/88",
            "/api/orders/89",
            "/api/orders/90",
            "/api/orders/93",
            "/api/orders/94",
            "/api/orders/95",
            "/api/orders/102",
            "/api/orders/103",
            "/api/orders/104",
            "/api/orders/115",
            "/api/orders/116",
            "/api/orders/117",
            "/api/orders/118",
            "/api/orders/119",
            "/api/orders/120",
            "/api/orders/121",
            "/api/orders/122",
            "/api/orders/123",
            "/api/orders/124",
            "/api/orders/125",
            "/api/orders/126",
            "/api/orders/127",
            "/api/orders/128",
            "/api/orders/129",
            "/api/orders/130",
            "/api/orders/147",
            "/api/orders/148",
            "/api/orders/154",
            "/api/orders/155",
            "/api/orders/156",
            "/api/orders/157",
            "/api/orders/158",
            "/api/orders/159",
            "/api/orders/160",
            "/api/orders/162",
            "/api/orders/163",
            "/api/orders/170",
            "/api/orders/185",
            "/api/orders/186",
            "/api/orders/187",
            "/api/orders/188",
            "/api/orders/189",
            "/api/orders/200",
            "/api/orders/201",
            "/api/orders/215",
            "/api/orders/216",
            "/api/orders/218",
            "/api/orders/236",
            "/api/orders/237",
            "/api/orders/238",
            "/api/orders/248",
            "/api/orders/249",
            "/api/orders/250",
            "/api/orders/251",
            "/api/orders/256",
            "/api/orders/257",
            "/api/orders/265",
            "/api/orders/266",
            "/api/orders/267",
            "/api/orders/277",
            "/api/orders/278",
            "/api/orders/306",
            "/api/orders/307",
            "/api/orders/308",
            "/api/orders/317",
            "/api/orders/318",
            "/api/orders/319",
            "/api/orders/349",
            "/api/orders/350",
            "/api/orders/351",
            "/api/orders/352",
            "/api/orders/353",
            "/api/orders/354",
            "/api/orders/355",
            "/api/orders/356",
            "/api/orders/357",
            "/api/orders/368",
            "/api/orders/382",
            "/api/orders/383",
            "/api/orders/384",
            "/api/orders/385",
            "/api/orders/386",
            "/api/orders/387",
            "/api/orders/388",
            "/api/orders/417",
            "/api/orders/418",
            "/api/orders/419",
            "/api/orders/420",
            "/api/orders/421"
        ],
        "createdAt": "2024-02-23T15:46:09-10:00",
        "updatedAt": "2024-04-10T12:17:13-10:00",
        "userIdentifier": "bertille"
    },
    "history": [
        {
            "@id": "/api/order_histories/1603",
            "@type": "OrderHistory",
            "status": "created",
            "createdAt": "2024-06-03T11:36:25-10:00"
        },
        {
            "@id": "/api/order_histories/1610",
            "@type": "OrderHistory",
            "status": "operin",
            "createdAt": "2024-06-03T12:10:50-10:00"
        },
        {
            "@id": "/api/order_histories/1608",
            "@type": "OrderHistory",
            "status": "ready_for_delivery",
            "createdAt": "2024-06-03T11:36:25-10:00"
        }
    ],
    "products": [
        {
            "name": "Muesli Énergie MARKAL - le paquet de 500g",
            "quantity": 1,
            "price": 1
        },
        {
            "name": "Compote Pomme Nature ANDROS - le bocal de 750g",
            "quantity": 1,
            "price": 1
        },
        {
            "name": "Tablette de Chocolat Noir Caramel Fleur de Sel EXCELLENCE LINDT - la tablette de 100g",
            "quantity": 1,
            "price": 1
        },
        {
            "name": "Café Colombie CARREFOUR SENSATION - le paquet de 250g",
            "quantity": 1,
            "price": 1
        },
        {
            "name": "Gel Douche Monoï CARREFOUR SOFT - le flacon de 250mL",
            "quantity": 1,
            "price": 1
        },
        {
            "name": "Savon Mousse Vanille DETTOL - le flacon de 250mL",
            "quantity": 1,
            "price": 1
        },
        {
            "name": "Papier toilette DOUCEUR NATURE - les 6 rouleaux",
            "quantity": 1,
            "price": 1
        },
        {
            "name": "Piles Alcalines AA/LR6 MN1500 PLUS DURACELL - le lot de 4 piles",
            "quantity": 1,
            "price": 1
        },
        {
            "name": "Piles Alcalines AAA/LR3 MN2400 OPTIMUM DURACELL - le lot de 4 piles",
            "quantity": 1,
            "price": 1
        }
    ],
    "multiOrderCode": "WPXSFFDE",
    "createdAt": "2024-06-03T11:36:25-10:00",
    "updatedAt": "2024-06-03T12:10:50-10:00",
    "cleveronSlotId": 9
},
  {
    "@id": "/api/orders/420",
    "@type": "Order",
    "id": 420,
    "barcode": "CFP-177-BUX1",
    "status": "operin",
    "bookingSlot": {
        "@id": "/api/booking_slots/1",
        "@type": "BookingSlot",
        "slot": {
            "@id": "/api/slots/1",
            "@type": "Slot",
            "temperatureZone": {
                "@id": "/api/temperature_zones/1",
                "@type": "TemperatureZone",
                "locker": {
                    "@id": "/api/lockers/1",
                    "@type": "Locker",
                    "shortLocation": "Punaauia 2"
                },
                "name": "Froid positif"
            },
            "size": "M"
        }
    },
    "shippedBy": {
        "@id": "/api/users/5",
        "@type": "User",
        "id": 5,
        "email": "bertille.johnston@lwane.com",
        "username": "bertille",
        "roles": [
            "ROLE_SHIPPER",
            "ROLE_USER"
        ],
        "firstName": "Bertille",
        "lastName": "Johnston",
        "phone": "89571833",
        "apmAccessCode": 697978,
        "password": "$2y$13$Jo4B50MwjYvSps5tjbQwyOnPnfd7hwqzI.IJV/4/ESO0mFOo8UI7.",
        "enabled": true,
        "ownerships": [],
        "memberships": [
            "/api/companies/1"
        ],
        "deliveries": [
            "/api/orders/9",
            "/api/orders/10",
            "/api/orders/11",
            "/api/orders/12",
            "/api/orders/13",
            "/api/orders/14",
            "/api/orders/15",
            "/api/orders/16",
            "/api/orders/17",
            "/api/orders/18",
            "/api/orders/19",
            "/api/orders/20",
            "/api/orders/21",
            "/api/orders/22",
            "/api/orders/23",
            "/api/orders/24",
            "/api/orders/25",
            "/api/orders/27",
            "/api/orders/30",
            "/api/orders/31",
            "/api/orders/32",
            "/api/orders/33",
            "/api/orders/34",
            "/api/orders/35",
            "/api/orders/36",
            "/api/orders/37",
            "/api/orders/38",
            "/api/orders/39",
            "/api/orders/40",
            "/api/orders/41",
            "/api/orders/42",
            "/api/orders/43",
            "/api/orders/44",
            "/api/orders/45",
            "/api/orders/46",
            "/api/orders/47",
            "/api/orders/48",
            "/api/orders/49",
            "/api/orders/50",
            "/api/orders/51",
            "/api/orders/52",
            "/api/orders/53",
            "/api/orders/54",
            "/api/orders/60",
            "/api/orders/61",
            "/api/orders/62",
            "/api/orders/63",
            "/api/orders/64",
            "/api/orders/72",
            "/api/orders/73",
            "/api/orders/74",
            "/api/orders/75",
            "/api/orders/76",
            "/api/orders/77",
            "/api/orders/78",
            "/api/orders/79",
            "/api/orders/80",
            "/api/orders/81",
            "/api/orders/82",
            "/api/orders/83",
            "/api/orders/84",
            "/api/orders/85",
            "/api/orders/86",
            "/api/orders/87",
            "/api/orders/88",
            "/api/orders/89",
            "/api/orders/90",
            "/api/orders/93",
            "/api/orders/94",
            "/api/orders/95",
            "/api/orders/102",
            "/api/orders/103",
            "/api/orders/104",
            "/api/orders/115",
            "/api/orders/116",
            "/api/orders/117",
            "/api/orders/118",
            "/api/orders/119",
            "/api/orders/120",
            "/api/orders/121",
            "/api/orders/122",
            "/api/orders/123",
            "/api/orders/124",
            "/api/orders/125",
            "/api/orders/126",
            "/api/orders/127",
            "/api/orders/128",
            "/api/orders/129",
            "/api/orders/130",
            "/api/orders/147",
            "/api/orders/148",
            "/api/orders/154",
            "/api/orders/155",
            "/api/orders/156",
            "/api/orders/157",
            "/api/orders/158",
            "/api/orders/159",
            "/api/orders/160",
            "/api/orders/162",
            "/api/orders/163",
            "/api/orders/170",
            "/api/orders/185",
            "/api/orders/186",
            "/api/orders/187",
            "/api/orders/188",
            "/api/orders/189",
            "/api/orders/200",
            "/api/orders/201",
            "/api/orders/215",
            "/api/orders/216",
            "/api/orders/218",
            "/api/orders/236",
            "/api/orders/237",
            "/api/orders/238",
            "/api/orders/248",
            "/api/orders/249",
            "/api/orders/250",
            "/api/orders/251",
            "/api/orders/256",
            "/api/orders/257",
            "/api/orders/265",
            "/api/orders/266",
            "/api/orders/267",
            "/api/orders/277",
            "/api/orders/278",
            "/api/orders/306",
            "/api/orders/307",
            "/api/orders/308",
            "/api/orders/317",
            "/api/orders/318",
            "/api/orders/319",
            "/api/orders/349",
            "/api/orders/350",
            "/api/orders/351",
            "/api/orders/352",
            "/api/orders/353",
            "/api/orders/354",
            "/api/orders/355",
            "/api/orders/356",
            "/api/orders/357",
            "/api/orders/368",
            "/api/orders/382",
            "/api/orders/383",
            "/api/orders/384",
            "/api/orders/385",
            "/api/orders/386",
            "/api/orders/387",
            "/api/orders/388",
            "/api/orders/417",
            "/api/orders/418",
            "/api/orders/419",
            "/api/orders/420",
            "/api/orders/421"
        ],
        "createdAt": "2024-02-23T15:46:09-10:00",
        "updatedAt": "2024-04-10T12:17:13-10:00",
        "userIdentifier": "bertille"
    },
    "history": [
        {
            "@id": "/api/order_histories/1602",
            "@type": "OrderHistory",
            "status": "created",
            "createdAt": "2024-06-03T11:36:23-10:00"
        },
        {
            "@id": "/api/order_histories/1609",
            "@type": "OrderHistory",
            "status": "operin",
            "createdAt": "2024-06-03T12:09:21-10:00"
        },
        {
            "@id": "/api/order_histories/1607",
            "@type": "OrderHistory",
            "status": "ready_for_delivery",
            "createdAt": "2024-06-03T11:36:24-10:00"
        }
    ],
    "products": [
        {
            "name": "Emmental Râpé Fondant PRÉSIDENT - le sachet de 70g",
            "quantity": 1,
            "price": 1
        }
    ],
    "multiOrderCode": "WPXSFFDS",
    "createdAt": "2024-06-03T11:36:23-10:00",
    "updatedAt": "2024-06-03T12:09:21-10:00",
    "cleveronSlotId": 3
},
{
    "@id": "/api/orders/421",
    "@type": "Order",
    "id": 421,
    "barcode": "CFP-177-BUX2",
    "status": "operin",
    "bookingSlot": {
        "@id": "/api/booking_slots/2",
        "@type": "BookingSlot",
        "slot": {
            "@id": "/api/slots/2",
            "@type": "Slot",
            "temperatureZone": {
                "@id": "/api/temperature_zones/2",
                "@type": "TemperatureZone",
                "locker": {
                    "@id": "/api/lockers/1",
                    "@type": "Locker",
                    "shortLocation": "Punaauia 2"
                },
                "name": "Ambiant"
            },
            "size": "M"
        }
    },
    "shippedBy": {
        "@id": "/api/users/5",
        "@type": "User",
        "id": 5,
        "email": "bertille.johnston@lwane.com",
        "username": "bertille",
        "roles": [
            "ROLE_SHIPPER",
            "ROLE_USER"
        ],
        "firstName": "Bertille",
        "lastName": "Johnston",
        "phone": "89571833",
        "apmAccessCode": 697978,
        "password": "$2y$13$Jo4B50MwjYvSps5tjbQwyOnPnfd7hwqzI.IJV/4/ESO0mFOo8UI7.",
        "enabled": true,
        "ownerships": [],
        "memberships": [
            "/api/companies/1"
        ],
        "deliveries": [
            "/api/orders/9",
            "/api/orders/10",
            "/api/orders/11",
            "/api/orders/12",
            "/api/orders/13",
            "/api/orders/14",
            "/api/orders/15",
            "/api/orders/16",
            "/api/orders/17",
            "/api/orders/18",
            "/api/orders/19",
            "/api/orders/20",
            "/api/orders/21",
            "/api/orders/22",
            "/api/orders/23",
            "/api/orders/24",
            "/api/orders/25",
            "/api/orders/27",
            "/api/orders/30",
            "/api/orders/31",
            "/api/orders/32",
            "/api/orders/33",
            "/api/orders/34",
            "/api/orders/35",
            "/api/orders/36",
            "/api/orders/37",
            "/api/orders/38",
            "/api/orders/39",
            "/api/orders/40",
            "/api/orders/41",
            "/api/orders/42",
            "/api/orders/43",
            "/api/orders/44",
            "/api/orders/45",
            "/api/orders/46",
            "/api/orders/47",
            "/api/orders/48",
            "/api/orders/49",
            "/api/orders/50",
            "/api/orders/51",
            "/api/orders/52",
            "/api/orders/53",
            "/api/orders/54",
            "/api/orders/60",
            "/api/orders/61",
            "/api/orders/62",
            "/api/orders/63",
            "/api/orders/64",
            "/api/orders/72",
            "/api/orders/73",
            "/api/orders/74",
            "/api/orders/75",
            "/api/orders/76",
            "/api/orders/77",
            "/api/orders/78",
            "/api/orders/79",
            "/api/orders/80",
            "/api/orders/81",
            "/api/orders/82",
            "/api/orders/83",
            "/api/orders/84",
            "/api/orders/85",
            "/api/orders/86",
            "/api/orders/87",
            "/api/orders/88",
            "/api/orders/89",
            "/api/orders/90",
            "/api/orders/93",
            "/api/orders/94",
            "/api/orders/95",
            "/api/orders/102",
            "/api/orders/103",
            "/api/orders/104",
            "/api/orders/115",
            "/api/orders/116",
            "/api/orders/117",
            "/api/orders/118",
            "/api/orders/119",
            "/api/orders/120",
            "/api/orders/121",
            "/api/orders/122",
            "/api/orders/123",
            "/api/orders/124",
            "/api/orders/125",
            "/api/orders/126",
            "/api/orders/127",
            "/api/orders/128",
            "/api/orders/129",
            "/api/orders/130",
            "/api/orders/147",
            "/api/orders/148",
            "/api/orders/154",
            "/api/orders/155",
            "/api/orders/156",
            "/api/orders/157",
            "/api/orders/158",
            "/api/orders/159",
            "/api/orders/160",
            "/api/orders/162",
            "/api/orders/163",
            "/api/orders/170",
            "/api/orders/185",
            "/api/orders/186",
            "/api/orders/187",
            "/api/orders/188",
            "/api/orders/189",
            "/api/orders/200",
            "/api/orders/201",
            "/api/orders/215",
            "/api/orders/216",
            "/api/orders/218",
            "/api/orders/236",
            "/api/orders/237",
            "/api/orders/238",
            "/api/orders/248",
            "/api/orders/249",
            "/api/orders/250",
            "/api/orders/251",
            "/api/orders/256",
            "/api/orders/257",
            "/api/orders/265",
            "/api/orders/266",
            "/api/orders/267",
            "/api/orders/277",
            "/api/orders/278",
            "/api/orders/306",
            "/api/orders/307",
            "/api/orders/308",
            "/api/orders/317",
            "/api/orders/318",
            "/api/orders/319",
            "/api/orders/349",
            "/api/orders/350",
            "/api/orders/351",
            "/api/orders/352",
            "/api/orders/353",
            "/api/orders/354",
            "/api/orders/355",
            "/api/orders/356",
            "/api/orders/357",
            "/api/orders/368",
            "/api/orders/382",
            "/api/orders/383",
            "/api/orders/384",
            "/api/orders/385",
            "/api/orders/386",
            "/api/orders/387",
            "/api/orders/388",
            "/api/orders/417",
            "/api/orders/418",
            "/api/orders/419",
            "/api/orders/420",
            "/api/orders/421"
        ],
        "createdAt": "2024-02-23T15:46:09-10:00",
        "updatedAt": "2024-04-10T12:17:13-10:00",
        "userIdentifier": "bertille"
    },
    "history": [
        {
            "@id": "/api/order_histories/1603",
            "@type": "OrderHistory",
            "status": "created",
            "createdAt": "2024-06-03T11:36:25-10:00"
        },
        {
            "@id": "/api/order_histories/1610",
            "@type": "OrderHistory",
            "status": "operin",
            "createdAt": "2024-06-03T12:10:50-10:00"
        },
        {
            "@id": "/api/order_histories/1608",
            "@type": "OrderHistory",
            "status": "ready_for_delivery",
            "createdAt": "2024-06-03T11:36:25-10:00"
        }
    ],
    "products": [
        {
            "name": "Muesli Énergie MARKAL - le paquet de 500g",
            "quantity": 1,
            "price": 1
        },
        {
            "name": "Compote Pomme Nature ANDROS - le bocal de 750g",
            "quantity": 1,
            "price": 1
        },
        {
            "name": "Tablette de Chocolat Noir Caramel Fleur de Sel EXCELLENCE LINDT - la tablette de 100g",
            "quantity": 1,
            "price": 1
        },
        {
            "name": "Café Colombie CARREFOUR SENSATION - le paquet de 250g",
            "quantity": 1,
            "price": 1
        },
        {
            "name": "Gel Douche Monoï CARREFOUR SOFT - le flacon de 250mL",
            "quantity": 1,
            "price": 1
        },
        {
            "name": "Savon Mousse Vanille DETTOL - le flacon de 250mL",
            "quantity": 1,
            "price": 1
        },
        {
            "name": "Papier toilette DOUCEUR NATURE - les 6 rouleaux",
            "quantity": 1,
            "price": 1
        },
        {
            "name": "Piles Alcalines AA/LR6 MN1500 PLUS DURACELL - le lot de 4 piles",
            "quantity": 1,
            "price": 1
        },
        {
            "name": "Piles Alcalines AAA/LR3 MN2400 OPTIMUM DURACELL - le lot de 4 piles",
            "quantity": 1,
            "price": 1
        }
    ],
    "multiOrderCode": "WPXSFFDS",
    "createdAt": "2024-06-03T11:36:25-10:00",
    "updatedAt": "2024-06-03T12:10:50-10:00",
    "cleveronSlotId": 9
},
  {
    "@id": "/api/orders/420",
    "@type": "Order",
    "id": 420,
    "barcode": "CFP-177-BUX1",
    "status": "operin",
    "bookingSlot": {
        "@id": "/api/booking_slots/1",
        "@type": "BookingSlot",
        "slot": {
            "@id": "/api/slots/1",
            "@type": "Slot",
            "temperatureZone": {
                "@id": "/api/temperature_zones/1",
                "@type": "TemperatureZone",
                "locker": {
                    "@id": "/api/lockers/1",
                    "@type": "Locker",
                    "shortLocation": "Punaauia 2"
                },
                "name": "Froid positif"
            },
            "size": "M"
        }
    },
    "shippedBy": {
        "@id": "/api/users/5",
        "@type": "User",
        "id": 5,
        "email": "bertille.johnston@lwane.com",
        "username": "bertille",
        "roles": [
            "ROLE_SHIPPER",
            "ROLE_USER"
        ],
        "firstName": "Bertille",
        "lastName": "Johnston",
        "phone": "89571833",
        "apmAccessCode": 697978,
        "password": "$2y$13$Jo4B50MwjYvSps5tjbQwyOnPnfd7hwqzI.IJV/4/ESO0mFOo8UI7.",
        "enabled": true,
        "ownerships": [],
        "memberships": [
            "/api/companies/1"
        ],
        "deliveries": [
            "/api/orders/9",
            "/api/orders/10",
            "/api/orders/11",
            "/api/orders/12",
            "/api/orders/13",
            "/api/orders/14",
            "/api/orders/15",
            "/api/orders/16",
            "/api/orders/17",
            "/api/orders/18",
            "/api/orders/19",
            "/api/orders/20",
            "/api/orders/21",
            "/api/orders/22",
            "/api/orders/23",
            "/api/orders/24",
            "/api/orders/25",
            "/api/orders/27",
            "/api/orders/30",
            "/api/orders/31",
            "/api/orders/32",
            "/api/orders/33",
            "/api/orders/34",
            "/api/orders/35",
            "/api/orders/36",
            "/api/orders/37",
            "/api/orders/38",
            "/api/orders/39",
            "/api/orders/40",
            "/api/orders/41",
            "/api/orders/42",
            "/api/orders/43",
            "/api/orders/44",
            "/api/orders/45",
            "/api/orders/46",
            "/api/orders/47",
            "/api/orders/48",
            "/api/orders/49",
            "/api/orders/50",
            "/api/orders/51",
            "/api/orders/52",
            "/api/orders/53",
            "/api/orders/54",
            "/api/orders/60",
            "/api/orders/61",
            "/api/orders/62",
            "/api/orders/63",
            "/api/orders/64",
            "/api/orders/72",
            "/api/orders/73",
            "/api/orders/74",
            "/api/orders/75",
            "/api/orders/76",
            "/api/orders/77",
            "/api/orders/78",
            "/api/orders/79",
            "/api/orders/80",
            "/api/orders/81",
            "/api/orders/82",
            "/api/orders/83",
            "/api/orders/84",
            "/api/orders/85",
            "/api/orders/86",
            "/api/orders/87",
            "/api/orders/88",
            "/api/orders/89",
            "/api/orders/90",
            "/api/orders/93",
            "/api/orders/94",
            "/api/orders/95",
            "/api/orders/102",
            "/api/orders/103",
            "/api/orders/104",
            "/api/orders/115",
            "/api/orders/116",
            "/api/orders/117",
            "/api/orders/118",
            "/api/orders/119",
            "/api/orders/120",
            "/api/orders/121",
            "/api/orders/122",
            "/api/orders/123",
            "/api/orders/124",
            "/api/orders/125",
            "/api/orders/126",
            "/api/orders/127",
            "/api/orders/128",
            "/api/orders/129",
            "/api/orders/130",
            "/api/orders/147",
            "/api/orders/148",
            "/api/orders/154",
            "/api/orders/155",
            "/api/orders/156",
            "/api/orders/157",
            "/api/orders/158",
            "/api/orders/159",
            "/api/orders/160",
            "/api/orders/162",
            "/api/orders/163",
            "/api/orders/170",
            "/api/orders/185",
            "/api/orders/186",
            "/api/orders/187",
            "/api/orders/188",
            "/api/orders/189",
            "/api/orders/200",
            "/api/orders/201",
            "/api/orders/215",
            "/api/orders/216",
            "/api/orders/218",
            "/api/orders/236",
            "/api/orders/237",
            "/api/orders/238",
            "/api/orders/248",
            "/api/orders/249",
            "/api/orders/250",
            "/api/orders/251",
            "/api/orders/256",
            "/api/orders/257",
            "/api/orders/265",
            "/api/orders/266",
            "/api/orders/267",
            "/api/orders/277",
            "/api/orders/278",
            "/api/orders/306",
            "/api/orders/307",
            "/api/orders/308",
            "/api/orders/317",
            "/api/orders/318",
            "/api/orders/319",
            "/api/orders/349",
            "/api/orders/350",
            "/api/orders/351",
            "/api/orders/352",
            "/api/orders/353",
            "/api/orders/354",
            "/api/orders/355",
            "/api/orders/356",
            "/api/orders/357",
            "/api/orders/368",
            "/api/orders/382",
            "/api/orders/383",
            "/api/orders/384",
            "/api/orders/385",
            "/api/orders/386",
            "/api/orders/387",
            "/api/orders/388",
            "/api/orders/417",
            "/api/orders/418",
            "/api/orders/419",
            "/api/orders/420",
            "/api/orders/421"
        ],
        "createdAt": "2024-02-23T15:46:09-10:00",
        "updatedAt": "2024-04-10T12:17:13-10:00",
        "userIdentifier": "bertille"
    },
    "history": [
        {
            "@id": "/api/order_histories/1602",
            "@type": "OrderHistory",
            "status": "created",
            "createdAt": "2024-06-03T11:36:23-10:00"
        },
        {
            "@id": "/api/order_histories/1609",
            "@type": "OrderHistory",
            "status": "operin",
            "createdAt": "2024-06-03T12:09:21-10:00"
        },
        {
            "@id": "/api/order_histories/1607",
            "@type": "OrderHistory",
            "status": "ready_for_delivery",
            "createdAt": "2024-06-03T11:36:24-10:00"
        }
    ],
    "products": [
        {
            "name": "Emmental Râpé Fondant PRÉSIDENT - le sachet de 70g",
            "quantity": 1,
            "price": 1
        }
    ],
    "multiOrderCode": "ZPXSFFDS",
    "createdAt": "2024-06-03T11:36:23-10:00",
    "updatedAt": "2024-06-03T12:09:21-10:00",
    "cleveronSlotId": 3
},
{
    "@id": "/api/orders/421",
    "@type": "Order",
    "id": 421,
    "barcode": "CFP-177-BUX2",
    "status": "operin",
    "bookingSlot": {
        "@id": "/api/booking_slots/2",
        "@type": "BookingSlot",
        "slot": {
            "@id": "/api/slots/2",
            "@type": "Slot",
            "temperatureZone": {
                "@id": "/api/temperature_zones/2",
                "@type": "TemperatureZone",
                "locker": {
                    "@id": "/api/lockers/1",
                    "@type": "Locker",
                    "shortLocation": "Punaauia 2"
                },
                "name": "Ambiant"
            },
            "size": "M"
        }
    },
    "shippedBy": {
        "@id": "/api/users/5",
        "@type": "User",
        "id": 5,
        "email": "bertille.johnston@lwane.com",
        "username": "bertille",
        "roles": [
            "ROLE_SHIPPER",
            "ROLE_USER"
        ],
        "firstName": "Bertille",
        "lastName": "Johnston",
        "phone": "89571833",
        "apmAccessCode": 697978,
        "password": "$2y$13$Jo4B50MwjYvSps5tjbQwyOnPnfd7hwqzI.IJV/4/ESO0mFOo8UI7.",
        "enabled": true,
        "ownerships": [],
        "memberships": [
            "/api/companies/1"
        ],
        "deliveries": [
            "/api/orders/9",
            "/api/orders/10",
            "/api/orders/11",
            "/api/orders/12",
            "/api/orders/13",
            "/api/orders/14",
            "/api/orders/15",
            "/api/orders/16",
            "/api/orders/17",
            "/api/orders/18",
            "/api/orders/19",
            "/api/orders/20",
            "/api/orders/21",
            "/api/orders/22",
            "/api/orders/23",
            "/api/orders/24",
            "/api/orders/25",
            "/api/orders/27",
            "/api/orders/30",
            "/api/orders/31",
            "/api/orders/32",
            "/api/orders/33",
            "/api/orders/34",
            "/api/orders/35",
            "/api/orders/36",
            "/api/orders/37",
            "/api/orders/38",
            "/api/orders/39",
            "/api/orders/40",
            "/api/orders/41",
            "/api/orders/42",
            "/api/orders/43",
            "/api/orders/44",
            "/api/orders/45",
            "/api/orders/46",
            "/api/orders/47",
            "/api/orders/48",
            "/api/orders/49",
            "/api/orders/50",
            "/api/orders/51",
            "/api/orders/52",
            "/api/orders/53",
            "/api/orders/54",
            "/api/orders/60",
            "/api/orders/61",
            "/api/orders/62",
            "/api/orders/63",
            "/api/orders/64",
            "/api/orders/72",
            "/api/orders/73",
            "/api/orders/74",
            "/api/orders/75",
            "/api/orders/76",
            "/api/orders/77",
            "/api/orders/78",
            "/api/orders/79",
            "/api/orders/80",
            "/api/orders/81",
            "/api/orders/82",
            "/api/orders/83",
            "/api/orders/84",
            "/api/orders/85",
            "/api/orders/86",
            "/api/orders/87",
            "/api/orders/88",
            "/api/orders/89",
            "/api/orders/90",
            "/api/orders/93",
            "/api/orders/94",
            "/api/orders/95",
            "/api/orders/102",
            "/api/orders/103",
            "/api/orders/104",
            "/api/orders/115",
            "/api/orders/116",
            "/api/orders/117",
            "/api/orders/118",
            "/api/orders/119",
            "/api/orders/120",
            "/api/orders/121",
            "/api/orders/122",
            "/api/orders/123",
            "/api/orders/124",
            "/api/orders/125",
            "/api/orders/126",
            "/api/orders/127",
            "/api/orders/128",
            "/api/orders/129",
            "/api/orders/130",
            "/api/orders/147",
            "/api/orders/148",
            "/api/orders/154",
            "/api/orders/155",
            "/api/orders/156",
            "/api/orders/157",
            "/api/orders/158",
            "/api/orders/159",
            "/api/orders/160",
            "/api/orders/162",
            "/api/orders/163",
            "/api/orders/170",
            "/api/orders/185",
            "/api/orders/186",
            "/api/orders/187",
            "/api/orders/188",
            "/api/orders/189",
            "/api/orders/200",
            "/api/orders/201",
            "/api/orders/215",
            "/api/orders/216",
            "/api/orders/218",
            "/api/orders/236",
            "/api/orders/237",
            "/api/orders/238",
            "/api/orders/248",
            "/api/orders/249",
            "/api/orders/250",
            "/api/orders/251",
            "/api/orders/256",
            "/api/orders/257",
            "/api/orders/265",
            "/api/orders/266",
            "/api/orders/267",
            "/api/orders/277",
            "/api/orders/278",
            "/api/orders/306",
            "/api/orders/307",
            "/api/orders/308",
            "/api/orders/317",
            "/api/orders/318",
            "/api/orders/319",
            "/api/orders/349",
            "/api/orders/350",
            "/api/orders/351",
            "/api/orders/352",
            "/api/orders/353",
            "/api/orders/354",
            "/api/orders/355",
            "/api/orders/356",
            "/api/orders/357",
            "/api/orders/368",
            "/api/orders/382",
            "/api/orders/383",
            "/api/orders/384",
            "/api/orders/385",
            "/api/orders/386",
            "/api/orders/387",
            "/api/orders/388",
            "/api/orders/417",
            "/api/orders/418",
            "/api/orders/419",
            "/api/orders/420",
            "/api/orders/421"
        ],
        "createdAt": "2024-02-23T15:46:09-10:00",
        "updatedAt": "2024-04-10T12:17:13-10:00",
        "userIdentifier": "bertille"
    },
    "history": [
        {
            "@id": "/api/order_histories/1603",
            "@type": "OrderHistory",
            "status": "created",
            "createdAt": "2024-06-03T11:36:25-10:00"
        },
        {
            "@id": "/api/order_histories/1610",
            "@type": "OrderHistory",
            "status": "operin",
            "createdAt": "2024-06-03T12:10:50-10:00"
        },
        {
            "@id": "/api/order_histories/1608",
            "@type": "OrderHistory",
            "status": "ready_for_delivery",
            "createdAt": "2024-06-03T11:36:25-10:00"
        }
    ],
    "products": [
        {
            "name": "Muesli Énergie MARKAL - le paquet de 500g",
            "quantity": 1,
            "price": 1
        },
        {
            "name": "Compote Pomme Nature ANDROS - le bocal de 750g",
            "quantity": 1,
            "price": 1
        },
        {
            "name": "Tablette de Chocolat Noir Caramel Fleur de Sel EXCELLENCE LINDT - la tablette de 100g",
            "quantity": 1,
            "price": 1
        },
        {
            "name": "Café Colombie CARREFOUR SENSATION - le paquet de 250g",
            "quantity": 1,
            "price": 1
        },
        {
            "name": "Gel Douche Monoï CARREFOUR SOFT - le flacon de 250mL",
            "quantity": 1,
            "price": 1
        },
        {
            "name": "Savon Mousse Vanille DETTOL - le flacon de 250mL",
            "quantity": 1,
            "price": 1
        },
        {
            "name": "Papier toilette DOUCEUR NATURE - les 6 rouleaux",
            "quantity": 1,
            "price": 1
        },
        {
            "name": "Piles Alcalines AA/LR6 MN1500 PLUS DURACELL - le lot de 4 piles",
            "quantity": 1,
            "price": 1
        },
        {
            "name": "Piles Alcalines AAA/LR3 MN2400 OPTIMUM DURACELL - le lot de 4 piles",
            "quantity": 1,
            "price": 1
        }
    ],
    "multiOrderCode": "ZPXSFFDS",
    "createdAt": "2024-06-03T11:36:25-10:00",
    "updatedAt": "2024-06-03T12:10:50-10:00",
    "cleveronSlotId": 9
}
]