import { Col, Placeholder, Row } from "react-bootstrap";

export function DashboardItems() {
  return (
    <Placeholder as="div" animation="glow" className=" mb-3">
      <Placeholder xs={12} className="dash-placeholder rounded m-auto">
        <Row className="h-100 m-auto">
          <Col xs={2} className="m-auto pe-0 ">
            <Placeholder
              xs={10}
              as="div"
              style={{ height: "40px", width: "40px" }}
              className="bg-secondary rounded-circle px-0 my-0"
            />
          </Col>
          <Col xs={3} className="m-auto text-start">
            <Placeholder xs={12} as="div" className="bg-secondary rounded" />
          </Col>
          <Col xs={4} className="m-auto ps-0">
            <Placeholder
              xs={10}
              md={8}
              as="div"
              className="bg-secondary rounded"
            />
            <Placeholder
              xs={10}
              md={8}
              as="div"
              className="bg-secondary rounded"
            />
            <Placeholder
              xs={10}
              md={8}
              as="div"
              className="bg-secondary rounded"
            />
          </Col>
          <Col xs={2} className="m-auto ps-0">
            <Placeholder
              xs={10}
              md={8}
              as="div"
              className="bg-secondary rounded"
            />
            <Placeholder
              xs={10}
              md={8}
              as="div"
              className="bg-secondary rounded"
            />
            <Placeholder
              xs={10}
              md={8}
              as="div"
              className="bg-secondary rounded"
            />
          </Col>
        </Row>
      </Placeholder>
    </Placeholder>
  );
}
export function OrderLoaderItems() {
  return (
    <Placeholder as="div" animation="glow">
      <Placeholder xs={12} as="div" className="py-1 px-2 rounded mb-0 mb-2">
        <Row className="">
          <Col xs={2} className="pe-0">
            <Placeholder
              xs={10}
              as="div"
              style={{ height: "40px", width: "40px" }}
              className="bg-secondary rounded-circle px-0 my-0"
            />
          </Col>
          <Col xs={7} className="m-auto text-start">
            <Placeholder xs={10} as="div" className="bg-secondary rounded" />
          </Col>
          <Col xs={2} className="ps-0">
            <Placeholder
              xs={10}
              as="div"
              style={{ height: "40px", width: "40px" }}
              className="bg-secondary rounded-circle px-0 my-0"
            />
          </Col>
        </Row>
      </Placeholder>
    </Placeholder>
  );
}

export function HistoryLoaderItems() {
  return (
    <Placeholder as="div" animation="glow" className="m-0">
      <Placeholder xs={12} as="div" className="py-1 px-2 rounded mb-0 mb-2">
        <Row className="">
          <Col xs={2} className="pe-0">
            <Placeholder
              xs={10}
              as="div"
              style={{ height: "40px", width: "40px" }}
              className="bg-secondary rounded-circle px-0 my-0"
            />
          </Col>
          <Col xs={7} className="m-auto text-start">
            <Placeholder xs={6} as="div" className="bg-secondary rounded" />
            <Placeholder xs={10} as="div" className="bg-secondary rounded " />
          </Col>
          <Col xs={2} className="m-auto ps-0">
            <Placeholder
              xs={10}
              md={8}
              as="div"
              className="bg-secondary rounded"
            />
          </Col>
        </Row>
      </Placeholder>
    </Placeholder>
  );
}
