import { Container, Row, Col, Button, Modal, Spinner } from "react-bootstrap";
import userDataStore, { UserState } from "../../store/userDataStore";
import { _hasUserRole } from "../../utils/functions";
import OrdersService from "../../service/Orders/OrdersService";
import React from "react";
import { _todayDate, addHolidays } from "../../utils/holidays";
import { useOutletContext } from "react-router-dom";

const ProductTable = ({ productTableProps }: any) => {
  const {
    selectedOrder,
    handleShow,
    newStatus,
    trigger,
    handleShowCustomerService,
    handleShowCancelSlot,
    handleShowOrderEditModal,
  
  } = productTableProps;
  const {
   toggleShowA
  } = useOutletContext<any>();
  const userRoles = userDataStore((state: UserState) => state.roles);
  const token = userDataStore((state: UserState) => state.token);

  const hasSuperAdmin = _hasUserRole(userRoles, "ROLE_SUPER_ADMIN");
  const hasAdmin = _hasUserRole(userRoles, "ROLE_ADMIN");

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  
  const [showResendModal, setShowResendModal] = React.useState<boolean>(false);
  const handleCloseResendModal = () => setShowResendModal(false);
  const handleShowResendModal = () => setShowResendModal(true);
  //Holidays
  const [isDisabled, setIsDisabled] = React.useState(false);
  
  
  
  React.useEffect(() => {
    const holidays = addHolidays(new Date().getFullYear())
    const today = new Date();
    const currentDate = _todayDate();
    
    const dayOfWeek = today.getDay(); // 0 = Sunday, 6 = Saturday
    const hour = today.getHours();
    
    // Check if today is a holiday or a Sunday afternoon
    if (
      ((dayOfWeek === 0 && hour >= 12) || // Sunday afternoon
      (holidays.includes(currentDate) && hour >= 12)) && 
      selectedOrder?.ageRestriction === 18  && 
     ( newStatus === "picked_up" || newStatus === "operin")
    
    ) {
      console.log("holidays");
      setIsDisabled(true);
      toggleShowA()
    } else {
      console.log("not holidays");
      setIsDisabled(false);
    }
  }, []);




  const resendNotification = async (
    id: any,
    token: string
 
  ) => {
    setIsLoading(true);
    try {
      const response = await OrdersService.resend(id, token);
      setIsLoading(false)
      console.log(response);
      console.log("message renvoyé");
      handleCloseResendModal();
    
    } catch (error) {
      console.error(error);
      setIsLoading(false)
    }
  };


  return (
    <Container className="bg-gray rounded mt-3 pb-2 border border-2 border-gray">
      <Row className="py-2">
        <Col xs={3} className="text-secondary">
          <strong>Qté</strong>
        </Col>
        <Col xs={9} className="text-secondary text-start">
          <strong>Désignation</strong>
        </Col>
      </Row>
      {selectedOrder?.products?.map((prod: any, index: any) => (
        <Row
          key={index}
          className={`py-2 ${
            index % 2 === 0 && "table-row"
          } border-1 border-gray border-bottom`}
        >
          <Col xs={3} className="text-dark font-85 border-1 border-dark">
            {prod?.quantity}
          </Col>
          <Col xs={9} className="text-dark font-85 text-start">
            {prod?.name}{" "}
          </Col>
        </Row>
      ))}
      {trigger === "operout" ? (
        (hasSuperAdmin ) && (
          // || hasAdmin
          <Container className="text-end mt-4">
            <Button
              className="bg-yellow rounded-pill border-yellow text-green ms-3 fab3"
              type="button"
              onClick={handleShow}
            >
              <i className="ri-close-circle-fill"></i> Annuler
            </Button>
          </Container>
        )
      ) : trigger === "operin" ? (
        <>
          {(hasSuperAdmin || hasAdmin) && (
          <Container className="text-end mt-4">
            <Button
              className="bg-yellow rounded-pill border-yellow text-green ms-3 fab3"
              type="button"
              onClick={() => {
                handleShowResendModal();
              }}
            >
              <i className="ri-send-plane-fill"></i> Renvoyer notification
            </Button>
          </Container>
           )}
        </>
      ) : (
        trigger === "livraisons" && (
          <>
            {
            // selectedOrder?.bookingSlot?.slot?.temperatureZone.locker.type ===
            //   503 &&
              (hasSuperAdmin || hasAdmin) && (
                <Container className="text-end mt-4">
                  <Button
                    className="bg-dark rounded-pill border-dark text-light ms-3 fab-annulation"
                    type="button"
                    onClick={handleShowOrderEditModal}
                  >
                    <i className="ri-edit-fill"></i> Editer
                  </Button>
                </Container>
              )}
            <Container className="text-end mt-4">
              <Button
                className="bg-yellow rounded-pill border-yellow text-green ms-3 fab3"
                type="button"
                onClick={handleShowCustomerService}
              >
                <i className="ri-close-circle-fill"></i> Service client
              </Button>
            </Container>
          </>
        )
      )}
      {newStatus !== "history" &&
      trigger !== "retraits" &&
      trigger !== "operout" ? (
        <Container className="text-end mt-4">
          <Button
            className="bg-green rounded-pill border-green text-light ms-3 fab"
            type="button"
            onClick={handleShow}
            disabled={isDisabled}
          >
            {newStatus === "picked_up" ? (
              <>
                <i className="ri-check-fill"></i> valider
              </>
            ) : newStatus === "operin" ? (
              <>
                <i className="ri-qr-code-fill"></i> Qrcode
              </>
            ) : (
              newStatus === "operout" && (
                <>
                  <i className="ri-close-circle-fill"></i> Retirer
                </>
              )
            )}
          </Button>
        </Container>
      ) : trigger === "retraits" ? (
        <Container className="text-end mt-4">
          <Button
            className="bg-green rounded-pill border-green text-light ms-3 fab"
            type="button"
            onClick={handleShow}
          >
            <i className="ri-inbox-unarchive-fill"></i> Retirer
          </Button>
        </Container>
      ) : (
        newStatus === "left_for_customer_service" && (
          <Container className="text-end mt-4">
            <Button
              className="bg-green rounded-pill border-green text-light ms-3 fab"
              type="button"
              onClick={handleShowCustomerService}
            >
              <i className="ri-customer-service-fill"></i> Service client
            </Button>
          </Container>
        )
      )}

      <Modal show={showResendModal} onHide={handleCloseResendModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <i className="ri-mail-send-line"></i> Renvoie de notification{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>Etes-vous sur de renvoyer la notification ?</Modal.Body>
        <Modal.Footer>
          <Button
            className="bg-yellow rounded-pill border-yellow text-green"
            type="button"
            onClick={handleCloseResendModal}
          >
            Annuler
          </Button>
          <Button
            className="bg-green rounded-pill border-green text-light"
            type="button"
            onClick={() => resendNotification(selectedOrder?.id, token)}
          >
           {isLoading ? <span><Spinner /> Chargement</span> : <span><i className="ri-send-plane-fill"></i> Valider</span>} 
           
          </Button> 
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default ProductTable;
