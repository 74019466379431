import {  Placeholder } from "react-bootstrap";
import { DashboardItems } from "./ItemsLoader";

const DashBoardLoader = () => {
  return (
    <>
      <Placeholder
        xs={8}
        md={8}
        as="div"
        className="bg-secondary rounded my-2 py-1"
      />
      {Array.from({ length: 5 }).map((_, indx: number) => (
        <DashboardItems key={indx} />
      ))}
    </>
  );
};

export default DashBoardLoader;
