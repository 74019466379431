import React from "react";
import { Container } from "react-bootstrap";
import userDataStore from "../../store/userDataStore";
import { useOutletContext } from "react-router-dom";
import { _getOrdersByStatus } from "../../utils/functions";
import ProductTable from "./ProductTable";
import axios from "axios";
import { orderStatusType } from "../../utils/definitions";
import { ConfirmationCancel, CancelValidation, Confirmation, CustomerServiceValidation } from "./modals/Modals";
import {SelectedOrderInfoBar} from './SelectedOrderInfoBar'


const OperoutDetail = ({ scanPageProps }: any) => {
  ////////////////////
  //context Props & store
  ///////////////////

  const { setIsSelectedOrder } = useOutletContext<any>();

  const {
    selectedOrder,
    setSelectedOrder,
    newStatus,
    setOrderExpired,
    setOrderOperout,
    setSearchOrder,
    trigger,
  } = scanPageProps;

  const dataStore: any = userDataStore((states: any) => states);


  const [isErrorValid, setIsErrorValid] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const [isScroll, setIsScroll] = React.useState(false);

  const [show, setShow] = React.useState<boolean>(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showCancel, setShowCancel] = React.useState<boolean>(false);
  const handleCloseCancel = () => setShowCancel(false);
  const handleShowCancel = () => {
    setShowCancel(true);

    setTimeout(() => {
      setSelectedOrder(null);
      handleCloseCancel();
      setIsSelectedOrder(false);
    }, 2000);
  };

  const [showUpdateStatus, setShowUpdateStatus] =
    React.useState<boolean>(false);
  const handleCloseUpdateStatus = () => setShowUpdateStatus(false);
  const handleShowUpdateStatus = () => {
    setShowUpdateStatus(true);

    setTimeout(() => {
      setSelectedOrder(null);
      handleCloseUpdateStatus();
      setIsSelectedOrder(false);
    }, 2000);
  };

  //Modal de confirmation de retrait
  const [showCustomerService, setShowCustomerService] =
    React.useState<boolean>(false);
  const handleCloseCustomerService = () => setShowCustomerService(false);
  const handleShowCustomerService = () => setShowCustomerService(true);

  const message: string =
    "Voulez-vous vraiment déposer cette commande au service client ?";

  React.useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > 50) {
        setIsScroll(true);
      } else {
        setIsScroll(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  ////////////
  //events
  ////////////

  const changeStatus = (newStatus: orderStatusType, handleShowModal: any) => {
    setIsLoading(true);

    let data = {
      status: newStatus,
      shippedBy: "api/users/" + dataStore.id,
    };
    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_END_POINT + "orders/" + selectedOrder.id,
      headers: {
        "Content-Type": "application/merge-patch+json",
        Authorization: "Bearer " + dataStore.token,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response: any) => {
        _getOrdersByStatus(
          dataStore.token,
          "operout",
          setOrderOperout,
          setIsLoading
        );
        setIsLoading(false);
        handleCloseCustomerService();
        handleClose();

        handleShowModal();
      })
      .catch((error: any) => {
        console.log("update error");
        console.log(error);
        setIsErrorValid(true);
        setIsLoading(false);
      });
  };

  ///////////////////
  //components props
  ///////////////////

  const productTableProps = {
    selectedOrder,
    handleShow,
    newStatus,
    trigger,
    handleShowCustomerService,
  };
  const confirmationProps = {
    showCustomerService,
    handleCloseCustomerService,
    selectedOrder,
    changeStatus,
    newStatus,
    isLoading,
    message,
    handleShowUpdateStatus,
    handleShowCustomerService,
    setOrderExpired,
    setIsLoading,
    setSelectedOrder,
    setIsSelectedOrder,
  };
  const confirmationCancelProps = {
    show,
    handleClose,
    selectedOrder,
    changeStatus,
    isLoading,
    handleShowCancel,
  };
  const cancelValidationProps = { showCancel, handleCloseCancel };
  const CustomerServiceProps = { showUpdateStatus, handleCloseUpdateStatus };
  const selectedOrderInfoBarProps = {isScroll, setSelectedOrder, setSearchOrder, setIsSelectedOrder, selectedOrder};


  return (
    <Container fluid className="order-list pb-5">
      <div className="text-center">
        <p className="col-12 mb-0 text-center font-75 text-ui">
          Détail de la commande à livrer
        </p>
        <SelectedOrderInfoBar selectedOrderInfoBarProps={selectedOrderInfoBarProps} />

    
        <ProductTable productTableProps={productTableProps} />
      </div>

      <Confirmation confirmationProps={confirmationProps} />
      <ConfirmationCancel confirmationCancelProps={confirmationCancelProps} />
      <CustomerServiceValidation CustomerServiceProps={CustomerServiceProps} />
      <CancelValidation cancelValidationProps={cancelValidationProps} />
    </Container>
  );
};

export default OperoutDetail;
