import React from "react";
import { Modal, Container, Button, Alert } from "react-bootstrap";
import QrCode from "../../QrCode";
import userDataStore from "../../../store/userDataStore";

const UserQrcode = ({ show, handleClose }: any) => {
  const dataStore = userDataStore((state: any) => state);
  return (
    <Modal show={show} onHide={handleClose} animation={true} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <span className="text-ui">Livreur : {dataStore.firstname}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="py-0">
        <Container fluid>
          <Container className="text-center mt-2 px-0">
            <Alert variant="" className="border-3 border-gray bg-yellow py-1">
              <span className="font-85 text-ui-second mb-0">
                Présentez le qrcode à la machine pour vous identifier
              </span>
            </Alert>
          </Container>
          <Container className="m-auto bg-light p-2">
            <div className=" text-cente">
              <QrCode data={`${dataStore.apm_access_code}`} />
            </div>
          </Container>
        </Container>
      </Modal.Body>
      <Container className="mt-3 text-end">
        <Button
          className="bg-green border-green text-light w-2 mb-3"
          onClick={handleClose}
        >
          Fermer
        </Button>
      </Container>
    </Modal>
  );
};

export default UserQrcode;
