

import {create} from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

const themeStore = create(
  persist(
    (set) => ({
      // initial state
      theme: null,
      // methods for manipulating state
      themeRegister: (
        theme: string,
      ) =>
        set(() => ({
          theme: theme,
        })),
      themeDelete: () =>
        set(() => ({
          theme: null,
        })),
    }),
    {
      name: 'theme', // unique name
      storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage sessionStorage' is used
    }
  )
)

export default themeStore;