import React from 'react'
import { Container, Row, Col, Button, Modal } from 'react-bootstrap'
import userDataStore from '../../store/userDataStore'
import ProductTable from './ProductTable'
import { useOutletContext } from 'react-router-dom'
import {SelectedOrderInfoBar} from './SelectedOrderInfoBar'
import { PickedUpModal } from './modals/Modals'
const OrderDetail = ({ scanPageProps }: any) => {

  ////////////////////
  //Props & store
  ///////////////////
  const { setIsSelectedOrder } = useOutletContext<any>();

  const {
    selectedOrder,
    setSelectedOrder,
    newStatus,
    setOrderReady,
    setOrderPickedUp,
    setSearchOrder,
  } = scanPageProps

  const dataStore: any = userDataStore((states: any) => states)

  const [isErrorValid, setIsErrorValid] = React.useState<boolean>(false)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [errorMsg, setErrorMsg] = React.useState<string>('')

  const [isScroll, setIsScroll] = React.useState<boolean>(false)

  const [show, setShow] = React.useState<boolean>(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const [showUpdateStatus, setShowUpdateStatus] = React.useState<boolean>(false)
  const handleCloseUpdateStatus = () => setShowUpdateStatus(false)
  const handleShowUpdateStatus = () => {
    setShowUpdateStatus(true)
    handleClose()
    setTimeout(() => {
      setSelectedOrder(null)
      handleCloseUpdateStatus()
    }, 2000)
  }


  React.useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > 50) {
        setIsScroll(true)
      } else {
        setIsScroll(false)
      }
    }

    window.addEventListener('scroll', toggleVisibility)

    return () => window.removeEventListener('scroll', toggleVisibility)
  }, [])

  // const changeStatus = () => {
  //   setIsLoading(true)
  //   if (selectedOrder?.status === 'picked_up') {
  //     setIsErrorValid(true)
  //     setIsLoading(false)
  //     setErrorMsg(
  //       'Cette commande est déja prise en charge par ' +
  //         selectedOrder?.shippedBy?.firstName +
  //         ", rafraichissez l'application"
  //     )
  //   } else {
  //     let data = {
  //       status: newStatus,
  //       shippedBy: 'api/users/' + dataStore.id,
  //     }
  //     let config = {
  //       method: 'patch',
  //       maxBodyLength: Infinity,
  //       url: process.env.REACT_APP_END_POINT + 'orders/' + selectedOrder.id,
  //       headers: {
  //         'Content-Type': 'application/merge-patch+json',
  //         Authorization: 'Bearer ' + dataStore.token,
  //       },
  //       data: data,
  //     }

  //     axios
  //       .request(config)
  //       .then((response: any) => {
  //         _getOrdersByStatus(dataStore.token, 'ready_for_delivery', setOrderReady, setIsLoading)
  //         _getOrdersByStatus(dataStore.token, 'picked_up', setOrderPickedUp, setIsLoading)
  //         setIsLoading(false)
  //         handleShowUpdateStatus()
  //       })
  //       .catch((error: any) => {
  //         console.log(error)
  //         setIsErrorValid(true)
  //         setIsLoading(false)
  //       })
  //   }
  // }



  const productTableProps = {selectedOrder, handleShow, newStatus}
  const selectedOrderInfoBarProps = {isScroll, setSelectedOrder, setSearchOrder, setIsSelectedOrder, selectedOrder};
  const option = {setOrderReady, setOrderPickedUp, handleShowUpdateStatus,  setIsLoading, setIsErrorValid, setErrorMsg, selectedOrder, newStatus, dataStore} 
  const pickedUpModalProps = {handleClose, isLoading, option}


  return (
    <Container fluid className="order-list pb-5 mb-5">
      <div className="text-center">
        <p className="col-12 mb-0 text-center font-75 text-ui">
          Détail de la commande
        </p>
        <SelectedOrderInfoBar selectedOrderInfoBarProps={selectedOrderInfoBarProps} />
        <ProductTable productTableProps={productTableProps} />
      </div>

      <Modal show={showUpdateStatus} onHide={handleCloseUpdateStatus}>
        <Modal.Body className="bg-dark rounded text-light">
          {" "}
          {""}
          <Row className="m-auto">
            <Col xs={2}>
              <i className="ri-checkbox-circle-line text-success fs-1 me-2 animate__animated animate__fadeInDown"></i>
            </Col>
            <Col className="m-auto">Commande prise en charge</Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal show={show} onHide={handleClose}>
        {isErrorValid ? (
          <>
            <Modal.Header closeButton>
              <Modal.Title>
                <i className="ri-error-warning-line fs-2 text-warning"></i>
                Attention
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {errorMsg
                ? errorMsg
                : "Une anomalie est survenue... Rafraichissez la page"}
            </Modal.Body>
            <Modal.Footer>
              <Button
                size="lg"
                className=" rounded-pill border-warning text-light ms-3 px-4"
                variant="warning"
                onClick={() => {
                  setSelectedOrder("");
                  handleClose();
                  setIsErrorValid(false);
                }}
              >
                Réessayez
              </Button>
            </Modal.Footer>
          </>
        ) : (
          <PickedUpModal pickedUpModalProps={pickedUpModalProps} />
        )}
      </Modal>
    </Container>
  );
}

export default OrderDetail
